import React, { useState, useRef, useEffect } from "react";
import { AiOutlineLike, AiFillLike, AiOutlineDislike, AiFillDislike } from "react-icons/ai";
import ReactMarkdown from "react-markdown";
import { DataSendToChatBots, GetChatbotHistory } from "../../helpers/api";
import { useDispatch } from "react-redux";
import { TextField, Avatar, InputAdornment } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import remarkGfm from "remark-gfm";
import { GreenSendIcon } from "../../assets/svgs/svgs";

const QuickChat = ({ setIsSVGClicked }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.app);

  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([
  ]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const messageContainerRef = useRef(null);
  const textAreaRef = useRef(null);
  const responseDivRef = useRef(null);

  const [initial, setInitial] = useState('');

  useEffect(() => {
    const firstName = user.first_name;
    console.log("First Name: ", user.first_name);
    if (firstName) {
      setInitial(firstName.charAt(0).toUpperCase());
    }
  }, []);

  console.log('Initial: ', initial);
  useEffect(() => {
    const fetchHistory = async () => {
      // if (page === 1) return; // Skip fetching on initial load
      const payload = {
        page,
        id: user.id
      }
      const response = await GetChatbotHistory(payload);
      const newMessages = response.data.chat_history;
      console.log("new messages", newMessages);

      if (!Array.isArray(newMessages) || newMessages.length === 0) {
        setHasMore(false);
        if (page === 1) {
          // Show the initial message only if there's no history at all
          setMessages([{ text: "What would you like to know?", sender: "bot", liked: false, disliked: false }]);
        }
        console.log("no new messages");
      } else {
        // Convert the fetched chat history to the format used in the state
        const formattedMessages = newMessages.map(msg => [
          { text: msg.user_message, sender: "user" },
          { text: msg.ai_message, sender: "bot", liked: false, disliked: false },
        ]).flat();

        setMessages(prevMessages => [...formattedMessages, ...prevMessages]);
      }
    };

    fetchHistory();
  }, [page]);

  console.log("messages", messages);
  useEffect(() => {
    const handleScroll = () => {
      if (messageContainerRef.current.scrollTop === 0 && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    };

    const containerRef = messageContainerRef.current;
    if (containerRef) {
      containerRef.addEventListener('scroll', handleScroll);
      return () => containerRef.removeEventListener('scroll', handleScroll);
    }
  }, [hasMore]);

  useEffect(() => {
    // Scroll to the bottom of the message container when new messages are added
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }

    // Check if text area exceeds four lines and adjust overflow
    if (textAreaRef.current) {
      const lineHeight = parseInt(window.getComputedStyle(textAreaRef.current).lineHeight);
      const lines = Math.floor(textAreaRef.current.scrollHeight / lineHeight);
      if (lines > 3) {
        textAreaRef.current.style.overflowY = "scroll";
      } else {
        textAreaRef.current.style.overflowY = "hidden";
      }
    }

    // Update the width of the response div to match the width of the text area
    if (textAreaRef.current && responseDivRef.current) {
      responseDivRef.current.style.width = `${textAreaRef.current.clientWidth}px`;
    }
  }, [messages, message]);

  const handleMessageSend = async () => {
    if (message.trim() !== "") {
      try {
        // Display user's message immediately
        setMessages(prevMessages => [
          ...prevMessages,
          { text: message, sender: "user" },
          { text: "Foxchat is typing...", sender: "bot-typing" }
        ]);

        // Clear the input field
        setMessage("");

        // Prepare the payload
        const payload = { text: message };

        // Send the message to the chatbot API and handle the streaming response
        const responseStream = await DataSendToChatBots(payload, dispatch);
        const reader = responseStream.getReader();
        const decoder = new TextDecoder();

        let fullResponse = "";
        let loopRunner = true;

        // while (loopRunner) {
        //   // Read data chunks from the stream
        //   const { value, done } = await reader.read();
        //   if (done) {
        //     break;
        //   }
        //   const decodedChunk = decoder.decode(value, { stream: true });
        //   fullResponse += decodedChunk;

        //   // Update the bot's response with each received chunk
        //   setMessages(prevMessages => [
        //     ...prevMessages.slice(0, -1), // Remove the last "Foxchat is typing..." message
        //     { text: fullResponse, sender: "bot", liked: false, disliked: false },
        //   ]);
        // }

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          const chunk = decoder.decode(value, { stream: true });
          fullResponse += chunk;
          setMessages(prevMessages => [
            ...prevMessages.slice(0, -1),
            { text: fullResponse, sender: "bot", liked: false, disliked: false },
          ]);
        }

      } catch (error) {
        console.error("Error sending message:", error);
        setMessages(prevMessages => [
          ...prevMessages.slice(0, -1), // Remove the last "Foxchat is typing..." message
          { text: "An error occurred. Please try again later.", sender: "bot", liked: false, disliked: false },
        ]);
      }
    }
  };

  const handleLikeClick = (index) => {
    setMessages(messages.map((msg, i) =>
      i === index ? { ...msg, liked: !msg.liked, disliked: false } : msg
    ));
  };

  const handleDislikeClick = (index) => {
    setMessages(messages.map((msg, i) =>
      i === index ? { ...msg, disliked: !msg.disliked, liked: false } : msg
    ));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleMessageSend();
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    // Add event listener on mount
    window.addEventListener("resize", handleResize);
  
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div style={{ zIndex: 1000 }}
      className={`relative flex mb-5 bg-[#1E1D1B] rounded-[20px] flex-grow antialiased text-gray-400 justify-center items-center ${location.pathname.includes('/foxchat') ? 'min-h-screen' : 'h-[100%]'}`}
    >
      <div className={`flex flex-col ${location.pathname.includes('/foxchat') ? 'min-h-screen' : 'h-full'} w-full  md:w-[70%] justify-between`}>
        <div className="flex flex-col mb-4 mt-10 px-4 w-full scrollbar overflow-y-auto overflow-x-hidden max-h-[40] items-center" ref={messageContainerRef}>
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`mt-3 flex ${msg.sender === "user" ? "justify-end" : "justify-start"} w-full`}
              style={{ maxWidth: "100%" }}
            >
              {msg.sender === "bot" && (
                <div className="flex w-full gap-2">
                  <div className="flex-shrink-0 mt-2">
                    <img
                      loading="lazy"
                      alt="this is the chatbot icon"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/03348c95283b5311b817bba7ca4a3bb8b8177a58cfcb3d9cce98d3ad80299638?apiKey=712222130b354692aa9375ac3c42bcf2&"
                      className="w-[33px] h-[33px] mr-2"
                    />
                  </div>
                  <div className="bg-[#FBFAFB] flex-grow rounded-lg px-5 py-4" ref={responseDivRef}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}
                      className="w-full markdown text-[#1E1D1B]">{msg.text}</ReactMarkdown>
                    {index !== 0 && ( // Hide the like/dislike buttons for the first bot message
                      <div className="flex justify-end mt-2">
                        <button
                          className={`text-black mr-2 ${msg.liked ? "text-gray-500" : ""}`}
                          onClick={() => handleLikeClick(index)}
                        >
                          {msg.liked ? <AiFillLike /> : <AiOutlineLike />}
                        </button>
                        <button
                          className={`text-black ${msg.disliked ? "text-gray-500" : ""}`}
                          onClick={() => handleDislikeClick(index)}
                        >
                          {msg.disliked ? <AiFillDislike /> : <AiOutlineDislike />}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {msg.sender === "bot-typing" && (
                <div className="flex w-full gap-2">
                  <div className="flex-shrink-0 mt-2">
                    <img
                      loading="lazy"
                      alt="this is the chatbot icon"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/03348c95283b5311b817bba7ca4a3bb8b8177a58cfcb3d9cce98d3ad80299638?apiKey=712222130b354692aa9375ac3c42bcf2&"
                      className="w-[33px] h-[33px] mr-2"
                    />
                  </div>
                  <div className="bg-[#FBFAFB] flex-grow rounded-lg px-5 py-4" ref={responseDivRef}>
                    <div className="w-full text-[#1E1D1B] flex gap-1">Fox Chat is typing <p className="animate-bounce">...</p></div>
                  </div>
                </div>
              )}
              {msg.sender === "user" && (
                <div className="flex w-full gap-4 py-4 justify-start">
                  <Avatar>{initial}</Avatar>
                  <div className="rounded-lg py-2  message-container flex-grow" ref={responseDivRef}>
                    <span className="message-text pl-3 text-[#EBEBE6]">
                      {msg.text}
                    </span>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div>
          <div className="flex flex-col pt-5 items-center justify-end rounded-xl bg-transparent w-full px-4 py-2">
            <div className="w-full flex justify-end text-white">
              {/* fox chat within app form */}
              <TextField
                inputProps={{
                  color:"white",
                  sx: {
                    '&::placeholder': {
                      color: '#EBEBE6',
                      opacity: 1, // For consistent placeholder color
                    },
                  },
                }}
                placeholder="Type your question here"
                ref={textAreaRef}
                className="text-white"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                sx={{ 
                  border: "1px solid white", 
                  borderRadius: { xs: "30px", smc: "10px" }, 
                  color: "white", 
                  width: "100%",
                  "& .MuiInputBase-input": {
                    paddingRight: "7px", // Correctly targets the input padding
                    color:"white",
                  },
                  "& .MuiOutlinedInput-input": {
                    paddingRight: "7px", // Ensures consistency for outlined input
                    color:"white",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {windowWidth < 640 ? (
                        <div onClick={handleMessageSend} style={{ cursor: "pointer", marginRight:"-6px" }}>
                          <GreenSendIcon />
                        </div>
                      ) : (
                        <SendIcon
                          onClick={handleMessageSend}
                          sx={{ color: "white", fontSize: "40px", cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="flex justify-center mt-3 pb-5">
            <button onClick={() => {
              if (location.pathname.includes('/foxchat')) {
                navigate("/");
              }
              else {
                setIsSVGClicked(false);
              }
            }} className="flex items-center justify-center cursor-pointer bg-[#B9B1F7] rounded-[30px] text-[#000000] px-10 py-3 flex-shrink-0">
              <span>Return to Application</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickChat;
