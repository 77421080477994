import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";

import {
  Button,
  Box,
  Divider,
  CircularProgress,
  Typography,
  DialogContent,
  Tooltip,
  TextField,
  InputAdornment,
  Dialog,
} from "@mui/material";

import { useSelector } from "react-redux";
import {
  apiCallWithAxiosCancel,
  AppStepLock,
  GetApplicationData,
  GetMainActivities,
  GetSubActivities,
  SaveBusinessActivities,
  SearchSubBusinessActivities,
} from "../../helpers/api";
import { toast, ToastContainer } from "react-toastify";
import { MdClose } from "react-icons/md";
import "../../../src/index.css";
import { useDispatch } from "react-redux";
import { setApplicationID,} from "../../features/appSlice";

import LoadingModal from "../../helpers/LoadingModal";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { FixedSizeList, VariableSizeList } from 'react-window';
import { ExclaimationIcon } from "../../assets/svgs/svgs";
import { blockStep,unblockStep } from "../../features/stepsBlocker";
import { valueTester } from "../../Utilities/UtilityFunctions";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //width: {sm: "100%", md: "70%", lg: "50%"},
  bgcolor: "background.paper",
  // border: "2px sod #000",
  // boxShadow: 24,
  p: 4,
};

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

function BusinnessActivities({
  onNext,
  onBack,
  onSaveAndExit,
  setMaxStepReached,
}) {
  const applicationID = useSelector((state) => state.app.applicationID);
  const [subActivitiesData, setSubActivitiesData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mainActivity, setMainActivity] = useState("");
  const [mainActivityNameEn, setMainActivityNameEn] = useState([]);
  const [apiloading, setApiloading] = useState(false);
  const [mainActivityId, setMainActivityId] = useState([]);
  const [mainActivitiyDialogue, setMainActivitiyDialogue] = useState(false);
  const [subActivity, setSubActivity] = useState([]);
  const [subActivityId, setSubActivityId] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [subActivitiyDialogue, setSubActivitiyDialogue] = useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [nextLoading, setNextLoading] = useState(false);
  const [saveExit, setSaveExit] = useState(false);
  const [filterEnabled, setFilterEnabled] = useState(true);
  const [mainactivitiesBigId, setMainactivitiesBigId] = useState([]);
  const [mainactivityBigName, setMainactivityBigName] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [pageSize, setPageSize] = useState(1);
  const [isEverythingDisabled, setIsEverythingDisabled] = useState(false);
  const [extraValue, setExtraValue] = useState([]);
  const observer = useRef();
  const [allSubActivitiesData, setAllSubActivitiesData] = useState({
    //1: {}, indexer for storing fetched pages
  });
  const [filteredSubActivitiesData, setFilteredSubActivitiesData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const [business_error, setBusinessError] = useState("")

  const [appComplete, setAppComplete] = useState(false)
  // functions for infinite scrolling for searched sub activities:

const [nextPage, setNextPage] = useState(null); // Track the next page URL for infinite scrolling
const [prevSearchText, setPrevSearchText] = useState(""); // Previous search text
const [originalSubActivityCount, setOriginalSubActivityCount] = useState(0);
const [subActivityIdCount, setSubActivityIdCount] = useState(0);

const appLock = useSelector((state) => state.stepBlocker)

// Search function with logic for replacing data
const debouncedSearch = useMemo(
  () =>
    debounce(async (searchText) => {

      if (!searchText.length){
        fetchMoreAllData(1)      
      }   
      
      if (searchText.length) {

        setIsLoadingMore(true);
        setIsSearching(true);

        setCurrentPage(0)
        setNextPage(null)
        setFilteredSubActivitiesData([])
        setSubActivitiesData([])

        try {
          // Reset the previous data when new search text is entered
          const response = await SearchSubBusinessActivities(searchText, dispatch, nextPage);
          if (response && response.data) {
            setFilteredSubActivitiesData(response.data.results); // Replace data with new search results
            setNextPage(extractPageNumber(response.data.next)); // Set the next page for infinite scrolling
            setPrevSearchText(searchText); // Update previous search text
          }
        } catch (error) {
          console.error("Error during search:", error);
        } finally {
          setIsLoadingMore(false);
        }
      } else {
        setIsSearching(false);
        setFilteredSubActivitiesData([]); // Clear data if search text is empty
      }
    }, 750),
  []
);

  // Trigger search when searchText changes
  useEffect(() => {
    // setIsLoadingMore(true)
    debouncedSearch(searchText);
  }, [searchText, debouncedSearch]);

  const divRef = useRef(null); // Ref to the div element
  // const [divHeight, setDivHeight] = useState(0); // State to store the div's height



// Function to fetch data for scrolling
const fetchMoreSearchedData = async () => {
  if (!nextPage || isLoadingMore) return; // Exit if no next page or already loading

  try {
    setIsLoadingMore(true);
    const response =  await SearchSubBusinessActivities(searchText, dispatch, nextPage);
    if (response?.data) {
      setFilteredSubActivitiesData(prevData => [...prevData, ...response.data.results]); // Append new search results
      setNextPage(extractPageNumber(response.data.next)); // Update next page URL for search
    }
  } catch (error) {
    console.error("Error fetching more search data:", error);
  } finally {
    setIsLoadingMore(false); // Reset loading state
  }
};

function subActivitiesIndexer(page, action = "retrieve", data = []){
  
  let objectIndex = Object.keys(allSubActivitiesData)?.find((item) => item === `${page}`)

  if (action === "retrieve"){
    if(objectIndex){
      setSubActivitiesData([...allSubActivitiesData[objectIndex]])
      // setCurrentPage(parseInt(page) + 1)
      return true
    }
    else {
      return false
    }     
  }
  else if (action === "store" && !objectIndex){
    setAllSubActivitiesData({
      ...allSubActivitiesData,
      [page]: [...data]
    })
    return true
  }
  else{
    return false
  }

}

const fetchMoreAllData = async (page = null) => {
  let pageNum = page !== null ? page : (currentPage + 1)
  if (isLoadingMore || currentPage >= 97) return; // Exit if already loading or page limit reached

  try {
    let loadingMore = true
    setIsLoadingMore(true);
    console.log(pageNum , " | subActivitiesIndexer = retrieve = ", subActivitiesIndexer(pageNum, "retrieve")) 
    //if (subActivitiesIndexer(pageNum, "retrieve") === false){
      const response = await GetSubActivities(pageNum, dispatch); // Fetch the next page of all activities
      if (response?.data) {
        setSubActivitiesData(prevData => [...prevData, ...response.data.results]); // Append new data to the list
        setCurrentPage(prevPage => prevPage + 1); // Increment page count
        subActivitiesIndexer(pageNum, "store", [...response.data.results])
      }      
    //}
    // else if(subActivitiesIndexer(pageNum, "retrieve") === true){
    //   console.log("currentPage", pageNum, "new = ", pageNum + 1)
    //   setCurrentPage(pageNum + 1)
    //   if (!isLoadingMore){
    //     fetchMoreAllData(pageNum + 1) // recall/recurse the function
    //   }
    // }
  } catch (error) {
    console.error("Error fetching all activities data:", error);
  } finally {
    setIsLoadingMore(false);
  }
};
const [windowWidth, setWindowWidth] = useState(window.innerWidth);

useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Add event listener on mount
  window.addEventListener("resize", handleResize);

  // Cleanup event listener on unmount
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);




  const [screenHeight, setScreenHeight] = useState(window.innerHeight); // Initial height

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight); // Update state when window is resized
    };

    // Add event listener for window resize
    window.addEventListener('resize', updateHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const handleIsEverythingDisabled = (strategy) => {
    if (strategy === 'next') {
      onNext();
    } else {
      navigate("/");
    }
  };




  useEffect(() => {
    async function fetchMainActivies() {
      try {
        setNextLoading(true)

        const MainActivities = await GetMainActivities(dispatch);
        setMainActivity(MainActivities.data);
      } catch (error) {
        toast.error("Error occured while fetching Main Activities Data");
      } finally {
        setNextLoading(false);
      }
    }
    fetchMainActivies();
  }, []);
  const getSubActivities = async (page) => {
    if (page > 96) {
      return;
    }
    setNextLoading(true)
    setIsLoadingMore(true);
    try {
      const response = await GetSubActivities(page,
        dispatch
      );
      if (response && response.data) {
        setSubActivitiesData((prevData) => [...prevData, ...response.data?.results]);
        subActivitiesIndexer(page, "store", [...response.data.results])
      } else {
        console.error("Invalid response from GetSubActivities API");
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setNextLoading(false)
      setIsLoadingMore(false);
    }
  };
  useEffect(() => {
    //if(!subActivitiesIndexer(currentPage, "retrieve")){
      getSubActivities(currentPage);
    //}
  }, []);

  const extractPageNumber = (url) => {
    const urlParams = new URLSearchParams(url.split('?')[1]); // Extract query params from URL
    console.log("page =", urlParams.get('page'))
    return urlParams.get('page'); // Extract the page number
  };
  
  const isFirstRender = useRef(true); // Define ref to track the first render

  // useEffect(() => {
  //   if (isFirstRender.current) {
  //     isFirstRender.current = false; // Skip the first render
  //     return;
  //   }
  //   else{

  //     // causes no activities found message to appear before search is triggered
  //     setTimeout(()=>{
  //       setCurrentPage(0)
  //       setNextPage(null)
  //       setFilteredSubActivitiesData([])
  //       setSubActivitiesData([])
  //       if (!searchText.length){
  //         fetchMoreAllData(1)      
  //       }   
  //     }, 1000)
   
  //   }

  // }, [searchText])

  const lastActivityElementRef = useCallback((node) => {
    if (isLoadingMore) return; // Don't trigger new requests while loading
    if (observer.current) observer.current.disconnect(); // Disconnect previous observer
  
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (searchText !== "") { 
          // Infinite scroll for searched results
          if (nextPage) { // Check if there is a next page in search results
            fetchMoreSearchedData();
          }
        } else {
          // Pagination for all activities (don't fetch if page limit is reached)
          if (currentPage < 97) {
            fetchMoreAllData(); // Function for paginated results
          }
        }
      }
    });
  
    if (node) observer.current.observe(node); // Attach observer to the last element
  }, [isLoadingMore, searchText]);
  

  useEffect(() => {
    if (applicationID === null) return;
    async function fetchApplicationData() {
      try {
        setNextLoading(true);
        const ApplicationData = await GetApplicationData(
          applicationID,
          dispatch
        );

        const applicationCompletionStatus = ApplicationData?.data[0]?.is_completed ? ApplicationData?.data[0]?.is_completed : false 
        console.log("app complete = ", applicationCompletionStatus)
        setAppComplete(applicationCompletionStatus)

        setMaxStepReached(ApplicationData?.data[0]?.step_no + 1);
        setIsEverythingDisabled(ApplicationData?.data[0]?.is_completed);
        if (
          ApplicationData.data.length > 0 &&
          ApplicationData.data[0].sub_activities !== null
        ) {

          const mainActivityName =
            ApplicationData.data[0]?.main_business_activities?.map(
              (item) => item.nameEN
            );

          const mainActivityId =
            ApplicationData.data[0]?.main_business_activities?.map(
              (item) => item.id
            );

          setMainactivitiesBigId(mainActivityId || []);
          setMainactivityBigName(mainActivityName || []);

          const subActivityIds = ApplicationData?.data[0]?.sub_activities.map(
            (item) => item.id
          );

          setSubActivityId(subActivityIds);
          setOriginalSubActivityCount(subActivityIds)

          const count = subActivityIds.length;
          setSubActivityIdCount(count);
          
          console.log('Original count of subactivities:',originalSubActivityCount)
          const subActivityNameEns = ApplicationData.data[0].sub_activities.map(
            (item) => item.activityNameEn
          );
          setExtraValue([...ApplicationData?.data[0]?.sub_activities])
          setSelectedActivities(subActivityNameEns);
        }
      } catch (error) {
        toast.error("Error occurred while fetching Application Data");
      } finally {
        setNextLoading(false);
      }
    }
    fetchApplicationData();
  }, []);

  useEffect(() => {
    if (subActivitiesData?.length > 0) {
      // Group sub-activities by main activity ID
      const groupedSubActivities = subActivitiesData?.reduce(
        (acc, subActivity) => {
          const mainActivityId = subActivity?.business_main_activities_id;
          if (!acc[mainActivityId]) {
            acc[mainActivityId] = [];
          }
          acc[mainActivityId].push(subActivity);
          return acc;
        },
        {}
      );
      setSubActivity(groupedSubActivities);
    }
  }, [subActivitiesData]);


  const CloseSubActivityDialouge = () => {
    setFilterEnabled(false);
    setSearchText("");
    setSubActivitiyDialogue(false);
  };

  const handleOpenMainDialoge = () => {
    try {
      setNextLoading(true);
      //setSubActivityId([]); // Reset subActivityId when changing main activity
      //setSelectedActivities([]);
      if (subActivitiesData) {
        setSubActivitiyDialogue(true);
        // setFilterEnabled(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Oops!Sever Error ", {
        position: "bottom-right",
        toastId: "get business activities",
      });
    }
    finally {
      setNextLoading(false);
    }
    //
  };
  const handleActivityClick = (activity) => {
    const { id, activityNameEn, business_main_activities_id } = activity;
    if (selectedActivities.includes(activityNameEn)) {
      handlesubactivityid(id);
      SubActivityClick(activityNameEn);
      return; // Exit here, so no maximum selection check is needed
    }

    if (selectedActivities.length > 6) {
      toast.error("You can select maximum of 7 activities", { toastId: "max activities" });
      return;
    }
    handlesubactivityid(id);
    SubActivityClick(activityNameEn);
    setExtraValue([...extraValue, activity]);

    if (!mainactivitiesBigId.includes(business_main_activities_id)) {
      const mainBusinessName = mainActivity.find(
        (item) => item.id === business_main_activities_id
      )?.nameEN;
      setMainactivitiesBigId((prev) => [...prev, business_main_activities_id]);
      setMainactivityBigName((prev) => [...prev, mainBusinessName]);
    }
  };


  const handlesubactivityid = (id) => {
    console.log(id);
    if (subActivityId.includes(id)) {
      setSubActivityId(subActivityId.filter((subId) => subId !== id));
    } else {
      setSubActivityId((prev) => [...prev, id]);
    }
  };

  const SubActivityClick = (activity) => {
    if (selectedActivities.includes(activity)) {
      setSelectedActivities((prev) =>
        prev.filter((selectedActivity) => selectedActivity !== activity)
      );
    } else {
      setSelectedActivities((prev) => [...prev, activity]);
    }
  };

  const RemoveSubActivity = (activity) => {
    // Find the activity object based on the name
    const activityObj = extraValue.find(
      (subActivity) => subActivity.activityNameEn === activity
    );


    // Remove the activity name from selectedActivities
    setSelectedActivities((prev) =>
      prev.filter((selectedActivity) => selectedActivity !== activity)
    );

    // Remove the corresponding activity ID from subActivityId
    if (activityObj) {
      setSubActivityId((prev) =>
        prev.filter((id) => id !== activityObj.id)
      );
    }
  };

      const areArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
          return false;
        }
        else{
          const sortedArr1 = [...arr1].sort();
          const sortedArr2 = [...arr2].sort();
          return sortedArr1.every((value, index) => value === sortedArr2[index]);          
        }
      };

  const ActivitesSelection = async (exit) => {
    // dispatch(blockStep())
    // dispatch(unblockStep());
    if (!subActivityId.length > 0) {
      // toast.error("Please Select Activity first", {
      //   toastId: "Check Activities",
      // });
      setBusinessError("Please Select Activity first")
    } else {
      setBusinessError("")


      
      // Inside your component or function
      console.log("subActivityId=",subActivityId, "\norg=", originalSubActivityCount)


      // else if is_blocked ===  false then check for change in component - if changed then set block step = true

      try {
        setApiloading(true);
        let ActivitiesResults;
        const payload = {
          main_activity_ids: mainactivitiesBigId !== null ? mainActivityId : [],
          sub_activity_ids: subActivityId,
        };
      
        if (applicationID !== null) {
          payload.id = applicationID;
        }
      
        try {
          // Execute SaveBusinessActivities API call
          ActivitiesResults = await apiCallWithAxiosCancel((cancelToken) =>
            SaveBusinessActivities(payload, dispatch, cancelToken)
          );
      
          // Conditionally execute AppStepLock if necessary
          if (
            !areArraysEqual(subActivityId, originalSubActivityCount) &&
            (applicationID !== null || ActivitiesResults.data?.id)
          ) {
            const appId = applicationID || ActivitiesResults.data.id;
      
            try {
              await AppStepLock(
                {
                  app_id: valueTester(appLock.app_id) ? appLock.app_id : appId,
                  is_blocked: true,
                  current_step: 0,
                },
                dispatch
              );
      
              dispatch(
                blockStep({
                  is_blocked: true,
                  currentStep: 0,
                  app_id: valueTester(appLock.app_id) ? appLock.app_id : appId,
                })
              );
            } catch (error) {
              console.error("Error in AppStepLock:", error);
              // Optionally, display a toast for AppStepLock failure
              toast.error("Error blocking the step.", {
                toastId: "app-step-lock",
              });
            }
          }
      
          // If applicationID is null, set it with the result from ActivitiesResults
          if (applicationID === null && ActivitiesResults.data?.id) {
            dispatch(setApplicationID(ActivitiesResults.data.id));
          }
      
          setSubActivity(ActivitiesResults.data);
      
          if (exit === true) {
            setTimeout(() => {
              navigate("/");
            }, 500);
          } else {
            onNext();
          }
        } catch (error) {
          console.error("Error in SaveBusinessActivities:", error);
          // Display a toast for SaveBusinessActivities failure
          toast.error("Error Saving Activity Details.", {
            toastId: "save-subactivities",
          });
          return; // Prevent further execution if SaveBusinessActivities fails
        }
      } catch (error) {
        if (error?.code !== "ERR_CANCELED" && error?.name !== "CanceledError") {
          console.error("Unexpected error:", error);
          toast.error("An unexpected error occurred.", {
            toastId: "unexpected-error",
          });
        }
      } finally {
        setApiloading(false);
        setSaveExit(false);
      }
      
    }
  };

  const allSubActivities = useMemo(() => {
    return Object?.values(subActivity)?.flat();
  }, [subActivity]);

  const filteredSubActivities = useMemo(() => {
    const uniqueSubActivities = new Map();

    allSubActivities
      ?.filter((activity) =>
        activity.activityNameEn?.toString()?.toLowerCase()?.includes(searchText?.toString()?.toLowerCase())
      )
      .sort((a, b) => {
        const nameA = a.activityNameEn?.toString()?.toLowerCase();
        const nameB = b.activityNameEn?.toString()?.toLowerCase();
        const searchLower = searchText?.toString()?.toLowerCase();

        const indexA = nameA.indexOf(searchLower);
        const indexB = nameB.indexOf(searchLower);

        if (indexA === 0 && indexB === 0) return 0;
        if (indexA === 0) return -1;
        if (indexB === 0) return 1;

        return indexA - indexB;
      })
      .forEach((activity) => {
        uniqueSubActivities.set(activity.activityNameEn, activity);
      });

    return Array.from(uniqueSubActivities.values());
  }, [allSubActivities, searchText]);





  const itemSize = 80;
  const displayedSubActivities = isSearching ? filteredSubActivitiesData : subActivitiesData;

  // list expansion functions:
  const listRef = useRef();

  // State to track expanded items
  const [expandedItems, setExpandedItems] = useState({});

  // Function to toggle expansion for a specific index
  const toggleExpand = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));

    // Reset the sizes in the list after changing expansion
    listRef.current.resetAfterIndex(index);
  };

  useEffect(()=>{
    console.log("expandedItems", expandedItems)
  }, [expandedItems])

  // Function to calculate item height
  const getItemSize = (index) => {
    const minHeight = 110; // Minimum height for all items
    const additionalHeight = expandedItems[index] ? calculateExpandedHeight(index) : 0;
    return minHeight + additionalHeight;
  };

  // Calculate additional height when expanded based on content length
  const calculateExpandedHeight = (index) => {
    const description = displayedSubActivities[index]?.description || "";
    let descriptionLines = Math.ceil(description.length / 50); // Roughly 50 chars per line
    if(windowWidth < 385){
      descriptionLines = Math.ceil(description.length / 40);
    }
    const lineHeight = 18; // Example line height in px
    let extraHeight = descriptionLines * lineHeight - 36 + 24 // Default description (line-clamp-3) is ~2 lines
    // if(windowWidth < 385){
    //   extraHeight = descriptionLines * lineHeight - 36 + 45 
    // }
    return Math.max(0, extraHeight); // Ensure no negative values
  };

  return (
    <>
            {/* <ToastContainer/> */}

      <LoadingModal open={nextLoading || isLoadingMore} />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={"column"}
        className="min-h-[100%] rounded-t-[20px]"
        sx={{background:"white"}}
      >
        <Box sx={{px: { xs:3, smc:5} }} marginTop={2}>
          <p className="font-bold text-lg sm:text-2xl text-[#1E1D1B]">
            What type of business would you like to set-up/register?
          </p>
          {
            windowWidth < 640 ? <hr style={{borderWidth:"1px", marginTop:"25px"}}/> : ""
          }
          <br />

          <Button
            fullWidth
            variant="outlined"
            onClick={handleOpenMainDialoge}
            sx={{
              textTransform: "none",
              color: "#8C8C8C",
              fontSize: { xs:"14px", smc: "16px"},
              justifyContent: "flex-start",
              padding: "7px",
              borderRadius: "6px",
              border: "2px solid #EBEBE6",
              "&:hover": {
                border: "2px solid #81ef6f",
              },
              width: "430px",
              maxWidth:"100%"
            }}
            disabled={isEverythingDisabled}
            startIcon={
              <SearchIcon className={`mr-2 ml-3 text-[#8C8C8C]`} size={28} />
            }
          >
            {windowWidth < 640 ? "Search for activity" : "Search Activity Related to Your Business"} 
          </Button>

          {
            business_error?.length ? <div className="error-msg-div"><p className="error-msg-text">{business_error}</p></div> : ""
          }
          
            <Divider
              sx={{
                marginTop: "3.2rem",
                marginBottom: "0.5rem",
              }}
            />


          {mainActivity !== "" &&
            subActivityId.length > 0 &&
            subActivitiyDialogue === false && (
              <>
                <p className="font-bold mt-3 text-lg sm:text-xl text-[#1E1D1B]">
                  {/* outter selected activities */}
                  Your selected activities:
                </p>

                <div style={{ height:"fit-content", 
                    maxHeight: windowWidth < 640 ? `calc(100dvh - 90px - 20px - 32px - 32px - 32px - 46px - 30px - 32px - 60px - 40px - 55px)` : 
                               windowWidth < 1280 && windowWidth > 640 ? `calc(100dvh - 90px - 20px - 32px - 32px - 32px - 46px - 30px - 32px - 60px - 40px)` :
                               `calc(100dvh - 90px - 20px - 32px - 32px - 32px - 46px - 30px - 32px - 60px - 30px)`,
                               overflowY:"auto"}} className="scrollbar overflow-y-auto">
                <div  className="flex flex-wrap mt-3.5">
                  {selectedActivities.map((activity, index) => (
                    <Tooltip placement="top" title={activity}>
                      <div
                        key={index}
                        className="wrapped-capsule px-3 py-2 bg-[#1E1D1B] rounded-[20px] text-white text-sm flex items-center justify-between mr-2 mb-2 cursor-pointer"

                      >
                        <span className="wrapped-capsule">{activity}</span>
                        <button
                          className="p-1"
                          onClick={(e) => {
                            e.preventDefault();
                            if(appComplete === false){
                              RemoveSubActivity(activity);
                            }
                          }}                        >
                          <MdClose hidden={appComplete} className="w-4 h-4 text-white" />
                        </button>
                      </div>                      
                    </Tooltip>

                  ))}
                </div>
              </div>
              </>
            )}
        </Box>

        <Dialog
          open={subActivitiyDialogue}
          onClose={CloseSubActivityDialouge}
          sx={{ 
            px: 1,
            "& .MuiPaper-root": {
              margin: {xs: "0px", smc: "32px"},
              borderRadius:"20px",
              width:{ xs:"99vw", smc:"95vw"},
              maxWidth:{ xs:"99vw", smc:"95vw"}
            },

          }}
          
        >
          <div
            ref={divRef}
            style={{
              // position: "absolute",
              // top: "90px",
              // left: "50%",
              // transform: "translate(-50%, -15px)",
              height: "88vh",
              width:"100%"
              
            }}
            className="min-w-[90%] flex flex-col scrollbar justify-between  overflow-y-auto  rounded-[20px] bg-white shadow-md "
          >
            <Box className="md:px-8 md:pt-6 px-3 pt-[10px] ">
              {/* <MdClose
                onClick={CloseSubActivityDialouge}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "15",
                  right: "15",
                }}
                className="w-6 h-6 text-[#000000]"
              /> */}

              <DialogContent sx={{ padding: "0px", mt: 0 }}>
                <div className="flex mb-0 gap-10 items-start">
                  <TextField
                    className="search-bar"
                    variant="outlined"
                    placeholder="Search activities"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      style: {
                        maxHeight: "40px",
                        borderRadius: "6px",
                      },
                    }}
                    sx={{
                      marginBottom: "0.5rem",
                      width: {
                        xs: "90%",
                        md: "440px",
                      },
                      borderRadius: {
                        xs: "5px",
                        smc: "10px"
                      },
                    }}
                  />

<MdClose
                onClick={CloseSubActivityDialouge}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "15",
                  right: "15",
                }}
                className="w-6 h-6 text-[#000000]"
              />
                </div>
                <div 
                  // style={{border:"2px solid red", height:"70%"}}
                  >
                  
                  <div className="hidden sm:grid grid-cols-2 w-[100%] md:w-[85%] ">
                    <p className="pl-5">Activities</p>
                    <p>Description</p>
                  </div>
                  <div className="flex flex-col mt-1 mb-3 cursor-pointer">
                    {displayedSubActivities?.length > 0 ? (<>

                    {
                      windowWidth < 640 ? 
                      <VariableSizeList
                      ref={listRef}
                      height={
                        (screenHeight -
                        screenHeight * 0.12) - (screenHeight * 0.40) - 15
                        // 90 -
                        // 65 -
                        // 32 -
                        // 150 -
                        // 37 
                        // <
                        // 350
                        //   ? 350
                        //   : screenHeight - screenHeight * 0.12 - 90 - 65 - 32 - 150 - 37
                      }
                      width="100%"
                      itemCount={displayedSubActivities?.length}
                      itemSize={getItemSize}
                      className="scrollbar flex flex-col"
                    >
                      {({ index, style }) => {
                        const activity = displayedSubActivities[index];
                        const isExpanded = expandedItems[index];
                        const isLastElement = index === displayedSubActivities?.length - 1;
                
                        return (
                          <div
                            style={style}
                            ref={isLastElement ? lastActivityElementRef : null}
                            key={activity?.id || index}
                          >
                            <div
                              style={{  minHeight: index === 0 ? "100px" : "100px", height: expandedItems?.[index] === true ? `calc(${getItemSize(index)}px - 10px)` : "100px"}}
                              className={`grid sm:grid-cols-2 gap-2 sm:gap-2 w-[100%] md:w-[85%] text-[#1E1D1B] items-start mt-0 mr-3 px-5 py-3 rounded-[6px] sm:rounded-[15px] text-[0.8em] ${
                                selectedActivities.includes(activity.activityNameEn)
                                  ? "bg-[#E6FCE2] border-2 border-[#81EF6F] text-[#1E1D1B]"
                                  : "bg-[#EDEDE9]"
                              }`}
                              onClick={() => handleActivityClick(activity)}
                            >
                              <div className="flex flex-col justify-between">

                              <div style={{ height: expandedItems?.[index] === true ? `calc(${getItemSize(index)}px - 30px)` : "100px"}} className="flex flex-col justify-between h-full w-full"> {/* parent text container - start */}   
                                <span style={{}}>
                                <div className="flex flex-row justify-between">
                                  <Tooltip title={activity.activityNameEn}>
                                    <p className="font-bold sm:font-medium text-base line-clamp-1">
                                      {activity.activityNameEn}
                                    </p>
                                  </Tooltip>
                
                                  <span
                                    style={{ margin: "-4px -12px 0px 0px" }}
                                    className="block sm:hidden"
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent triggering parent onClick
                                      toggleExpand(index);
                                    }}
                                  >
                                    <ExclaimationIcon />
                                  </span>
                                </div>                                  
                                
                                <div
                                  className={`${
                                    isExpanded ? "" : "line-clamp-3"
                                  } my-auto font-normal text-xs`}
                                >
                                  <Tooltip title={ windowWidth < 640 ? "" : activity.description}>
                                    <p>{activity.description}</p>
                                  </Tooltip>
                                  



                                </div>
                                
                                </span>

                                  <div className="mt-2" hidden={expandedItems?.[index] === true ? false : true}>
                                    <p  className="font-bold sm:font-medium text-base">
                                        {displayedSubActivities[index].licenseTypeEn} License
                                    </p>                                    
                                  </div> 

                                  </div> {/* parent text container - end */}               

                              </div>
                            </div>
                          </div>
                        );
                      }}
                    </VariableSizeList>
                      :
                      <FixedSizeList
                        height={
                          (screenHeight -
                            screenHeight * 0.12) - (screenHeight * 0.4) - 40 - 40
                        }
                        width="100%"
                        itemSize={ windowWidth < 640 ? 120 : 85 }
                        itemCount={displayedSubActivities?.length}
                        className="scrollbar flex flex-col"
                      >
                        {({ index, style }) => {
                          const isLastElement = index === displayedSubActivities?.length - 1;

                          return (
                            <div
                              style={style}
                              ref={
                                isLastElement
                                  ? lastActivityElementRef // Attach ref to the last element
                                  : null
                              }
                              key={displayedSubActivities[index]?.id || index}
                            >
                              <div
                                className={`grid sm:grid-cols-2 gap-2 sm:gap-2 w-[100%] md:w-[85%] text-[#1E1D1B] items-start mt-0 mr-2 px-5 py-3 rounded-[6px] sm:rounded-[15px] text-[0.8em] ${
                                  selectedActivities.includes(
                                    displayedSubActivities[index].activityNameEn
                                  )
                                    ? "bg-[#E6FCE2] border-2 border-[#81EF6F] text-[#1E1D1B]"
                                    : "bg-[#EDEDE9]"
                                }`}
                                onClick={() => handleActivityClick(displayedSubActivities[index])}
                              >
                                <div className="flex flex-col justify-between">

                                  <div className="flex flex-row justify-between">
                                  <Tooltip
                                    title={displayedSubActivities[index].activityNameEn}
                                  >
                                    <p className="font-bold sm:font-medium text-base line-clamp-1">
                                      {displayedSubActivities[index].activityNameEn}
                                    </p>
                                  </Tooltip>


                                  <span 
                                    style={{
                                      margin:"-4px -12px 0px 0px"
                                    }}
                                    className="block sm:hidden">
                                      <ExclaimationIcon/>  
                                  </span> 

                                  </div>

                                  
                                  <div
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: {
                                        xs: "200px",
                                        md: "250px",
                                      },
                                      // border:"2px solid red",
                                      position: "relative",
                                    }}
                                    className="hidden sm:grid grid-cols-3 items-center"
                                  >
                                    <p className="pt-3 font-normal text-xs">
                                      {displayedSubActivities[index].licenseTypeEn} License
                                    </p>
                                  </div>
                                </div>
                                <div className="my-auto"
                                      style={{
                                        whiteSpace: "wrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: {
                                          xs: "200px",
                                          md: "250px",
                                        },
                                        maxHeight:{
                                          xs: "50px",
                                          smc: "inherit"
                                        },
                                        height:"55px"
                                      }}
                                
                                >
                                  <Tooltip title={displayedSubActivities[index].description}>
                                    <p className="font-normal line-clamp-3 text-xs">
                                      {displayedSubActivities[index].description}
                                    </p>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      </FixedSizeList>                      
                    }

                    </>) : (
                      !isLoadingMore && (
                        <Typography variant="body1" sx={{ mt: 2 }}>
                          No activities found.
                        </Typography>
                      )
                    )}
                  </div>
                </div>
              </DialogContent>

              <Divider sx={{ marginTop: "0.5rem" }} />
            </Box>

            <Box
              px={2}
              sx={{
                height: "auto",
                minHeight: {
                  xs:"20dvh",
                },
                // paddingBottom: "10px",
              }}
            >
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  marginTop: "5px",
                  marginBottom: "2px",
                  margin: "",
                  fontWeight: 600,
                  fontSize: {
                    xs: "16px",
                    md: "22px"
                  },
                }}
              >
                {/* modal */}
                Your selected activities:
              </Typography>
              <div className={`scrollbar flex overflow-y-auto`}>
                <div className="flex flex-wrap mt-3.5" 
                      style={{
                        flexGrow:1,
                        maxHeight: "calc(100dvh - (100dvh * 0.12) - (100dvh * 0.6) - 55px - 25px )"
                        // maxHeight: `${(screenHeight - (screenHeight * 0.12) - 90 - 65 - 32 - 150 - 37) < 400 ? "102px" : "152px" }`
                      }}
                >
                  {selectedActivities.map((activity, index) => (
                    <Tooltip placement="top" title={activity}>
                      <div
                        key={index}
                        className="cursor-pointer wrapped-capsule px-3 py-2 bg-[#1E1D1B] rounded-[30px] text-white text-sm flex items-center justify-between mr-2 mb-2"
                      >
                        <span className="wrapped-capsule">{activity}</span>
                        <button
                          className="p-1"
                          onClick={() => RemoveSubActivity(activity)}
                        >
                          <MdClose hidden={appComplete} className="w-4 h-4 text-white" />
                        </button>
                      </div>                      
                    </Tooltip>

                  ))}
                </div>
              </div>
            </Box>
            <Box
              sx={{
                alignItems: "flex-end",
                display: "flex",
                gap: "0.2rem",
                mt: 2,
              }}
            >
              <div className="w-1/2"></div>


              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "rgb(212 212 216)",
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: "#81EF6F",
                  },
                  color: "#1E1D1B",
                  borderRadius: "0px",
                  textTransform: "none",
                  width: "25%",
                  minWidth: '120px',
                }}
                onClick={CloseSubActivityDialouge}
              >
                Exit Search
              </Button>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#81ef6f",
                  fontWeight: 600,
                  color: "#1E1D1B",
                  borderRadius: "0px",
                  textTransform: "none",
                  width: "25%",
                  minWidth: '120px',
                  "&:hover": {
                    backgroundColor: "#81EF6F",
                  },
                  display: "flex",

                  gap: "10px",
                }}
                onClick={CloseSubActivityDialouge}
                endIcon={
                  apiloading && <CircularProgress size={20} color="inherit" />
                }
              >
                Continue
              </Button>
            </Box>
          </div>
        </Dialog>
        <Box
          sx={{
            alignItems: "flex-end",
            display: "flex",
            gap: "0.1rem",
            mt: 2,
            width: "100%",
            borderTop: "2px solid #EBEBE6"
          }}
        >
          {
            applicationID === null && (<Button
              variant="contained"
              fullWidth
              className="three-btn-row"
              sx={{
                backgroundColor: "rgb(212 212 216)",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#81EF6F",
                  boxShadow: "none",
                },
                color: "#000000",
                boxShadow: "none",
                borderRadius: "0px",
                textTransform: "none",
                maxWidth: windowWidth < 640 ? "48%" : "28%",
              }}
              onClick={() => navigate("/")}
            >
              {windowWidth < 640 ? "Cancel" : "Cancel & Exit"} 
            </Button>
            )
          }
          
          <Button
            variant="contained"
            fullWidth
            disabled={apiloading}
            className="three-btn-row"
            sx={{
              backgroundColor: "rgb(212 212 216)",
              fontWeight: 600,
              // display: windowWidth < 640 ? "none" : "flex",
              "&:hover": {
                backgroundColor: "#81EF6F",
                boxShadow: "none",
              },
              color: "#000000",
              boxShadow: "none",
              borderRadius: "0px",
              textTransform: "none",
              height:"36px",
              maxWidth: applicationID === null ? "28": "48",
            }}
            onClick={() => isEverythingDisabled ? handleIsEverythingDisabled('exit') : ActivitesSelection(true)}
          >
            Save & Exit
          </Button>
          <Button
            variant="contained"
            fullWidth
            disabled={apiloading}
            className="three-btn-row"
            sx={{
              backgroundColor: "#81ef6f",
              fontWeight: 600,
              color: "#000000",
              borderRadius: "0px",
              textTransform: "none",
              boxShadow: "none",
              height:"36px",
              "&:hover": {
                backgroundColor: "#81EF6F",
                boxShadow: "none",
              },
              minWidth: applicationID === null ? "48": "60%"
            }}
            onClick={() => isEverythingDisabled ? handleIsEverythingDisabled('next') : ActivitesSelection(false)}
            endIcon={
              apiloading && (
                <svg
                  className="animate-spin w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                </svg>
              )
            }
          >
            {windowWidth < 640 && applicationID === null ? "Next" : "Next Step"} 
          </Button>
        </Box>
      </Box >
    </>
  );
}

export default BusinnessActivities;
