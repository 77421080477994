import React from 'react';
import { Modal, Box, Dialog } from '@mui/material';
// import JR_animation from "../assets/images/output-onlinegiftools.gif"


const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: 150,
    height: 120,
    bgcolor: 'background.paper',
    // boxShadow: 24,
    p: 4,
    borderRadius: "20px",
    border: "none",
    borderWidth: 0

};
const LoadingModal = ({ open, ...props }) => {
    return (
        <Dialog
            open={open}
            aria-labelledby="loading-modal-title"
            aria-describedby="loading-modal-description"
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
            sx={{
                border: "none",
                borderWidth: 0,
                boxShadow:"none",
                borderRadius:"10px",
                "& .MuiPaper-root": {
                borderRadius:"10px"
                }
            }}
        >
            <Box
                sx={style}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <svg className='animate-spin w-10 h-10 ' fill="#81EF6F" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" /></svg>

                {/* {
                    props?.fox && props?.fox === true 
                    ? 
                    <div className="flex items-center text-center md:w-[100%] justify-center overflow-hidden">
                    <img src={JR_animation} alt="Loading animation" className="filter sepia(100%) max-h-[100%]" />
                    </div> 
                    :  
                    <svg className='animate-spin w-10 h-10 ' fill="#81EF6F" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" /></svg>

                 } */}

            </Box>
        </Dialog>
    );
};

export default LoadingModal;
