export function localStorageValueTester(val){
    if (val != null && val != "null" && val != undefined && val != "undefined"){
        return true;
    }
    else{
        return false
    }
}

export function valueTester(val){
  if (val != null && val != "null" && val != undefined && val != "undefined" && val.toString()?.length !== 0){
      return true;
  }
  else{
      return false
  }
}

export function cleanArray(arr) {
    return arr.map(obj => {
        return Object.fromEntries(
            Object.entries(obj).filter(([_, value]) => value !== null && value !== '' && `${value}`?.trim() !== "" && `${value}`?.trim() !== "null" && `${value}`?.trim() !== "undefined")
        );
    });
}

export function cleanString(str) {
    if (str === null || str === undefined || str === '') {
        return '';
    }
    return `${str}`.trim();
}

export function cleanObject(obj) {
    // Create a new object by filtering out keys with unwanted values
    return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => {
        // Remove properties that are null, undefined, empty string, or only whitespace
        return value !== null && value !== undefined && value.toString().trim() !== '';
      })
    );
  }

export const handleArrowClick = ({ direction = null, ref = null, distance = 100 } = {}) => {
    const scrollContainer = ref?.current;
  
    if (!scrollContainer) return; // Ensure the ref is valid
  
    if (direction === 'left') {
      scrollContainer.scrollBy({
        left: -distance, // Scroll left by the provided or default distance
        behavior: 'smooth',
      });
    } else if (direction === 'right') {
      scrollContainer.scrollBy({
        left: distance, // Scroll right by the provided or default distance
        behavior: 'smooth',
      });
    }
  };

  export function formatNumberWithCommas(rawNumber) {
    // Check if the input is null, undefined, or not a valid number
    if (rawNumber == null || isNaN(rawNumber)) {
      return "N/A"; // Return a friendly error message
    }
  
    // Convert the number to a string with commas as thousand separators
    return Number(rawNumber).toLocaleString("en-US");
  }

  export const scrollToFirstError = (errors) => {
    const errorField = document.querySelector(`[name="${Object.keys(errors)[0]}"]`);
    if (errorField) {
      errorField.scrollIntoView({ behavior: "smooth", block: "center" });
      errorField.focus(); // Optionally set focus
    }
  };
  