import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { API_URL } from "../Utilities/ConstantSettings";

const VerificationPage = () => {
  const { id } = useParams();
  const { referral_code } = useParams();
  const [loading, setLoading] = useState(true);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("code received for this is", id);
    const verifyEmail = async () => {
      try {
        const response = await axios.post(
          `${API_URL}/api/auth/verify-email/`,
          {
            verification_code: id,
            referral_code: referral_code,
          }
        );
        if (response.status === 200) {
          console.log("Email verified successfully");
          setVerificationSuccess(true);
        } else {
          setError("Error verifying email");
        }
      } catch (error) {
        console.error("Error:", error);
        setError("An unexpected error occurred");
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        <CircularProgress color="success" />
        <div>Verifying your email address...</div>
      </div>
    );
  }

  if (verificationSuccess) {
    navigate("/signin");
  }

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      {error ? <div>{error}</div> : <div>Error: Verification failed</div>}
    </div>
  );
};

export default VerificationPage;
