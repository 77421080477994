import { Box, Container, Stack } from "@mui/material";
import SideBar from "./Sidebar";
import NavBar from "./Navbar";

const Dashboard = ({ children }) => {
    return (
        <>
            <Stack direction={"row"} className="w-full" style={{height:"100dvh", minHeight:"100dvh", maxHeight: "100dvh", }} >
                <Box flex={8} sx={{ display: "flex", flexDirection: "column", width:"100%", minHeight:"100dvh", maxHeight: "100dvh",   }}>
                    <Box sx={{ position: "sticky", top: 0, zIndex: 1000 }}>
                        <NavBar />
                    </Box>
                    <div
                        style={{ minHeight:"calc(100dvh - 65px)", maxHeight: "calc(100dvh - 65px)",  }}
                        className="scrollbar overflow-y-auto min-h-screen w-full lg:min-h-fit flex-1 px-[2px] sm:px-[12px] sm:px-5"
                    >
                        {children}
                    </div>
                </Box>
            </Stack>
        </>
    );
};

export default Dashboard;
