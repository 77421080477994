import React, { useEffect, useState } from 'react';
import {
    Typography,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    InputAdornment,
    IconButton
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import { GetAccountSettings, DeleteUserAccount, PatchUserAccountInformation, FetchReferralLink } from '../../helpers/api';
import { useDispatch } from 'react-redux';
import LoadingModal from '../../helpers/LoadingModal';
import { toast } from 'react-toastify';
import { apiCallWithAxiosCancel } from '../../helpers/api';
import { useSelector } from 'react-redux';
import { logout } from '../../features/appSlice';
import { cleanObject } from '../../Utilities/UtilityFunctions';
import Tooltip from '@mui/material/Tooltip';

// Validation schema using Yup
const validationSchema = Yup.object().shape({
    first_name: Yup.string().trim().required('Name is required'),
    email: Yup.string().trim().email('Invalid email').matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,  // Additional regex for more strict email validation
        "Invalid email address"
      ).required('Email is required'),
    current_password: Yup.string().trim().min(8, 'Password should be at least 8 characters').required('Current Password is required to make any changes.'),
    password: Yup.string().trim()
        .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
            "Password must contain at least 8 characters, 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character"
        ),
    phone_number: Yup.string().required('Phone number is required').min(9, "Phone number must be at least 9 digits")
        .max(16, "Phone number must not exceed 15 digits"),
});

function AccountSettings() {

    const [userData, setUserData] = useState(null);
    const [openSaveModal, setOpenSaveModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [loading, setLoading] = useState(false);
    const [referralLink, setReferralLink] = useState("");
    const [tooltipText, setTooltipText] = useState("Click to copy");

    const fetchReferralLink = async () => {
        try {
            const response = await FetchReferralLink(dispacth)
    
            const data = await response.data
            setReferralLink(data.referral_url || "Failed to fetch link")
            navigator.clipboard.writeText(data.referral_url)
            // setTooltipText(true)
            // setTimeout(() => setTooltipText(false), 2000)
        } catch (error) {
            console.error("Error fetching referral link:", error)
            setReferralLink("Error fetching link")
        }
    };
    
    const handleCopy = () => {
        navigator.clipboard.writeText(referralLink);
        setTooltipText("Copied!"); 
        setTimeout(() => setTooltipText("Click to copy"), 2000);
    };

    const navigate = useNavigate();
    const dispacth = useDispatch();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    // Fetch user data from API when component mounts
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const response = await GetAccountSettings(dispacth) // Replace with your API call
                setUserData(response?.data);
                console.log('user data = ', response?.data);
            } catch (error) {
                console.log(error);
                toast.error('An error occurred while fetching user data', { toastId: 'fetch-error' });
                // Optionally, set an error state here to display to the user
            }
            finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, []);

    // API call to save changes
    const handleSave = async (values, {setErrors}) => {
        if (formValues) {
            console.log('form values = ', formValues);
            try {
                setLoading(true);
                // Call your save API here
                await PatchUserAccountInformation(formValues, dispacth); // Replace with your save API
                setOpenSaveModal(false);
                toast.success('Account information updated successfully', { toastId: 'save-success' });
            } catch (error) {
                console.log(error.response.data)
                if (error.response && error.response.data && error.response.data.error === 'Incorrect current password') {
                    setErrors({current_password: "Kindly enter the correct current password."})
                }
                else if(error.response.data?.message && `${error.response.data?.message}`?.toLowerCase()?.includes("already exist")){
                    setErrors({email: "User with this email already exists." })
                }
                else {
                    // toast.error('An error occurred while saving account information', { toastId: 'save-error' });
                    setErrors({current_password: "Error occured while trying to save account information."})

                }
                console.log(error);
            } finally {
                setLoading(false);
                setOpenSaveModal(false);
            }
        }
    };

    // API call to delete account
    const handleDeleteAccount = async () => {
        try {
            setLoading(true);
            await DeleteUserAccount(dispacth); // Replace with your delete API
            setOpenDeleteModal(false);
            dispacth(logout());
            toast.success('Account deleted successfully', { toastId: 'delete-success' })
            // await apiCallWithAxiosCancel((cancelToken) => {
            //     Logout(dispacth, {}, cancelToken);
            //     
            // });


            // Optionally, redirect the user or show a success message
        } catch (error) {
            console.log(error);
            // Optionally, handle errors and inform the user
        }
        finally {
            setLoading(false);
        }
    };

    //if (!userData) return <div className='flex justify-center'>Loading...</div>;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
    
      // Add event listener on mount
      window.addEventListener("resize", handleResize);
    
      // Cleanup event listener on unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    return (
        <div className=' 2xl:mx-[100px]'>
            <LoadingModal open={loading} />
            <Formik
                initialValues={{
                    first_name: userData?.first_name || '',
                    email: userData?.email || '',
                    current_password: '',
                    password: '',
                    phone_number: userData?.phone_number || '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    setFormValues({...cleanObject(values)}); // Store form values in state
                    setOpenSaveModal(true); // Open the save modal
                }}
                enableReinitialize // Reinitialize Formik when userData changes
            >
                {({ errors, touched, values, setFieldTouched, setFieldValue, setErrors }) => (
                    <Form >
                        <div style={{ maxHeight: "calc(100dvh - 80px)", minHeight: "calc(100dvh - 80px)", }} className='grid grid-cols-7 w-full gap-8'>
                            <div className='rounded-3xl col-span-2 bg-[#FBFAFB] p-7 hidden xl:flex flex-col justify-between'>
                                <div>
                                    <Typography
                                        variant="h5"
                                        sx={{ fontWeight: 700 }}
                                        className="h-6"
                                        gutterBottom
                                        marginTop={2}
                                        marginBottom={1}
                                    >
                                        Account Settings
                                    </Typography>
                                    <p className='text-[#1E1D1B] text-sm mt-5'>Ensure that you click save below to maintain any changes you make.</p>
                                </div>

                                <div>
                                    <Divider sx={{ borderBottomWidth: 2, mb: 6, color: "#EBEBE6" }}></Divider>
                                    <Button
                                        color="primary"
                                        fullWidth
                                        type='submit'
                                        sx={{
                                            mt: "0px",
                                            mb: "0.3rem",
                                            borderRadius: "6px",
                                            border: "2px solid #81EF6F",
                                            textTransform: "none",
                                            color: "#1E1D1B",
                                            backgroundColor: "#81EF6F",
                                            '&:hover': {
                                                backgroundColor: "#81EF6F",
                                                textTransform: "none",
                                                color: "#1E1D1B",
                                            }
                                        }}
                                    >
                                        Save Changes
                                    </Button>

                                    <Button
                                        color="primary"
                                        fullWidth
                                        onClick={() => navigate('/')}
                                        sx={{
                                            mt: "1px",
                                            mb: "1rem",
                                            borderRadius: "6px",
                                            border: "2px solid #1E1D1B",
                                            textTransform: "none",
                                            color: "#1E1D1B",
                                        }}
                                    >
                                        Discard Changes
                                    </Button>
                                </div>
                            </div>
                            <div className='col-span-7 xl:col-span-5 rounded-3xl bg-[#FBFAFB] xl:p-7 px-10 xl:pt-12'>
                            
                                <p className="font-bold text-xl sm:text-2xl text-[#1E1D1B] pt-8">Account Settings</p>

                                {
                                windowWidth < 1280 ? <hr style={{borderWidth:"1px", margin:"10px 0px 20px 0px"}}/> : ""
                                }

                            {/* <div className="flex items-center mt-4">
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            fetchReferralLink();
                                        }}
                                        className="text-xs mr-2 px-4 py-2 bg-[#81EF6F] text-black rounded-lg hover:bg-[#81EF6F] whitespace-nowrap"
                                    >
                                        Generate Referral Link
                                    </button>
                                        <Tooltip title={tooltipText} arrow placement="top">
                                            <input
                                                type="text"
                                                value={referralLink}
                                                readOnly
                                                className="border border-gray-300 rounded-lg px-3 py-2 text-sm text-gray-800 w-full"
                                                id="copyInput"
                                                onClick={handleCopy}
                                            />
                                        </Tooltip>
                                    </div> */}

                                {/* <Divider sx={{ borderBottomWidth: 2, mt:3, mb: 4, color: "#EBEBE6" }}></Divider> */}

                                <div className='w-full  lg:w-[80%]'>
                                    <div className='grid sm:grid-cols-2 gap-10'>
                                        <div className="flex w-full flex-col items-start">
                                            <div className="text-[#323338] font-semibold">
                                                Full Name
                                            </div>
                                            <Field
                                                size="small"
                                                as={TextField}
                                                marginBottom="normal"
                                                placeholder="Name"
                                                name="first_name"
                                                fullWidth
                                                error={touched.first_name && !!errors.first_name}
                                                helperText={touched.first_name && errors.first_name}
                                            />
                                        </div>
                                        <div className="flex w-full flex-col items-start">
                                            <div className="text-[#323338] font-semibold">
                                                Current Password
                                            </div>
                                            <Field
                                                marginBottom="normal"
                                                size="small"
                                                as={TextField}
                                                name="current_password"
                                                placeholder="Current Password"
                                                type={showPassword ? "text" : "password"}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                error={touched.current_password && !!errors.current_password}
                                                helperText={touched.current_password && errors.current_password}
                                            />
                                        </div>
                                    </div>
                                    <div className='grid sm:grid-cols-2 gap-10 mt-4'>
                                        <div className="flex w-full flex-col items-start">
                                            <div className="text-[#323338] font-semibold">
                                                Email
                                            </div>
                                            <Field
                                                size="small"
                                                as={TextField}
                                                placeholder="Email"
                                                disabled
                                                name="email"
                                                fullWidth
                                                error={touched.email && !!errors.email}
                                                helperText={touched.email && errors.email}
                                            />
                                            {/* <p className='text-xs text-[#8C8C8C]'>You will need to check your email inbox to verify and change to a new email address.</p> */}
                                        </div>
                                        <div className="flex w-full flex-col items-start">
                                            <div className="text-[#323338] font-semibold ">
                                                Create a New Password
                                            </div>
                                            <Field
                                                size="small"
                                                as={TextField}
                                                name="password"
                                                placeholder="At least 8 characters"
                                                type={showPasswordNew ? "text" : "password"}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setShowPasswordNew(!showPasswordNew)}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPasswordNew ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                error={touched.password && !!errors.password}
                                                helperText={touched.password && errors.password}
                                            />
                                            <p className='text-xs text-[#8C8C8C]'> Create a secure password using a combination of letters, numbers, and symbols.</p>
                                        </div>
                                    </div>
                                    <div className='grid sm:grid-cols-2 gap-10 mt-4'>
                                        <div className="flex w-full flex-col items-start">
                                            <div className="text-[#323338] font-semibold">
                                                Phone Number
                                            </div>
                                            <MuiTelInput
                                                size="small"
                                                value={values.phone_number}
                                                forceCallingCode
                                                defaultCountry='AE'
                                                disableFormatting 
                                                onBlur={() => setFieldTouched('phone_number', true)}
                                                onChange={(value) => setFieldValue('phone_number', value)} // Correct usage
                                                fullWidth
                                                error={touched.phone_number && !!errors.phone_number}
                                                helperText={touched.phone_number && errors.phone_number}
                                            />
                                        </div>
                                        <div >
                                            <div></div>
                                            {/* <div className='border-t-2 pt-3 mt-6 '>
                                                <p className='text-sm text-[#1E1D1B]'>Want to deactivate your account? <span onClick={() => setOpenDeleteModal(true)} className='underline cursor-pointer'>Click here</span></p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='flex flex-col sm:flex-row gap-4 sm:gap-10 xl:hidden justify-start sm:mt-12 mt-6 pb-6'>
                                    
                                    <Button
                                        color="primary"
                                        fullWidth
                                        type='submit'
                                        sx={{
                                            
                                            borderRadius: "6px",
                                            border: "2px solid #81EF6F",
                                            textTransform: "none",
                                            color: "#1E1D1B",
                                            backgroundColor: "#81EF6F",
                                            '&:hover': {
                                                backgroundColor: "#81EF6F",
                                                textTransform: "none",
                                                color: "#1E1D1B",
                                            }
                                        }}
                                    >
                                        Save Changes
                                    </Button>

                                    <Button
                                        color="primary"
                                        fullWidth
                                        onClick={() => navigate('/')}
                                        sx={{
                                            
                                            borderRadius: "6px",
                                            border: "2px solid #1E1D1B",
                                            textTransform: "none",
                                            color: "#1E1D1B",
                                        }}
                                    >
                                        Discard Changes
                                    </Button>
                                </div>

                                    {/* Save Confirmation Modal */}
                                    <Dialog open={openSaveModal} onClose={() => setOpenSaveModal(false)}>
                                        <DialogTitle>Confirm Changes</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Are you sure you want to save the changes to your account?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            {/* <Button onClick={() => setOpenSaveModal(false)} color="primary">
                                            Cancel
                                        </Button>
                                        <Button onClick={handleSave} color="primary">
                                            Yes, Save
                                        </Button> */}
                                            <Button
                                                color="primary"
                                                fullWidth
                                                onClick={() => setOpenSaveModal(false)}
                                                sx={{

                                                    borderRadius: "6px",
                                                    border: "2px solid #1E1D1B",
                                                    textTransform: "none",
                                                    color: "#1E1D1B",
                                                    width: "20%",
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                color="primary"
                                                fullWidth
                                                sx={{

                                                    borderRadius: "6px",
                                                    border: "2px solid #81EF6F",
                                                    textTransform: "none",
                                                    color: "#1E1D1B",
                                                    backgroundColor: "#81EF6F",
                                                    '&:hover': {
                                                        backgroundColor: "#81EF6F",
                                                        textTransform: "none",
                                                        color: "#1E1D1B",
                                                    },
                                                    minWidth:"81px",
                                                    width: "20%",
                                                }}
                                                disabled={loading}
                                                onClick={() => handleSave(values, {setErrors})}
                                            >
                                                Yes, Save
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>


                            </div>
                        </div>

                        {/* Delete Account Confirmation Modal */}
                        <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                            <DialogTitle>Delete Account</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete your account? This action cannot be undone.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                {/* <Button onClick={() => setOpenDeleteModal(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteAccount} color="error">
                        Yes, Delete
                    </Button> */}
                                <Button
                                    color="primary"
                                    fullWidth
                                    onClick={() => setOpenDeleteModal(false)}
                                    sx={{

                                        borderRadius: "6px",
                                        border: "2px solid #1E1D1B",
                                        textTransform: "none",
                                        color: "#1E1D1B",
                                        width: "20%",
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    fullWidth
                                    sx={{

                                        borderRadius: "6px",
                                        border: "2px solid #81EF6F",
                                        textTransform: "none",
                                        color: "#1E1D1B",
                                        backgroundColor: "#81EF6F",
                                        '&:hover': {
                                            backgroundColor: "#81EF6F",
                                            textTransform: "none",
                                            color: "#1E1D1B",
                                        },
                                        width: "20%",
                                    }}
                                    disabled={loading}
                                    onClick={handleDeleteAccount}
                                >
                                    Yes, Deactivate
                                </Button>


                            </DialogActions>
                        </Dialog>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default AccountSettings;
