import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  app_id: "",
  is_blocked: false,
  currentStep: 0,
};

export const stepBlockerSlice = createSlice({
  name: "stepBlocker",
  initialState,
  reducers: {
    blockStep: (state, action) => {
    
      if(action.payload.app_id && action.payload.app_id.length > 0){
        state.app_id = action.payload.app_id
        state.is_blocked = action.payload.is_blocked;
        state.currentStep = action.payload.currentStep;
      }
      else{
        state.is_blocked = action.payload.is_blocked;
        state.currentStep = action.payload.currentStep;
      }
            
    },
    // unblock on save and exit, logout
    unblockStep: (state) => {
      state.is_blocked = false;
    },

    clearBlockStep: (state) => {
      state.is_blocked = false;
      state.app_id = null;
      state.currentStep = 0;
    }
  },
});

export const { blockStep, unblockStep, clearBlockStep } = stepBlockerSlice.actions;

export default stepBlockerSlice.reducer;