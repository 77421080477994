import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  SvgIcon, Drawer
} from "@mui/material";
import { Logout } from "../authservices/Logout"
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import logo from "../assets/svgs/firmfox-logo.svg"
import { useNavigate, useLocation } from 'react-router-dom';
import { apiCallWithAxiosCancel } from '../helpers/api';
import CloseIcon from '@mui/icons-material/Close';


function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.app);
  const [apiloading, setApiloading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerCustom, setOpenDrawerCustom] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      // Check if the drawer is open and screen size is greater than a certain breakpoint
      if (openDrawer && window.innerWidth > 1199) {
        setOpenDrawer(false); // Close the drawer
        setOpenDrawerCustom(false)
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [openDrawer]);

  const HandleLogout = async () => {
    try {
      setApiloading(true);
      await apiCallWithAxiosCancel((cancelToken) =>
        Logout(dispatch, {}, cancelToken)
    );      setApiloading(false);
    } catch (err) {
      if(err?.code !== "ERR_CANCELED" || err?.name !== "CanceledError"){
        console.log(err);
        setApiloading(false);
        // toast.error("Oops! Something went wrong.Please Try Again Later", {
        //   position: "bottom-right",
        //   toastId: "signin_fails",
        // });
      }

    }
  };
  console.log("role of the user", role)
  const location = useLocation();

  return (
    <div className='2xl:mx-[100px]'>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#ebebe6",
          alignItems: "center",
          minHeight: "65px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >

        <div className='flex justify-between px-5 w-full lg:justify-between items-center gap-3 h-[65px]'>
          <Box sx={{ display: "flex", gap: "2.5rem", }}>
            <img src={logo} className='firmfox-logo-mobile' alt="logo of firmfox" />
          </Box>
          <div className="hidden xl:flex ">
            <div className=' flex justify-center'>
              {
                role &&
                <>
                  <div className='lg:w-44 '>
                    <Button
                      variant='text'
                      sx={{
                        color: "#1E1D1B",
                        fontSize: "17px",
                        textTransform: "none",
                      }}
                      // onClick={() => Logout(dispatch, user)}
                      onClick={() => navigate('/admin')}
                    >
                      Admin Dashboard
                    </Button>
                  </div>
                  <div className='lg:w-36 '>
                    <Button onClick={() => navigate("/create-agent")} sx={{
                      color: "#1E1D1B",
                      fontSize: "17px",
                      textTransform: "none",
                    }} className={` capitalize text-red-500 ${location.pathname === "/create-agent" ? 'underline' : ''} cursor-pointer min-w-36  text-start text-[17px] font-normal`}>Create Agent</Button>

                  </div></>

              }

            </div>
            <div className='lg:w-36 '>
              <Button
                onClick={() => navigate('/')}
                variant='text'
                sx={{
                  color: "#1E1D1B",
                  fontSize: "17px",
                  textTransform: "none",
                }}
              >
                Dashboard
              </Button>
            </div>

            <div className='lg:w-44 '>
            
              <Button

                variant='text'
                sx={{
                  color: "#1E1D1B",
                  fontSize: "17px",
                  textTransform: "none",
                }}
                onClick={() => navigate('/account-settings')}
              >
                Account Settings
              </Button>
              
            </div>

            <div className='lg:w-25'>
            
            <Button

              variant='text'
              sx={{
                color: "#1E1D1B",
                fontSize: "17px",
                textTransform: "none",
              }}
              onClick={() => navigate('/referral')}
            >
              Refer a friend
            </Button>
            
          </div>

            <div className='lg:w-28 flex justify-center '>
              <Button
                variant='text'
                sx={{
                  color: "#1E1D1B",
                  fontSize: "17px",
                  textTransform: "none",
                }}
                endIcon={
                  apiloading && <svg
                    className="animate-spin w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                  </svg>
                }
                // onClick={() => Logout(dispatch, user)}
                onClick={HandleLogout}
              >
                Sign Out
              </Button>
            </div>

            <div className='w-36 lg:flex justify-end hidden '>
              {" "}
              <a href="https://firmfox.com/contact-us" rel="no" target="_blank">
              <Button
                variant='outlined'
                sx={{
                  fontSize: "17px",
                  textTransform: "none",
                  color: "#1E1D1B",
                  border: "2px solid #1E1D1B",
                  borderRadius: "30px",
                  "&:hover": {
                    backgroundColor: "#1E1D1B",
                    color: "white",
                    border: "2px solid #1E1D1B",
                  },
                }}
              >
                {/* Get in Touch */}
                Learn More
              </Button>
              </a>
            </div>
          </div>

          {
            openDrawerCustom ? <span onClick={()=>setOpenDrawerCustom(false)}><CloseIcon/></span> : 

            <SvgIcon
              onClick={() => {
                setOpenDrawer(true);
                setOpenDrawerCustom(true);
              }}
              sx={{
                cursor: "pointer",
                display: { xs: "block", lgc: "none" },
              }}
            >
              <svg
                fill="grey"
                style={{ cursor: "pointer" }}
                className="w-44"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>{" "}
            </SvgIcon>            
          }


        </div>
      </Box>
      <Drawer
        sx={{ xs: "block", lgc: "none" }}
        anchor="left"
        // open={openDrawer}

        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        role="presentation"
      >
        <Box sx={{ width: "50vw", padding: "3.5%", background: "white" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              pt: 1,
              marginLeft: "4%",
            }}
          >

          </Box>
          <div className='flex flex-col justify-center pt-10 '>
            <img src={logo} width="57%" alt="logo of firmfox" onClick={() => setOpenDrawer(false)} className='mb-20 w-8/12' />
            <Button
              onClick={() => {navigate("/"); setOpenDrawer(false)}}
              variant="text"
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "24px"
                }, // Set font size
                fontWeight: 'bold',
                textAlign: 'left', // Align text to the start (left)
                justifyContent: 'flex-start', // Ensures text aligns left within button
                color: 'black',
                textTransform: 'none', // Prevent capitalization
                textDecoration: 'none', // Ensure no underline
                width: '100%', // Optional: Ensures full width for alignment
              }}
            >
              Dashboard
            </Button>

            <Button
              onClick={() => {navigate("/foxchat"); setOpenDrawer(false)}}
              variant="text"
              sx={{
                 fontSize: {
                  xs: "16px",
                  sm: "24px"
                }, // Set font size
                fontWeight: 'bold',
                textAlign: 'left',
                justifyContent: 'flex-start',
                color: 'black',
                textTransform: 'none',
                textDecoration: 'none',
                width: '100%',
              }}
            >
              Fox Chat
            </Button>

            <Button
              onClick={() => {navigate("/account-settings"); setOpenDrawer(false)}}
              variant="text"
              sx={{
                 fontSize: {
                  xs: "16px",
                  sm: "24px"
                }, // Set font size
                fontWeight: 'bold',
                textAlign: 'left',
                justifyContent: 'flex-start',
                color: 'black',
                textTransform: 'none',
                textDecoration: 'none',
                width: '100%',
              }}
            >
              Account Settings
            </Button>

            <Button
              onClick={() => {navigate("/referral"); setOpenDrawer(false)}}
              variant="text"
              sx={{
                 fontSize: {
                  xs: "16px",
                  sm: "24px"
                }, // Set font size
                fontWeight: 'bold',
                textAlign: 'left',
                justifyContent: 'flex-start',
                color: 'black',
                textTransform: 'none',
                textDecoration: 'none',
                width: '100%',
              }}
            >
              Referral
            </Button>

            <Button
              component="a"
              href="https://firmfox.com/our-services"
              target="_blank"
              rel="noopener"
              variant="text"
              sx={{
                 fontSize: {
                  xs: "16px",
                  sm: "24px"
                }, // Set font size
                fontWeight: 'bold',
                textAlign: 'left',
                justifyContent: 'flex-start',
                color: 'black',
                textTransform: 'none',
                textDecoration: 'none',
                width: '100%',
              }}
            >
              Our Services
            </Button>

            <Button
              onClick={()=>{HandleLogout(); setOpenDrawer(false)}}
              variant="text"
              sx={{
                 fontSize: {
                  xs: "16px",
                  sm: "24px"
                }, // Set font size
                fontWeight: 'bold',
                textAlign: 'left',
                justifyContent: 'flex-start',
                color: 'black',
                textTransform: 'none',
                textDecoration: 'none',
                width: '100%',
                mb: 2, // Margin bottom
              }}
            >
              Sign Out
            </Button>

            <Button variant="contained" sx={{
                fontSize: {
                  xs: "16px",
                  sm: "18px"
                },
                textTransform: "none", color: "white", backgroundColor: "#1E1D1B", '&:hover': {
                backgroundColor: '#1E1D1B'
              }
            }} >Find Out More</Button>

          </div>
        </Box>
      </Drawer>

    {/* Mobile Sidebar */}
    
    {
      openDrawerCustom && 
      <div hidden={openDrawerCustom === true ? false : true} style={{background:"#ebebe6", width:"100%", minHeight:"calc(100dvh - 65px)", position:"absolute"
      }}> 
          <div hidden={openDrawerCustom === true ? false : true} style={{background:"white",}} className='rounded-[20px] w-full flex flex-col justify-between drawer-custom'>

          

          <Box sx={{ width: "100%", padding: "3.5%", background: "white", borderRadius:"20px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  pt: 1,
                  marginLeft: "4%",
                }}
              >

              </Box>
              <div className='flex flex-col justify-center pt-10 '>
                {/* <img src={logo} width="57%" alt="logo of firmfox" onClick={() => setOpenDrawer(false)} className='mb-20 w-8/12' /> */}
                
                <Box
              component="div"
              className=" w-[95%]"
              sx={{
                display: "flex",
                height: "65px",
                backgroundColor: "#B9B1F7",
                position: "absolute",
                top: 30,
                right: -10, // Adjust this value to move the box further left
                borderTopLeftRadius: "300px",
                borderBottomLeftRadius: "300px",
                margin: "10px", // Change color and width as needed
                justifyContent: "center",
                alignItems: "center",
                zIndex: 2, // ensure the SVG is above the black background
                overflow: "hidden", // SVG and CloseIcon should always be visible
              }}
              onClick={()=>{setOpenDrawerCustom(false); navigate("/foxchat")}}
            >

                <div style={{display:"flex", justifyContent:"flex-start", width:"100%", paddingLeft:"20px"}} className="flex items-center gap-2">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 53 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                className="ml-1"
                >
                  <path
                    d="M27.6383 52.6375C26.9597 53.1168 26.0455 53.1168 25.3669 52.6375L8.55269 40.7767C3.17417 36.9866 -0.0170457 30.9057 -0.000220289 24.5183V24.3846C-0.0787388 13.9841 3.58359 1.8948 3.74063 1.38203C4.17248 -0.0169641 5.93914 -0.468431 6.99354 0.557124L20.0444 13.2093C20.409 13.5661 20.9025 13.7611 21.4129 13.7611H31.5923C32.1026 13.7611 32.5962 13.5605 32.9607 13.2093L46.0172 0.568271C47.0716 -0.45171 48.8383 -0.000243068 49.2645 1.39875C49.4216 1.90595 53.0783 13.9897 52.9998 24.4068C53.0334 30.85 49.8815 36.9476 44.5759 40.6931L27.6383 52.643V52.6375ZM7.53195 6.5321C7.13375 6.14751 6.46634 6.33702 6.33735 6.87209C5.27735 11.253 3.88084 18.2256 3.92571 24.3734V24.5239C3.90888 29.6517 6.48877 34.5342 10.8185 37.5941L25.3613 47.8553C26.0399 48.3346 26.9541 48.3346 27.6327 47.8553L42.2988 37.5105C46.5669 34.5008 49.0963 29.6015 49.0738 24.4013C49.1187 18.2424 47.7222 11.2697 46.6678 6.88881C46.5388 6.35374 45.8714 6.15866 45.4732 6.54324L34.5591 17.1109C34.1946 17.4621 33.7011 17.6627 33.1908 17.6627H21.8391C21.3288 17.6627 20.8353 17.4621 20.4708 17.1109L7.53195 6.5321Z"
                    fill="#1E1D1B"
                  />
                  <path
                    d="M22.6302 32.2439C22.6302 33.8101 21.5365 34.8524 19.9157 34.8524C18.2948 34.8524 17.2012 33.8101 17.2012 32.2439C17.2012 30.6777 18.2948 29.5908 19.9157 29.5908C21.5365 29.5908 22.6302 30.6777 22.6302 32.2439Z"
                    fill="#1E1D1B"
                  />
                  <path
                    d="M35.7986 32.2439C35.7986 33.8101 34.705 34.8524 33.0841 34.8524C31.4633 34.8524 30.3696 33.8101 30.3696 32.2439C30.3696 30.6777 31.4633 29.5908 33.0841 29.5908C34.705 29.5908 35.7986 30.6777 35.7986 32.2439Z"
                    fill="#1E1D1B"
                  />
                </svg>
                <p className="font-bold leading-none">Fox Chat <span className="text-center font-normal leading-none">(beta)</span></p>
                </div>
              
            </Box>

            <div className='flex flex-col pl-[7%]'>
            <Button
                  onClick={() => {navigate("/"); setOpenDrawerCustom(false)}}
                  variant="text"
                  sx={{
                    mt:6,
                    fontSize: {
                      xs: "16px",
                      sm: "24px"
                    }, // Set font size
                    fontWeight: "400",
                    textAlign: 'left', // Align text to the start (left)
                    justifyContent: 'flex-start', // Ensures text aligns left within button
                    color: 'black',
                    textTransform: 'none', // Prevent capitalization
                    textDecoration: 'none', // Ensure no underline
                    width: '100%', // Optional: Ensures full width for alignment
                  }}
                >
                  Dashboard
                </Button>

                {/* <Button
                  onClick={() => {navigate("/foxchat"); setOpenDrawerCustom(false)}}
                  variant="text"
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "24px"
                    }, // Set font size
                    fontWeight: window.location.href.includes("foxchat") ? 'bolder' : "400",
                    textAlign: 'left',
                    justifyContent: 'flex-start',
                    color: 'black',
                    textTransform: 'none',
                    textDecoration: 'none',
                    width: '100%',
                  }}
                >
                  Fox Chat
                </Button> */}

                <Button
                  onClick={() => {navigate("/account-settings"); setOpenDrawerCustom(false)}}
                  variant="text"
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "24px"
                    }, // Set font size
                    fontWeight: window.location.href.includes("account-settings") ? 'bolder' : "400",
                    textAlign: 'left',
                    justifyContent: 'flex-start',
                    color: 'black',
                    textTransform: 'none',
                    textDecoration: 'none',
                    width: '100%',
                  }}
                >
                  Account Settings
                </Button>

                <Button
                  onClick={() => {navigate("/referral"); setOpenDrawerCustom(false)}}
                  variant="text"
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "24px"
                    }, // Set font size
                    fontWeight: window.location.href.includes("referral") ? 'bolder' : "400",
                    textAlign: 'left',
                    justifyContent: 'flex-start',
                    color: 'black',
                    textTransform: 'none',
                    textDecoration: 'none',
                    width: '100%',
                  }}
                >
                  Refer a friend
                </Button>

                <Button
                  component="a"
                  href="https://firmfox.com/our-services"
                  target="_blank"
                  rel="noopener"
                  variant="text"
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "24px"
                    }, // Set font size
                    fontWeight: '400',
                    textAlign: 'left',
                    justifyContent: 'flex-start',
                    color: 'black',
                    textTransform: 'none',
                    textDecoration: 'none',
                    width: '100%',
                  }}
                >
                  Our Services
                </Button>

                <Button
                  onClick={()=>{HandleLogout(); setOpenDrawerCustom(false)}}
                  variant="text"
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "24px"
                    }, // Set font size
                    fontWeight: '400',
                    textAlign: 'left',
                    justifyContent: 'flex-start',
                    color: 'black',
                    textTransform: 'none',
                    textDecoration: 'none',
                    width: '100%',
                    mb: 2, // Margin bottom
                  }}
                >
                  Sign Out
                </Button>


                {/* <Button variant="contained" sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "18px"
                    },
                    textTransform: "none", color: "white", backgroundColor: "#1E1D1B", '&:hover': {
                    backgroundColor: '#1E1D1B'
                  }
                }} >Find Out More</Button> */}
            </div>
                
             

              </div>
            </Box>

        

          </div>      
      </div>
    }


</div>
  );
}

export default Navbar;