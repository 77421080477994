import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import SignUpPage from './authRoutes/SIgnUpPage';
import { LandingPage } from './components/LandingPage';
import LogInPage from './authRoutes/LogIn';
import Notificaation from './components/businessDashboard/Notificaation';
import VerificationPage from './authRoutes/VerificationEmail';
import { useSelector } from 'react-redux';
import Dashboard from './dashboard/Index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainActivity from "./BusinessActivities/MainActivity";
import AdminMain from "./AdminDashboard/AdminMain";
import CompleteDetails from "./AdminDashboard/CompleteDetails";
import CreateAgent from "./AdminDashboard/CreateAgent";
import ForgetPassword from "./authRoutes/ForgetPassword";
import ResetPassword from "./authRoutes/ResetPassword";
import FoxChatScreen from "./BusinessActivities/FoxChatScreen/FoxChatScreen";
import StripePaymentConfirm from "./BusinessActivities/BusinessPages/StripePaymentConfirm";
import StripeFailedPayment from "./BusinessActivities/BusinessPages/StripeFailedPayment";
import AccountSettings from "./BusinessActivities/AccountSettings/AccountSettings";
import Referral from './authRoutes/Referral';
import MetaPixel from './AdminDashboard/components/MetaPixel';

const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes("Chrome") && !userAgent.includes("Edge")) {
    return "Chrome";
  } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    return "Safari";
  } else if (userAgent.includes("Firefox")) {
    return "Firefox";
  } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    return "Opera";
  } else if (userAgent.includes("Edge")) {
    return "Edge";
  } else {
    return "Unknown";
  }
};

const detectDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  } else if (/Android/.test(userAgent)) {
    return "Android";
  } else if (/Macintosh|Mac OS X/.test(userAgent)) {
    return "macOS";
  } else if (/Windows/.test(userAgent)) {
    return "Windows";
  } else {
    return "Unknown";
  }
};


export const browser = getBrowserInfo()?.toLowerCase()
export const device = detectDevice()?.toLowerCase()
localStorage.setItem("browser", browser)
localStorage.setItem("device", device)

function App() {
  const { role, user } = useSelector((state) => state.app);
  const applicationID = useSelector((state) => state.app.applicationID);
  // console.log('app id = ', applicationID);
  const roles = role;
  // console.log('role of thwe use', roles);

  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, [location]);



  useEffect(() => {
    if (!currentUrl.includes("business")) {
      sessionStorage.setItem("current_step", null);
    }
  }, [currentUrl]);

  return (
    <>
      {/* <MetaPixel/> */}
        <Routes>
          {user ? (
            <>
              {role && (
                <>
                  <Route
                    path="/"
                    element={
                      <Navigate
                        to={'/admin'}
                        replace
                      />
                    }
                  />
                  <Route
                    path="/admin"
                    element={
                      <Dashboard>
                        <AdminMain />
                      </Dashboard>
                    }
                  />
                  <Route
                    path="/create-agent"
                    element={
                      <Dashboard>
                        <CreateAgent />
                      </Dashboard>
                    }
                  />
                  <Route
                    path="/application-details/:id"
                    element={
                      <Dashboard>
                        <CompleteDetails />
                      </Dashboard>
                    }
                  />
                </>
              )}
              <Route
                path="/"
                element={
                  <Dashboard>
                    <Notificaation />
                  </Dashboard>
                }
              />

              <Route
                path="/business"
                element={
                  <Dashboard>
                    <MainActivity />
                  </Dashboard>
                }
              />
              <Route
                path="/stripe-success"
                element={
                  <Dashboard>
                    <StripePaymentConfirm />
                  </Dashboard>
                }
              />
              <Route
                path="/stripe-failed"
                element={
                  <Dashboard>
                    <StripeFailedPayment />
                  </Dashboard>
                }
              />

              <Route
                path="/foxchat"
                element={
                  <Dashboard>
                    <FoxChatScreen />
                  </Dashboard>
                }
              />
              <Route
                path="*"
                element={
                  <Navigate
                    to={'/'}
                    replace
                  />
                }
              />

<Route
              path="/account-settings"
              element={
                <Dashboard>
                  <AccountSettings />
                </Dashboard>
              } 
            />
              {/* <Route
                path="/account-settings"
                element={
                  <Dashboard>
                    <AccountSettings />
                  </Dashboard>
                }
              /> */}
            </>
          ) : (
            <>
              <Route
                path="/"
                element={<LandingPage />}
              />
              <Route
                path="/signin"
                element={<LogInPage />}
              />
              <Route
                path="/signup"
                element={<SignUpPage />}
              />
              <Route
                path="/ForgetPassword/"
                element={<ForgetPassword />}
              />
              <Route
                path="*"
                element={
                  <Navigate
                    to={'/'}
                    replace
                  />
                }
              />
              <Route
                path="/verify-email/:id"
                element={<VerificationPage />}
              />
              <Route
                path="/ResetPassword/:id"
                element={<ResetPassword />}
              />
            </>
          )}

          <Route
            path="/referral"
            element={<Referral />}
          />

        </Routes>
      
      <ToastContainer />
  </>
  

  );
}

export default App;
