export const Environments = {
    "dev": "https://yce3362q2s.us-east-1.awsapprunner.com/", // staging mode
    "prod": "https://g2scsqxnpk.ap-southeast-1.awsapprunner.com/" // production mode
  }
  
  const env_value = process.env.REACT_APP_PROJECT_MODE; 

  let url = `https://yce3362q2s.us-east-1.awsapprunner.com/` // default url is staging/dev environment

  if (env_value){
    url = Environments[env_value]
  }
// export const API_URL = `http://127.0.0.1:8000/`
export const API_URL = url