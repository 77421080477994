import axios from "axios";
import { getToken } from "./getToken";
import { logout } from "../features/appSlice"; // Adjust the import according to your actual logout action
import { API_URL } from "../Utilities/ConstantSettings";

const handleLogout = (dispatch) => {
  dispatch(logout());
};

const getAxiosInstance = (dispatch) => {
  const instance = axios.create({
    baseURL: `${API_URL}/api/`,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });

  // Add a response interceptor
  instance.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        // Assuming you have a Redux action called `logout`
        console.log("Logging out due to 401 error");
        handleLogout(dispatch);
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

// Generic Axios call cancellation handler
export const apiCallWithAxiosCancel = (() => {
  let cancelTokenSource = null;

  return async (apiCallFunction) => {
    // Cancel any ongoing request
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Operation canceled due to a new request.');
    }

    // Create a new cancel token for the next request
    cancelTokenSource = axios.CancelToken.source();

    try {
      // Execute the API call function with the cancel token
      const response = await apiCallFunction(cancelTokenSource.token);
      cancelTokenSource = null; // Reset the cancel token after successful call
      return response; // Return the response from the API call
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('API call failed:', error);
      }
      throw error; // Rethrow error for further handling
    }
  };
})();

// export const SaveTradingName = async (payload, dispatch) => {
//   const axiosInstance = getAxiosInstance(dispatch)
//   const data = await axiosInstance.post("/business/create-trade-name/", payload);
//   return data;
// };

// Modified SaveTradingName function with CancelToken
export const SaveTradingName = async (payload, dispatch, cancelToken) => {
  const axiosInstance = getAxiosInstance(dispatch);
  
  try {
    // Making the API call and passing the cancel token
    const response = await axiosInstance.post("/business/create-trade-name/", payload, {
      cancelToken,  // Pass the cancel token to allow for cancellation
    });
    
    return response; // Return the whole response object as it is
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled');
    } else {
      console.error('API call failed:', error);
    }
    throw error;  // Rethrow error to handle it in the calling function
  }
};

export const MessageSeen = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch)
  const data = await axiosInstance.post("/agent/messages-seen/", payload);
  return data;
};



export const GetNationalities = async (dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch)
  const data = await axiosInstance.get("/business/nationalities/");
  return data;
};

// export const SaveIntialPartnerDetails = async (payload, dispatch) => {
//   const axiosInstance = getAxiosInstance(dispatch)
//   const data = await axiosInstance.post("/business/save-partner-initials/", payload);
//   return data;
// };

// Modified SaveIntialPartnerDetails function with CancelToken
export const SaveIntialPartnerDetails = async (payload, dispatch, cancelToken) => {
  const axiosInstance = getAxiosInstance(dispatch);
  
  try {
    // Making the API call and passing the cancel token
    const response = await axiosInstance.post("/business/save-partner-initials/", payload, {
      cancelToken,  // Pass the cancel token to allow for cancellation
    });
    
    return response; // Return the whole response object as it is
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled');
    } else {
      console.error('API call failed:', error);
    }
    throw error;  // Rethrow error to handle it in the calling function
  }
};


export const GetLegalType = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch)
  const data = await axiosInstance.get(`/business/get-legaltypes/?id=${payload}`);
  return data;
};

// export const SaveLegalType = async (payload, dispatch) => {
//   const axiosInstance = getAxiosInstance(dispatch)
//   const data = await axiosInstance.post("/business/save-legaltypes/", payload);
//   return data;
// };

// Modified SaveLegalType function with CancelToken
export const SaveLegalType = async (payload, dispatch, cancelToken) => {
  const axiosInstance = getAxiosInstance(dispatch);
  
  try {
    // Making the API call and passing the cancel token
    const response = await axiosInstance.post("/business/save-legaltypes/", payload, {
      cancelToken,  // Pass the cancel token to allow for cancellation
    });
    
    return response; // Return the whole response object as it is
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled');
    } else {
      console.error('API call failed:', error);
    }
    throw error;  // Rethrow error to handle it in the calling function
  }
};

export const GetLicenseType = async (dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch)
  const data = await axiosInstance.get("/business/get-licensetypes/");
  return data;
};

export const SaveLicenseType = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch)
  const data = await axiosInstance.post("/business/save-licensetypes/", payload);
  return data;
};

export const GetMainActivities = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch)
  const data = await axiosInstance.get("/business/business-activities/", payload);
  return data;
};

export const GetSubActivities = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch)
  const data = await axiosInstance.get(`/business/business-sub-activities/?page=${payload}`);
  return data;
};


// export const SaveBusinessActivities = async (payload, dispatch) => {
//   const axiosInstance = getAxiosInstance(dispatch)
//   const data = await axiosInstance.post("/business/save-business-activities/", payload);
//   return data;
// };

// Modified SaveBusinessActivities function with CancelToken
export const SaveBusinessActivities = async (payload, dispatch, cancelToken) => {
  const axiosInstance = getAxiosInstance(dispatch);
  
  try {
    // Making the API call and passing the cancel token
    const response = await axiosInstance.post("/business/save-business-activities/", payload, {
      cancelToken,  // Pass the cancel token to allow for cancellation
    });
    
    return response; // Return the whole response object as it is
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled');
    } else {
      console.error('API call failed:', error);
    }
    throw error;  // Rethrow error to handle it in the calling function
  }
};

export const GetPartnerDetails = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch)
  const data = await axiosInstance.get(`/business/get-partner-details/?id=${payload}`);
  return data;
};

// export const GetApplicationData = async (payload, dispatch) => {
//   const axiosInstance = getAxiosInstance(dispatch);
//   const data = await axiosInstance.get(`/business/get-step-details/?id=${payload}`);
//   return data;
// };

// Modified GetApplicationData function with CancelToken
export const GetApplicationData = async (payload, dispatch, cancelToken) => {
  const axiosInstance = getAxiosInstance(dispatch);

  try {
    // Making the API call and passing the cancel token
    const response = await axiosInstance.get(`/business/get-step-details/?id=${payload}`, {
      cancelToken,  // Pass the cancel token to allow for cancellation
    });
    // console.log("res = ", response)
    return response; // Returning just the data from the response
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled');
    } else {
      console.error('API call failed:', error);
    }
    throw error;  // Rethrow error to handle it in the calling function
  }
};

export const RemoveSubActivityApi = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.post("/business/delete-activities/", payload, {
    // headers: {
    //   "Content-Type": "multipart/form-data",
    // },
  });
  return data;
};

// export const SavePartnerDetails = async (payload, dispatch) => {
//   const axiosInstance = getAxiosInstance(dispatch);
//   const data = await axiosInstance.post("/business/complete-partner-details/", payload, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//   });
//   return data;
// };

// Modified SavePartnerDetails function with CancelToken
export const SavePartnerDetails = async (payload, dispatch, cancelToken) => {
  const axiosInstance = getAxiosInstance(dispatch);
  
  try {
    // Making the API call and passing the cancel token
    const response = await axiosInstance.post("/business/complete-partner-details/", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      cancelToken,  // Pass the cancel token to allow for cancellation
    });
    
    return response; // Return the whole response object as it is
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled');
    } else {
      console.error('API call failed:', error);
    }
    throw error;  // Rethrow error to handle it in the calling function
  }
};


export const GetBeneficiaryOwners = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.get(`/business/get-beneficiary-details/?id=${payload}`);
  return data;
};

export const GetDashboardApplicationData = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const { page } = payload;
  const data = await axiosInstance.get(`/business/get-application-details/?page=${payload.page}`, payload.id);
  return data;
};



export const SaveBeneficiaryOwners = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.post("/business/save-beneficiary-details/", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

export const SaveFinalPayment = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.post("/business/save-application/", payload);
  return data;
};

// Assuming this function is in your helpers/api.js file
export const DataSendToChatBots = async (payload) => {
  try {
    const response = await fetch(`${API_URL}/api/chatbot/chatbot-response/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`,
        // Include any other necessary headers here
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error(`HTTP error status: ${response.status}`);
    }
    console.log("i am here", response.body)
    return response.body; // This is a ReadableStream
  }
  catch (error) {
    console.log("an errrroorzjzx", error)
  }
};



export const GetChatbotHistory = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.get(`/chatbot/chatbot-history/?page=${payload.page}`, payload.id);
  return data;
};

export const GetAllApplicationsForAgent = async (page,dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.get(`/agent/application-details/?page=${page}`);
  return data;
};

export const CreateAgent = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.post("/agent/create-agent/", payload);
  return data;
};

export const JurisdictionData = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.get(`/business/costing-jurisdiction/?app_id=${payload.id}&visa_for_manager=${payload.visa_for_manager}&visa_for_investor=${payload.visa_for_investor}&visa_for_manager_inside=${payload.visa_for_manager_inside}&workspace_type=${payload.workspace_type}`);
  return data;
};

// export const SaveJurisdictionData = async (payload, dispatch) => {
//   const axiosInstance = getAxiosInstance(dispatch);
//   const data = await axiosInstance.post("/business/save-costing-jurisdiction/", payload);
//   return data;
// };

// Modified SaveJurisdictionData function with CancelToken
export const SaveJurisdictionData = async (payload, dispatch, cancelToken) => {
  const axiosInstance = getAxiosInstance(dispatch);
  
  try {
    // Making the API call and passing the cancel token
    const response = await axiosInstance.post("/business/save-costing-jurisdiction/", payload, {
      cancelToken,  // Pass the cancel token to allow for cancellation
    });
    
    return response; // Return the whole response object as it is
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled');
    } else {
      console.error('API call failed:', error);
    }
    throw error;  // Rethrow error to handle it in the calling function
  }
};

export const UserChatHistory = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.get(`/agent/message-history/?app_id=${payload}`);
  return data;
};

export const UserChatSend = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.post("/agent/send-message/", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

export const SearchSubBusinessActivities = async (payload, dispatch, page) => {
  const axiosInstance = getAxiosInstance(dispatch);
  if (page){
    const data = await axiosInstance.get(`/business/search-business-sub-activities/?name=${payload}&page=${page}`);
    return data;
  }
  else{
    const data = await axiosInstance.get(`/business/search-business-sub-activities/?name=${payload}`);
    return data;
  }

};

// export const CreateStripePayment = async (payload, dispatch) => {
//   const axiosInstance = getAxiosInstance(dispatch);
//   const data = await axiosInstance.post("/payments/create-payment/", payload);
//   return data;
// };

// Modified CreateStripePayment function with CancelToken
export const CreateStripePayment = async (payload, dispatch, cancelToken) => {
  const axiosInstance = getAxiosInstance(dispatch);
  
  try {
    // Making the API call and passing the cancel token
    const response = await axiosInstance.post("/payments/create-payment/", payload, {
      cancelToken,  // Pass the cancel token to allow for cancellation
    });

    return response; // Return the entire response object
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled');
    } else {
      console.error('API call failed:', error);
    }
    throw error;  // Rethrow error to handle it in the calling function
  }
};


export const UpdateStripePaymentStatus = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.post("/payments/update-payment-status/", payload);
  return data;
};

// export const ConfirmDetailStep = async (payload, dispatch) => {
//   const axiosInstance = getAxiosInstance(dispatch);
//   const data = await axiosInstance.post("/business/details-confirmed/", payload);
//   return data;
// };

// Modified ConfirmDetailStep function with CancelToken
export const ConfirmDetailStep = async (payload, dispatch, cancelToken) => {
  const axiosInstance = getAxiosInstance(dispatch);
  
  try {
    // Making the API call and passing the cancel token
    const response = await axiosInstance.post("/business/details-confirmed/", payload, {
      cancelToken,  // Pass the cancel token to allow for cancellation
    });
    
    return response; // Return the whole response object as it is
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled');
    } else {
      console.error('API call failed:', error);
    }
    throw error;  // Rethrow error to handle it in the calling function
  }
};

export const ChangeApplicationStatus = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.post("/agent/send-reminder-email/", payload);
  return data;
};

// export const DeleteApplication = async (payload, dispatch) => {
//   const axiosInstance = getAxiosInstance(dispatch);
//   console.log("the payload to the delete", payload)
//   const data = await axiosInstance.post("/business/delete-application/", payload);
//   return data;
// };

// Modified DeleteApplication function with CancelToken
export const DeleteApplication = async (payload, dispatch, cancelToken) => {
  const axiosInstance = getAxiosInstance(dispatch);
  console.log("the payload to the delete", payload);

  try {
    // Pass the cancel token along with the API call
    const response = await axiosInstance.post("/business/delete-application/", payload, {
      cancelToken,  // Cancel token for handling cancellation
    });

    return response; // Return the full response
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    } else {
      console.error('API call failed:', error);
    }
    throw error; // Rethrow error for further handling
  }
};

export const GetAccountSettings = async (dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.get("/business/account-settings/");
  return data;
};

export const DeleteUserAccount = async (dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.post("/business/account-settings/");
  return data;
};


export const PatchUserAccountInformation = async (payload,dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.patch("/business/account-settings/", payload);
  return data;
};

export const UserFeedback = async (payload,dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.post("/business/feedback/", payload);
  return data;
};


export const FetchReferralLink = async (dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.get("/auth/referral-link/");
  return data;
};

export const ShareWithSheraaCheckbox = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch)
  const data = await axiosInstance.post("/business/share-with-sheraa/", payload);
  return data;
};

export const ShareWithSheraaButton = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch)
  const data = await axiosInstance.post("/agent/share-details-with-sheraa/", payload);
  return data;
};

// app form step blockin API:
// {
//   "is_blocked": true,
//   "app_id": "8845ebef-7017-4c99-877a-7c70dba150f4",
//   "current_step": "4"
// }
export const AppStepLock = async (payload, dispatch) => {
  const axiosInstance = getAxiosInstance(dispatch);
  const data = await axiosInstance.post("/business/steps-blocking/", payload);
  return data;
};