import React, { useEffect, useRef, useState } from "react";
import { Button, Box, TextField, Grid, MenuItem, Tooltip, DialogContent, Modal, Divider } from "@mui/material";
import { Formik, Form, Field, ErrorMessage, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import LoadingModal from "../../helpers/LoadingModal";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { SavePartnerDetails, GetApplicationData, apiCallWithAxiosCancel, AppStepLock } from "../../helpers/api";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import ClearIcon from '@mui/icons-material/Clear';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { cleanArray, valueTester } from "../../Utilities/UtilityFunctions";
import { forEach } from "jszip";
import { blockStep } from "../../features/stepsBlocker";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});


const getValidationSchema = (partners) => {
  return Yup.object().shape({
    partners: Yup.array().of(
      Yup.lazy((_, index) =>
        Yup.object().shape({
          phoneNumber: Yup.string()
            .required("Phone number is required")
            .min(9, "Phone number must be at least 9 digits")
            .max(16, "Phone number must not exceed 15 digits"),
          email: Yup.string()
            .trim()
            .email("Invalid email address")
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              "Invalid email address"
            )
            .required("Email is required"),
          address1: Yup.string().trim().required("Residential Address 1 is required"),
          locatedInUAE: Yup.string().trim().when('$partners', {
            is: (partners) => !partners[index]?.iss_gcc,
            then: (schema) => schema.required("This field is required"),
            otherwise: (schema) => schema.notRequired(),
          }),
          hasBeenToUAE: Yup.string()
          .trim()
          .when(['$partners', 'locatedInUAE'], {
            is: (partners, locatedInUAE) => {
              console.log(
                "Condition:",
                partners && !partners[index]?.iss_gcc && locatedInUAE === "false"
              );
              return partners && !partners[index]?.iss_gcc && locatedInUAE === "false";
            },
            then: (schema) => schema.required("This field is required"),
            otherwise: (schema) => schema.notRequired(),
          }),
        
          // hasBeenToUAE: Yup.string().trim().when('$partners', {
          //   is: (partners) => !partners[index]?.iss_gcc,
          //   then: (schema) => schema.required("This field is required"),
          //   otherwise: (schema) => schema.notRequired(),
          // }),
        })
      )
    ),
  });
};




const RequiredDocument = ({ onNext, onBack, onSaveAndExit, setMaxStepReached }) => {
  const applicationID = useSelector((state) => state.app.applicationID);
  const dispatch = useDispatch();
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = React.useState("");
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedPartnerIndex, setSelectedPartnerIndex] = useState(0);
  const [isInsideUAE, setIsInsideUAE] = useState("");
  const [step, setStep] = useState(0);
  const [em, setem] = useState(false);
  const [idCardError, setIdCardError] = useState("");
  const [initialValues, setInitialValues] = useState();
  
  const appLock = useSelector((state) => state.stepBlocker)
  
  const validationSchemaTemp = Yup.object().shape({
    partners: Yup.array().of(
      Yup.lazy((_, index) =>
        Yup.object().shape({
          phoneNumber: Yup.string()
            .required("Phone number is required")
            .min(9, "Phone number must be at least 9 digits")
            .max(16, "Phone number must not exceed 15 digits"),
          email: Yup.string()
            .trim()
            .email("Invalid email address")
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              "Invalid email address"
            )
            .required("Email is required"),
          address1: Yup.string().trim().required("Residential Address 1 is required"),
          locatedInUAE: Yup.string().trim().when('$partners', {
            is: (partners) => partners[index]?.iss_gcc === true ,
            then : (schema) => schema.notRequired(),
            otherwise: (schema) => schema.required("This field is required"),
          }),
          

          // hasBeenToUAE: Yup.string().trim().when('$partners', {
          //   is: (partners) => partners[index]?.iss_gcc === true,
          //   then : (schema) => schema.notRequired(),
          //   otherwise: (schema) => schema.required("This field is required"),
          // }),

          hasBeenToUAE: Yup.string()
          .trim()
          .when(['$partners', 'locatedInUAE'], {
            is: (partners, locatedInUAE) => {
              // console.log("Partners:", partners);
              // console.log("Located in UAE:", locatedInUAE);
              console.log(
                "Condition:",
                partners && !partners[index]?.iss_gcc && locatedInUAE === "false"
              );
              return partners && !partners[index]?.iss_gcc && locatedInUAE === "false";
            },
            then: (schema) => schema.required("This field is required"),
            otherwise: (schema) => schema.notRequired(),
          }),
        

        })
      )
    ),
  })
  const [validationSchemaState, setValidationSchema] = useState(validationSchemaTemp)
  
const validationSchema = Yup.object().shape({
  partners: Yup.array().of(
    Yup.object().shape({
      phoneNumber: Yup.string().required("Phone number is required").min(9, "Phone number must be at least 9 digits")
      .max(16, "Phone number must not exceed 15 digits"),
      email: Yup.string().trim()
      .email("Invalid email address")  // Yup's built-in email validation
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,  // Additional regex for stricter validation
        "Invalid email address"
      )
      .required("Email is required"),
      address1: Yup.string().trim().required("Residential Address 1 is required"),
      locatedInUAE: Yup.string().trim()
        .required("This field is required"),
      hasBeenToUAE: Yup.string().trim().when("locatedInUAE", {
        is: "false", // Assuming locatedInUAE is a string "true" or "false"
        then: schema => schema.required("This field is required"),
        otherwise: schema => schema.notRequired(),
      }),
    })
  ),
});

  // const [validationSchema, setValidationSchema] = useState(validationSchemaTemp);
  
  const [screenHeight, setScreenHeight] = useState(window.innerHeight); // Initial height

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight); // Update state when window is resized
    };

    // Add event listener for window resize
    window.addEventListener('resize', updateHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const handlePartnerChange = (index) => {
    setSelectedPartnerIndex(index);
  };

  const handleInsideUAEChange = (event) => {
    setIsInsideUAE(event.target.value);
  };

  useEffect(() => {
    async function fetchApplicationData() {
      try {
        setLoading(true);
        const ApplicationData = await GetApplicationData(applicationID, dispatch);
        setStep(ApplicationData?.data[0]?.step_no);

        if (ApplicationData.data.length > 0 && ApplicationData.data[0].partner_details.length > 0) {
          // setPartners(ApplicationData.data[0].partner_details);
          // setInitialValues(ApplicationData.data[0].partner_details);

            // Clone the array to avoid direct mutation
          const updatedPartnerDetails = ApplicationData.data[0].partner_details.map((partner) => {
            if (partner.nationality_name === "United Arab Emirates" && (partner.locatedInUAE === null || partner.locatedInUAE === undefined)) {
              return {
                ...partner,
                locatedInUAE: "null", // Set to stringified "null"
              };
            }
            return partner; // Return unchanged if condition not met
          });

          // Update state with the modified array
          setPartners(updatedPartnerDetails);
          setInitialValues(updatedPartnerDetails);

          const initialPhoneNumbers = ApplicationData.data[0].partner_details.map(
            partner => partner.phoneNumber
          );
          setPhoneNumbers(initialPhoneNumbers);
        }
      } catch (error) {
        toast.error("Error occurred while fetching Application Data");
      } finally {
        setLoading(false);
      }
    }
    fetchApplicationData();
  }, [applicationID, dispatch]); // Only depend on necessary variables

  const PartnerFormik = useFormik({
      initialValues:{
        partners: initialValues || [], 
      },
      enableReinitialize: true,
      validateOnChange:true,
      validateOnBlur:true,
      validationSchema: validationSchemaTemp,
      onSubmit: async (values, exit = false) => {
        // const errors = await PartnerFormik.validateForm();
        // if (Object.keys(errors).length === 0) {
        //   PartnerFormik.handleSubmit(PartnerFormik.values, false);
        // }
        handleSubmit(values, exit)
      }    
  }

  )

  useEffect(()=>{

    console.log("PartnerFormik?.errors = ", PartnerFormik?.errors)

  }, [PartnerFormik])

  const handleChangePhoneNumber = (newValue, partnerIndex, setFieldValue,allDetails) => {

    // Update the phone number in Formik's state
    setFieldValue(`partners[${partnerIndex}].phoneNumber`, newValue);
    if (!allDetails || !allDetails.countryCode) {
      setFieldValue(`partners[${partnerIndex}].phoneNumber`, ''); // Clear the field or show an error
      // You can also use Formik's setFieldError to set a custom error message
    }
    // Optionally update other states if needed, but Formik's state is the source of truth
  };

  const phoneInputRefs = useRef([]); // Array of refs for all phone fields

  const scrollToFirstError = () => {
    if (PartnerFormik.errors?.partners) {
      // Loop through the errors and find the first one
      PartnerFormik.errors.partners.some((partnerError, index) => {
        if (partnerError?.phoneNumber && phoneInputRefs.current[index]) {
          phoneInputRefs.current[index].scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          phoneInputRefs.current[index].focus(); // Focus on the field
          return true; // Stop after the first error
        }
        return false;
      });
    }
  };


  const [docFieldNames, setDocFieldNames] = useState([
      [
        [
  `       "National ID",
          "Passport",
          "Passport Photo"`
        ],
        [
          "National ID",
          "Passport",
          "Passport Photo"
        ],
        [
          "National ID",
          "Passport",
          "Passport Photo"
        ],
        [
          "National ID",
          "Passport",
          "Passport Photo"
        ],
        [
          "National ID",
          "Passport",
          "Passport Photo"
        ]
        
      ]
  ])

  function documentTitleSelector(partner, index){

    let resident = null
    let hasBeenToUAE = null

    if(partner?.locatedInUAE === "true" || partner?.locatedInUAE === "yes"){
      resident = true
    }
    else if(partner?.locatedInUAE === "false" || partner?.locatedInUAE === "no"){
      resident = false
    }

    if(partner?.hasBeenToUAE === "true" || partner?.hasBeenToUAE === "yes"){
      hasBeenToUAE = true
    }
    else if(partner?.hasBeenToUAE === "false" || partner?.hasBeenToUAE === "no"){
      hasBeenToUAE = false
    }
    
    // partner is a UAE Resident
    if (resident === true){
        let doc_field_name = [...docFieldNames]
        doc_field_name[index] = ["Emirates ID (Both Sides)", "Passport", "UAE Visa Copy"]
        return [...doc_field_name]
    }
    // partner is not a UAE resident and has never been to UAE
    else if (resident === false && !hasBeenToUAE){
        let doc_field_name = [...docFieldNames]
        doc_field_name[index] = ["National ID", "Passport Copy", "Passport Photo"]
        return [...doc_field_name]
    }
    // Partner is not a UAE resident but has been to UAE
    else if(resident === false && hasBeenToUAE){
        let doc_field_name = [...docFieldNames]
        doc_field_name[index] = ["Passport Copy", "Visa Page", "Passport Photo"]
        return [...doc_field_name]
    }
    // if all fields are null then it assumes that partner is not a UAE resident and has never been to UAE
    else{
        let doc_field_name = [...docFieldNames]
        doc_field_name[index] = ["National ID", "Passport Copy", "Passport Photo"]
        return [...doc_field_name]
    }
    
  }

  useEffect(()=>{
    console.log("docFieldNames", docFieldNames)
  }, [docFieldNames])

  useEffect(()=>{
    // console.log("partner details useeffect = ", partners?.length, partners)
    
    if (partners?.length){
      let docNamesTemp = []
      partners.forEach((partner, index) => {
        // console.log("doc name each", index, documentTitleSelector(partner, index) )
        docNamesTemp[index] = documentTitleSelector(partner, index)[index]
      })
      setDocFieldNames([...docNamesTemp])
    }

  }, [partners])

  const handleSubmit = async (values, exit) => {
    try {
      setNextLoading(true);

      // Set form values to avoid clearing fields on validation errors

    // console.log("values.partners  = ", values.partners)
      
    //   const missingDoc = values.partners.some((partner) => 
    //   // partner is from UAE
    //   (partner) => (!partner.photo_file && (partner?.locatedInUAE === "true" || partner?.locatedInUAE === "yes") )
    // );

    const missingDoc = docFieldNames.map((doc, index) => {
        if (!values.partners[index]?.photo_file && doc[2] === "Passport Photo" && values.partners[index]?.is_gcc === true) {
          return false
        }      
        else if (values.partners[index]?.photo_file){
          return false
        }
        else{
          return true
        }
    }
    )

      const missingLastVisaPage = values.partners.map((partner) => {
        if ( partner?.hasBeenToUAE === true || partner?.hasBeenToUAE === "true"){
          if (!partner?.last_visa_page){
            return true
          }
          else return false
        }
        else return false
      } )
      const hasMissingIDCard = values.partners.some(
        // (partner) => !partner.id_card_files || !partner.passport_files || (!partner.photo_file && partner.is_gcc === false) || (!partner.photo_file && partner.is_gcc === true && (partner?.locatedInUAE === "true" || partner?.locatedInUAE === "yes"))
        (partner) => !partner.id_card_files || !partner.passport_files || missingDoc.includes(true) || missingLastVisaPage.includes(true)
      );

    // console.log("hasMissingIDCard", hasMissingIDCard)
    // console.log("hasMissingLastVisaPage", missingLastVisaPage)
    // console.log("missingDoc", missingDoc, step)


      // let affected_partner_indexes = partners.findIndex((partner) => partner.documents?.length === 0) || -1
      let affected_partner_indexes = 
        partners
        .map((partner, index) => (partner.documents?.length === 0 ? index : -1))
        .filter(index => index !== -1);

      let affected_partners = []

      if (step > 4 && affected_partner_indexes.some((item) => item !== -1) && affected_partner_indexes !== null && affected_partner_indexes?.length){
        affected_partners = affected_partner_indexes.map((partner_index) => values.partners[partner_index] )
      }
    
  // console.log("affected_partner_indexes = ",affected_partner_indexes);
      let affected_partner_missingIDCard = [false]
      let affected_partner_missingLastVisaPage = [false]
      let affected_partner_missingDoc = [false]

      // step process passed required documents screen:
      if (step > 4 && affected_partner_indexes.some((item) => item !== -1) && affected_partner_indexes !== null && affected_partner_indexes?.length){

      console.log("affected_partner_indexes = ", affected_partner_indexes)
      console.log("affected_partner docFieldNames", [...affected_partner_indexes.map((item) => docFieldNames[item])])

        affected_partner_missingDoc = [ ...affected_partner_indexes.map((item) => docFieldNames[item]) ].map((doc, index) => {
          if (!affected_partners[index]?.photo_file && doc[2] === "Passport Photo" && affected_partners[index]?.is_gcc === true) {
            return false
          }      
          else if (affected_partners[index]?.photo_file){
            return false
          }
          else{
            return true
          }
      }
      )
        
        affected_partner_missingLastVisaPage = affected_partners.map((partner) => {
          if ( partner?.hasBeenToUAE === true || partner?.hasBeenToUAE === "true"){
            if (!partner?.last_visa_page){
              return true
            }
            else return false
          }
          else return false
        } )

        affected_partner_missingIDCard = affected_partners.some(
          (partner) => !partner.id_card_files || !partner.passport_files || affected_partner_missingDoc.includes(true) || affected_partner_missingLastVisaPage.includes(true)
        );
      }

      if (step <= 4 && (hasMissingIDCard)) {
        setIdCardError("All Documents are required for all partners");
        return;
      }
      else if (affected_partner_indexes.some((item) => item !== -1) && ([affected_partner_missingIDCard, affected_partner_missingLastVisaPage, affected_partner_missingDoc].includes(true))) {
      // console.log("affected = ",[affected_partner_missingIDCard, affected_partner_missingLastVisaPage, affected_partner_missingDoc])
        setIdCardError("All Documents are required for all partners");
        return;
      }


      const transformedPartners = values.partners.map((partner) => {
        // Create a new object to hold transformed id_card_files
        const newPartner = { ...partner };

        if (newPartner.id_card_files && newPartner.id_card_files.length > 0) {
          newPartner.id_card_file = newPartner.id_card_files[0] || null;
          if (newPartner.id_card_files.length > 1) {
            newPartner.id_card_file_second = newPartner.id_card_files[1] || null;
          }
          // Add more keys if you expect more files
        } else {
          newPartner.id_card_file = null;
          newPartner.id_card_file_second = null;
        }

        if (newPartner.passport_files && newPartner.passport_files.length > 0) {
          newPartner.passport_file = newPartner.passport_files[0] || null;
          // console.log("I am in the images section for passport file");
          if (newPartner.passport_files.length > 1) {
            newPartner.passport_file_second = newPartner.passport_files[1] || null;
          }
        } else {
          // console.log("I am in the images section for passport file bullshoit");
          newPartner.passport_file = null;
          newPartner.passport_file_second = null;
        }

        // if (newPartner.photo_files && newPartner.photo_files.length > 0) {
        //   newPartner.photo_file = newPartner.photo_files[0] || null;
        //   if (newPartner.photo_files.length > 1) {
        //     newPartner.photo_file_second = newPartner.photo_files[1] || null;
        //   }
        // } else {
        //   newPartner.photo_file = null;
        //   newPartner.photo_file_second = null;
        // }

        // Remove the original id_card_files array
        delete newPartner.id_card_files;
        delete newPartner.passport_files;
        // delete newPartner.photo_files;

        return newPartner;
      });




      const filteredPayload = cleanArray(transformedPartners)

      const payload = {
        partners: filteredPayload,
        id: applicationID,
      };

    // Use the cancellation logic for saving partner details
   await apiCallWithAxiosCancel((cancelToken) =>
      SavePartnerDetails(payload, dispatch, cancelToken)
    );


      if(appLock.is_blocked === true){
            await AppStepLock({ 
              app_id: valueTester(appLock.app_id) ? appLock.app_id : applicationID,
                is_blocked: false, 
                current_step: appLock.currentStep >= 5 ? appLock.currentStep : 5, 
            }, dispatch)
                .then(()=>{
                dispatch(blockStep({ is_blocked: false, currentStep: appLock.currentStep >= 5 ? appLock.currentStep : 5, app_id: valueTester(appLock.app_id) ? appLock.app_id : applicationID }));
                })        
                .catch((error)=>{

                })        
      }


      if (exit === true) {
        navigate("/");
      } else {
        onNext();
      }
    } catch (error) {
      if(error?.code !== "ERR_CANCELED" || error?.name !== "CanceledError"){
      console.error("Error saving Initial Partners Details:", error);
      toast.error("Error saving Initial Partners Details.");        
      }

    } finally {
      setNextLoading(false);
    }
  };


  useEffect(() => {
    // Check if the id_card_file is null or not
    if (partners[0]?.id_card_file === null) {
      setem(true);
    } else {
      setem(false);
    }
  }, [partners[0]?.id_card_file]);


  const VALID_IMAGE_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];
  const VALID_DOCUMENT_TYPES = ['application/pdf'];
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    // Add event listener on mount
    window.addEventListener("resize", handleResize);
  
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* <Formik
        initialValues={{
          partners: initialValues || [], // Fallback to an empty array
        }}
        validationSchema={PartnerFormik.validationSchema} // Dynamically updated schema
        onSubmit={PartnerFormik.handleSubmit}
        enableReinitialize={true} // Ensures Formik adapts to new initialValues and schema
        validateOnChange={true}
        validateOnBlur={true}
      > */}

      <FormikProvider value={PartnerFormik}>
          <Form className="h-full flex flex-col justify-between">
            <LoadingModal open={loading} />
            <div style={{maxHeight: `calc(100dvh 20px - 90px - 20px - 120px)`, height:"fit-content"}} className=" pr-3 overflow-y-auto scrollbar h-full px-7 sm:px-10 ">
              {PartnerFormik.values.partners.length > 0 &&
                PartnerFormik.values.partners.map((partner, index) => (
                  <>
                    <p className="font-bold pt-5 text-lg sm:text-2xl text-[#1E1D1B]">
                      Partner {index + 1} - Required Information
                    </p>
                    {
                      windowWidth < 640 ? <hr style={{borderWidth:"1px", marginTop:"10px"}}/> : ""
                    }
                    <Box key={partner.id} sx={{ mt: 2 }} >
                      <div className="flex flex-wrap gap-4 items-center w-[100%]">
                        {/* <TextField
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#1E1D1B",
                              border: "none"
                            },
                            background: "#EBEBE6",
                            borderRadius: "6px",
                            width: "35%",
                            border: "none"

                          }}
                          inputProps={{ border: "none" }}
                          value={partner.nameEN}
                          disabled
                          fullWidth
                          margin="normal"
                        /> */}
                        <div className="w-[95%] sm:w-[200px] bg-[#EBEBE6] h-[55px] px-4 flex justify-start items-center rounded-md text-[#1E1D1B]  font-normal">
                          {partner.nameEN}
                        </div>
                        {/* <TextField
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#1E1D1B",
                            },
                            background: "#EBEBE6",
                            borderRadius: "6px",
                            width: "30%"
                          }}
                          value={partner.nationality_name}
                          disabled
                          fullWidth
                          margin="normal"
                        /> */}
                        <div className="w-[95%] sm:w-[200px] bg-[#EBEBE6] h-[55px] px-4 flex justify-start items-center rounded-md text-[#1E1D1B]  font-normal">
                          {partner.nationality_name}
                        </div>
                        
                        {/* <div className="dob-equity-field-container">

                        </div> */}
                        <div className=" w-[50%] sm:w-[150px] bg-[#EBEBE6] h-[55px] px-4 flex justify-start items-center rounded-md text-[#1E1D1B]  font-normal">
                          {partner.dob}
                        </div>
                        {/* <TextField
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#1E1D1B",
                            },
                            background: "#EBEBE6",
                            borderRadius: "6px",
                            width: "15%"
                          }}
                          value={partner.equity}
                          disabled
                          fullWidth
                          margin="normal"
                        /> */}
                        <div style={{width: windowWidth < 640 ? "calc(45% - 15px)" : "100px"}} className="sm:w-[100px] bg-[#EBEBE6] h-[55px] px-4 flex justify-start items-center rounded-md text-[#1E1D1B]  font-normal">
                          {partner.equity} %
                        </div>
                       
                      </div>
                      <div className="flex gap-2 md:flex-row flex-col  mt-3 w-11/12">
                        <div className=" w-full pb-5">
                          <p className="text-base font-semibold">Phone Number</p>
                            <MuiTelInput
                              key={index}
                              inputProps={{
                                autoComplete: "new-password", // Trick the browser by using an unrelated value
                              }}
                              name={`partners[${index}].phoneNumber`}
                              fullWidth
                              required
                              forceCallingCode
                              disableFormatting
                              onBlur={() => PartnerFormik.setFieldTouched(`partners[${index}].phoneNumber`, true)}
                              value={partner.phoneNumber || "+971"}
                              onChange={(newValue, allDetails) =>
                                handleChangePhoneNumber(newValue, index, PartnerFormik.setFieldValue, allDetails)
                              }
                            />


                          <ErrorMessage
                            className="text-red-500 mt-2 text-xs"
                            name={`partners[${index}].phoneNumber`}
                            component="div"
                          />
                        </div>
                        <div className=" w-full">
                          <p className="text-base font-semibold">Email</p>
                          <Field
                            required
                            name={`partners[${index}].email`}
                            as={TextField}
                            fullWidth
                            placeholder="Type here"
                            marginBottom="normal"
                            error={
                              PartnerFormik.touched.partners?.[index]?.email &&
                              !!PartnerFormik.errors.partners?.[index]?.email
                            }
                          />
                          <div className="">
                            <ErrorMessage
                              className="text-red-500 mt-2 text-xs"
                              name={`partners[${index}].email`}
                              component="div"
                            />
                          </div>
                        </div>
                      </div>
                      <p className="text-base font-semibold mt-8 md:mt-0">
                        Residential address
                      </p>
                      <div className="flex gap-2 md:flex-row flex-col w-11/12">
                        <div className="w-full">
                          <Field
                            name={`partners[${index}].address1`}
                            as={TextField}
                            placeholder="Residental Address 1"
                            fullWidth
                            marginBottom="normal"
                            required
                            error={
                              PartnerFormik.touched.partners?.[index]?.address1 &&
                              !!PartnerFormik.errors.partners?.[index]?.address1
                            }
                          />

                          <ErrorMessage
                            className="text-red-500   mt-2 text-xs"
                            name={`partners[${index}].address1`}
                            component="div"
                          />
                        </div>
                        <div className="w-full">
                          <Field
                            name={`partners[${index}].address2`}
                            as={TextField}
                            placeholder="Residental Address 2"
                            fullWidth
                            marginBottom="normal"
                          />
                          <ErrorMessage
                            className="text-red-500 mt text-xs"
                            name={`partners[${index}].address2`}
                            component="div"
                          />
                        </div>
                        <div className="w-full">
                          <Field
                            name={`partners[${index}].address3`}
                            as={TextField}
                            placeholder="Residental Address 3"
                            fullWidth
                            marginBottom="normal"
                          />
                          <ErrorMessage
                            className="text-red-500 mt-2 text-xs"
                            name={`partners[${index}].address3`}
                            component="div"
                          />
                        </div>
                      </div>

                      <Grid item xs={12} mt={2} mb={2}>
                        <div 
                        hidden={partners[index]?.is_gcc === true}
                        >
                          <div className="flex gap-3 sm:gap-5 field-justify items-baseline sm:items-center w-[92%]">
                            <p className="sm:text-base text-sm font-normal pr-1">
                              Is {" "}
                              <span className="font-semibold ">
                                {partner.nameEN}
                              </span>{" "} a resident of the UAE?
                            </p>
                            <span className="flex flex-col sm:flex-row sm:items-center gap-3">
                              <Field
                                required={partners[index]?.is_gcc === true ? false : true}
                                name={`partners[${index}].locatedInUAE`}
                                as={TextField}
                                select
                                margin="normal"
                                placeholder="Yes/No"
                                sx={{
                                  marginTop:"0px",
                                  minWidth: "100px", // Adjust the value as needed
                                }}
                                value={PartnerFormik.values.partners[index].locatedInUAE || ""} // Handle null value
                                onChange={(e) => {
                                  PartnerFormik.setFieldValue(`partners[${index}].locatedInUAE`, e.target.value);
                                  PartnerFormik.setFieldValue(`partners[${index}].hasBeenToUAE`, "false");
                                  setPartners(()=>{
                                    let partners_temp = partners

                                    if (e.target.value === "true" || e.target.value === "true"){
                                      partners_temp[index] = {
                                        ...partners_temp[index],
                                        locatedInUAE: e.target.value,
                                        hasBeenToUAE: "false"
                                      }
                                    }
                                    else{
                                      partners_temp[index] = {
                                        ...partners_temp[index],
                                        locatedInUAE: e.target.value
                                      }                                      
                                    }

                                    // console.log("partner updated = ", partners_temp)
                                    return [...partners_temp]
                                  })
                                }}
                                error={
                                  PartnerFormik.touched.partners?.[index]?.locatedInUAE &&
                                  !!PartnerFormik.errors.partners?.[index]?.locatedInUAE
                                }
                              >
                                <MenuItem value="true">Yes</MenuItem>
                                <MenuItem value="false">No</MenuItem>
                              </Field>
                              <ErrorMessage
                                className="text-red-500 mt-2 text-xs"
                                name={`partners[${index}].locatedInUAE`}
                                component="div"
                              />                              
                            </span>

                          </div>

                          {PartnerFormik.values.partners[index].locatedInUAE === "false" && (
                            <div className="flex gap-3 field-justify items-baseline sm:items-center w-[92%] mt-2 ">
                              <p className="text-sm sm:text-base font-normal pr-2">
                                Has {" "}
                                <span className="font-semibold text-sm sm:text-base">
                                  {partner.nameEN}
                                </span>{" "}
                                been to UAE before?
                              </p>
                              <span className="flex flex-col sm:flex-row sm:items-center gap-3">
                                <Field
                                  required={PartnerFormik.values.partners[index].locatedInUAE === "false" ? true : false}
                                  name={`partners[${index}].hasBeenToUAE`}
                                  as={TextField}
                                  select
                                  margin="normal"
                                  placeholder="Yes/No"
                                  sx={{ minWidth: "100px" }}
                                  error={
                                    PartnerFormik.touched.partners?.[index]?.hasBeenToUAE &&
                                    !!PartnerFormik.errors.partners?.[index]?.hasBeenToUAE
                                  }
                                  value={PartnerFormik.values.partners[index].hasBeenToUAE || ""} // Handle null value
                                  onChange={(e) => {
                                    // console.log("hasBeenToUAE field", e.target.value )
                                    PartnerFormik.setFieldValue(`partners[${index}].hasBeenToUAE`, e.target.value);
                                    let partners_temp = partners
                                    partners_temp[index] = {
                                      ...partners_temp[index],
                                      hasBeenToUAE: e.target.value
                                    }
                                    setPartners([...partners_temp])
                                  }}
                                >
                                  <MenuItem value="true">Yes</MenuItem>
                                  <MenuItem value="false">No</MenuItem>
                                </Field>
                                <ErrorMessage
                                  className="text-red-500 mt-2 text-xs"
                                  name={`partners[${index}].hasBeenToUAE`}
                                  component="div"
                                />                                
                              </span>

                            </div>
                          )}
                        </div>

                      </Grid>

                      <div>

                        <Modal open={open} onClose={!open}>
                          <Box
                            sx={{
                              position: "absolute",
                              top: "90px",
                              left: "50%",
                              transform: {xs: "translate(-50%, -75px)", smc: "translate(-50%, -5px)"},
                              height: {xs: "95%", smc: "84%"},
                              width:{xs:"95vw", lgc: "90vw"},
                            }}
                            className="min-w-[95%] flex flex-col text-[#1E1D1B] scrollbar justify-between  overflow-y-auto rounded-[20px] bg-white shadow-md "
                          >
                            <svg onClick={() => { setSelectedPartnerIndex(selectedPartnerIndex > 0 ? selectedPartnerIndex - 1 : selectedPartnerIndex) }} className={` cursor-pointer absolute top-[35%] left-3 md:left-[58px] z-10`} width={windowWidth < 640 ? "40" : "50"} height={windowWidth < 640 ? "40" : "50"}viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="54" y="54" width="54" height="54" rx="27" transform="rotate(-180 54 54)" fill="#EBEBE6" />
                              <path d="M25 21L19 27L25 33" stroke="#3A3937" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M35 26.25C35.4142 26.25 35.75 26.5858 35.75 27C35.75 27.4142 35.4142 27.75 35 27.75L35 26.25ZM20 26.25L35 26.25L35 27.75L20 27.75L20 26.25Z" fill="#3A3937" />
                            </svg>


                            <svg onClick={() => { setSelectedPartnerIndex(partners.length - 1 > selectedPartnerIndex ? selectedPartnerIndex + 1 : selectedPartnerIndex) }} className={`cursor-pointer absolute top-[35%] right-3 md:right-[58px] z-10`} width={windowWidth < 640 ? "40" : "50"} height={windowWidth < 640 ? "40" : "50"} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect width="54" height="54" rx="27" fill="#EBEBE6" />
                              <path d="M29 22L35 28L29 34" stroke="#3A3937" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M19 27.25C18.5858 27.25 18.25 27.5858 18.25 28C18.25 28.4142 18.5858 28.75 19 28.75L19 27.25ZM34 27.25L19 27.25L19 28.75L34 28.75L34 27.25Z" fill="#3A3937" />
                            </svg>

                            <Box 
                            sx={{
                              // border:"2px solid red",
                              maxHeight: {xs:`calc(100dvh - (100dvh * 0.16))`, smc: `calc(100dvh - (100dvh * 0.16) - 70px)`}
                            }}
                             className="md:px-16 flex flex-col justify-end md:pt-8 p-3">
                              {/* <ClearIcon
                                onClick={() => setOpen(false)}
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  top: "15",
                                  right: "15",
                                }}
                                className="w-6 h-6 text-[#000000]"
                              /> */}

                              <DialogContent sx={{ padding: "0px", mt: 0, border:"none"}}>
                                <div className="relative ">
                                  {PartnerFormik.values.partners.length > 0 && (
                                    <>
                                      <div className="px-5 sm:px-10 mt-4">
                                        <p className="font-semibold text-2xl">Document Upload - {PartnerFormik.values.partners[selectedPartnerIndex]?.nameEN}</p>

                                        {/* <div className="max-w-[290px] bg-[#EBEBE6] rounded-md px-3 mt-3 py-3">{values.partners[selectedPartnerIndex]?.nameEN}</div> */}
                                        <ClearIcon
                                onClick={() => setOpen(false)}
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  top: "5",
                                  right: "8",
                                }}
                                className="w-6 h-6 text-[#000000]"
                              />
                                      </div>
                                      <div className="flex flex-col mt-[5%] px-5 sm:px-10 justify-end ">
                                        <div className="flex flex-col lg:flex-row gap-5 justify-start items-start mt-5">
                                          <div className="w-full lg:w-1/4">
                                            <p className="font-semibold wrapped-text-custom max-w-[100%] flex flex-col min-h-[42px]"> 
                                              
                                              {/* National ID  */}
                                              {docFieldNames[selectedPartnerIndex][0]}
                                              
                                              <span className="text-[12px] font-normal italic ml-1 pr-1">(<span className="text-red-500 italic">*</span>if you have one)</span></p>
                                            <label
                                              onDragOver={(e) => e.preventDefault()}
                                              onDrop={(event) => {
                                                event.preventDefault();
                                                const files = Array.from(event.dataTransfer.files);

                                                const validFiles = files.filter(file => {
                                                  const isValidFileType = VALID_IMAGE_TYPES.includes(file.type) || VALID_DOCUMENT_TYPES.includes(file.type);
                                                  const isValidFileSize = file.size <= MAX_FILE_SIZE;
                                                  return isValidFileType && isValidFileSize;
                                                });

                                                if (validFiles.length > 0) {
                                                  const currentFiles = PartnerFormik.values.partners[selectedPartnerIndex]?.id_card_files || [];
                                                  const updatedFiles = [...currentFiles, ...validFiles].slice(-2); // Keep only the last two files
                                                  PartnerFormik.setFieldValue(`partners[${selectedPartnerIndex}].id_card_files`, updatedFiles);
                                                  //setFieldValue(`partners[${selectedPartnerIndex}].id_card_files`, [...(values.partners[selectedPartnerIndex]?.id_card_files || []), ...validFiles]);
                                                } else {
                                                  toast.error("Some files are invalid. Only PNG, JPG, JPEG, or PDF are allowed and must be less than 5 MB.");
                                                }
                                              }}

                                              htmlFor={`partners.${selectedPartnerIndex}.id_card_file`}
                                              className="h-[70%] border-dashed cursor-pointer justify-center border-2 flex gap-1 bg-[#e6fce2] border-[#81EF6F] px-3 rounded-lg py-8"
                                            >
                                              <VisuallyHiddenInput
                                                type="file"
                                                multiple
                                                id={`partners.${selectedPartnerIndex}.id_card_file`}
                                                name={`partners.${selectedPartnerIndex}.id_card_file`}
                                                accept=".png, .jpg, .jpeg, .pdf"
                                                onChange={(event) => {
                                                  const files = Array.from(event.currentTarget.files); // Convert FileList to an array
                                                  
                                                  if (files.length === 0) {
                                                    // User canceled file selection; retain previously selected files
                                                    return;
                                                  }
                                                
                                                  // Filter valid files by type and size
                                                  const validFiles = files.filter(file => {
                                                    const isValidFileType = VALID_IMAGE_TYPES.includes(file.type) || VALID_DOCUMENT_TYPES.includes(file.type);
                                                    const isValidFileSize = file.size <= MAX_FILE_SIZE; // Assuming MAX_FILE_SIZE is defined (e.g., 5 * 1024 * 1024 for 5MB)
                                                    return isValidFileType && isValidFileSize;
                                                  });
                                                
                                                  if (files.length > 2) {
                                                    toast.error("You can only select up to 2 files.", { toastId: "NationalID-file-limit" });
                                                    event.target.value = ''; // Reset input field if more than 2 files selected
                                                    return;
                                                  }
                                                
                                                  if (validFiles.length > 0) {
                                                    // Get the current files and update the field with the new valid files, keeping only the last two
                                                    const currentFiles = PartnerFormik.values.partners[selectedPartnerIndex]?.id_card_files || [];
                                                    const updatedFiles = [...currentFiles, ...validFiles].slice(-2); // Keep only the last 2 files
                                                
                                                    PartnerFormik.setFieldValue(`partners[${selectedPartnerIndex}].id_card_files`, updatedFiles);
                                                  } else {
                                                    toast.error("Some files are invalid. Only PNG, JPG, JPEG, or PDF are allowed and must be less than 5 MB.");
                                                  }
                                                
                                                  event.target.value = ''; // Ensure the file input is reset to handle re-uploads
                                                }}
                                                
                                              />

                                              <div className="cursor-pointer flex justify-center flex-col items-center gap-3">
                                                <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M20 25H2C1.73478 25 1.48043 24.8946 1.29289 24.7071C1.10536 24.5196 1 24.2652 1 24V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H14L21 8V24C21 24.2652 20.8946 24.5196 20.7071 24.7071C20.5196 24.8946 20.2652 25 20 25Z" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M14 1V8H21" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M8 16H14" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M11 13V19" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <Tooltip
                                                  title={
                                                    PartnerFormik.values.partners?.[selectedPartnerIndex]?.id_card_file
                                                      ? PartnerFormik.values.partners[selectedPartnerIndex].id_card_file?.name
                                                      : partners[selectedPartnerIndex]?.documents[0]?.file_name
                                                        ? partners[selectedPartnerIndex].documents[0].file_name
                                                        : "Click to Upload ID"
                                                  }
                                                  placement="top"
                                                >
                                                  <p className="font-normal text-center text-xs text-[#1E1D1B]">
                                                    Drag and drop files here
                                                    <br /> or
                                                    Click to browse documents
                                                  </p>
                                                </Tooltip>
                                              </div>
                                            </label>
                                            {PartnerFormik.values.partners?.[selectedPartnerIndex]?.id_card_files?.length > 0 ? (
                                              <div className="flex flex-col justify-center items-center gap-4 mt-2">
                                                {PartnerFormik.values.partners[selectedPartnerIndex]?.id_card_files.map((file, index) => (
                                                  <div key={index} className="bg-[#EBEBE6] px-2 py-2 rounded-md font-xs w-full flex justify-between items-center">
                                                    <p className="text-sm font-thin line-clamp-1">
                                                      {file.name}
                                                    </p>
                                                    <ClearIcon
                                                      className="cursor-pointer text-[#1E1D1B]"
                                                      sx={{ fontSize: "20px" }}
                                                      onClick={() => {
                                                        const updatedFiles = PartnerFormik.values.partners[selectedPartnerIndex].id_card_files.filter((_, i) => i !== index);
                                                        PartnerFormik.setFieldValue(`partners[${selectedPartnerIndex}].id_card_files`, updatedFiles);
                                                      }}
                                                    />
                                                  </div>
                                                ))}
                                              </div>
                                            ) : (
                                              partners[selectedPartnerIndex]?.documents
                                                ?.filter(category => category.category_id === 1)
                                                ?.map((category) => (
                                                  category?.documents?.length > 0 && (
                                                    category.documents.map((doc, docIndex) => (
                                                      <div className="bg-[#EBEBE6] mt-2 px-2 py-2 rounded-md font-xs w-full flex justify-between items-center" key={`${category.category_id}-${docIndex}`}>
                                                        <a href={doc.url} target="_blank" rel="noopener noreferrer" className="text-sm font-thin line-clamp-1">
                                                          previous image: {doc.file_name}
                                                        </a>
                                                      </div>
                                                    ))
                                                  )
                                                ))
                                            )}


                                          </div>

                                          <div className="w-full lg:w-1/4">
                                            <p className="font-semibold min-h-[42px]">
                                              {/* Passport  */}

                                              {docFieldNames[selectedPartnerIndex][1]}

                                            <span className="text-red-500">*</span></p>
                                            <label
                                              onDragOver={(event) => event.preventDefault()}
                                              onDrop={(event) => {
                                                event.preventDefault();
                                                const files = Array.from(event.dataTransfer.files);
                                                const validFiles = files.filter(file => {
                                                  const isValidFileType = VALID_IMAGE_TYPES.includes(file.type) || VALID_DOCUMENT_TYPES.includes(file.type);
                                                  const isValidFileSize = file.size <= MAX_FILE_SIZE;
                                                  return isValidFileType && isValidFileSize;
                                                });

                                                if (validFiles.length > 0) {
                                                  const currentFiles = PartnerFormik.values.partners[selectedPartnerIndex]?.passport_files || [];
                                                  const updatedFiles = [...currentFiles, ...validFiles].slice(-2); // Keep only the last two files
                                                  PartnerFormik.setFieldValue(`partners[${selectedPartnerIndex}].passport_files`, updatedFiles);
                                                  // setFieldValue(`partners[${selectedPartnerIndex}].passport_files`, [
                                                  //   ...(values.partners[selectedPartnerIndex]?.passport_files || []),
                                                  //   ...validFiles
                                                  // ]);
                                                } else {
                                                  toast.error("Some files are invalid. Only PNG, JPG, JPEG, or PDF are allowed and must be less than 5 MB.");
                                                }
                                              }}
                                              htmlFor={`partners.${selectedPartnerIndex}.passport_file`}
                                              className="h-[70%] border-dashed cursor-pointer justify-center border-2 flex gap-1 bg-[#e6fce2] border-[#81EF6F] px-3 rounded-lg py-8"
                                            >
                                              <VisuallyHiddenInput
                                                type="file"
                                                id={`partners.${selectedPartnerIndex}.passport_file`}
                                                name={`partners.${selectedPartnerIndex}.passport_file`}
                                                multiple
                                                accept=".png, .jpg, .jpeg, .pdf"
                                                onChange={(event) => {
                                                  const files = Array.from(event.currentTarget.files); // Convert FileList to an array
                                                  if (files.length === 0) {
                                                    return;
                                                  }

                                                  // Filter valid files by type and size
                                                  const validFiles = files.filter(file => {
                                                    const isValidFileType = VALID_IMAGE_TYPES.includes(file.type) || VALID_DOCUMENT_TYPES.includes(file.type);
                                                    const isValidFileSize = file.size <= MAX_FILE_SIZE; // Assuming MAX_FILE_SIZE is defined (e.g., 5 * 1024 * 1024 for 5MB)
                                                    return isValidFileType && isValidFileSize;
                                                  });

                                                  if (files.length > 2) {
                                                    toast.error("You can only select up to 2 files.");
                                                    event.target.value = ''; // Reset input field if more than 2 files selected
                                                    return;
                                                  }

                                                  if (validFiles.length > 0) {
                                                    // Get the current passport files and update the field with the new valid files, keeping only the last two
                                                    const currentFiles = PartnerFormik.values.partners[selectedPartnerIndex]?.passport_files || [];
                                                    const updatedFiles = [...currentFiles, ...validFiles].slice(-2); // Keep only the last 2 files

                                                    PartnerFormik.setFieldValue(`partners[${selectedPartnerIndex}].passport_files`, updatedFiles);
                                                  } else {
                                                    toast.error("Some files are invalid. Only PNG, JPG, JPEG, or PDF are allowed and must be less than 5 MB.");
                                                  }
                                                }}
                                              />

                                              <div className="cursor-pointer flex justify-center flex-col items-center gap-3">
                                                <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M20 25H2C1.73478 25 1.48043 24.8946 1.29289 24.7071C1.10536 24.5196 1 24.2652 1 24V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H14L21 8V24C21 24.2652 20.8946 24.5196 20.7071 24.7071C20.5196 24.8946 20.2652 25 20 25Z" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M14 1V8H21" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M8 16H14" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M11 13V19" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <Tooltip
                                                  title={
                                                    PartnerFormik.values.partners?.[selectedPartnerIndex]?.passport_file
                                                      ? PartnerFormik.values.partners[selectedPartnerIndex].passport_file?.name
                                                      : partners[selectedPartnerIndex]?.documents[1]?.file_name
                                                        ? partners[selectedPartnerIndex].documents[1]?.file_name
                                                        : "Click to Upload Passport"
                                                  }
                                                  placement="top"
                                                >
                                                  <p className="font-normal text-center text-xs text-[#1E1D1B]">
                                                    Drag and drop files here
                                                    <br /> or
                                                    Click to browse documents
                                                  </p>
                                                </Tooltip>
                                              </div>
                                            </label>

                                            {PartnerFormik.values.partners?.[selectedPartnerIndex]?.passport_files?.length > 0 ? (
                                              <div className="flex flex-col justify-center items-center gap-2 mt-2">
                                                {PartnerFormik.values.partners[selectedPartnerIndex]?.passport_files.map((file, index) => (
                                                  <div key={index} className="bg-[#EBEBE6] px-2 py-2 rounded-md font-xs w-full flex justify-between items-center">
                                                    <p className="text-sm font-thin line-clamp-1">
                                                      {file.name}
                                                    </p>
                                                    <ClearIcon
                                                      className="cursor-pointer text-[#1E1D1B]"
                                                      sx={{ fontSize: "20px" }}
                                                      onClick={() => {
                                                        const updatedFiles = PartnerFormik.values.partners[selectedPartnerIndex].passport_files.filter((_, i) => i !== index);
                                                        PartnerFormik.setFieldValue(`partners[${selectedPartnerIndex}].passport_files`, updatedFiles);
                                                      }}
                                                    />
                                                  </div>
                                                ))}
                                              </div>
                                            ) : (
                                              partners[selectedPartnerIndex]?.documents
                                                ?.filter(category => category.category_id === 2)
                                                ?.map((category) => (
                                                  category?.documents?.length > 0 && (
                                                    category.documents.map((doc, docIndex) => (
                                                      <div className="bg-[#EBEBE6] mt-2 px-2 py-2 rounded-md font-xs w-full flex justify-between items-center" key={`${category.category_id}-${docIndex}`}>
                                                        <a href={doc.url} target="_blank" rel="noopener noreferrer" className="text-sm font-thin line-clamp-1">
                                                          previous image: {doc.file_name}
                                                        </a>
                                                      </div>
                                                    ))
                                                  )
                                                ))
                                            )}
                                          </div>

                                          <div className="w-full lg:w-1/4" hidden={docFieldNames[selectedPartnerIndex][2] === "Passport Photo" && partners[selectedPartnerIndex]?.is_gcc === true}>
                                            <p className="font-semibold min-h-[42px]"> 
                                              {/* Passport Photo */}
                                              {docFieldNames[selectedPartnerIndex][2]}
                                            <span className="text-red-500">*</span></p>
                                            <label
                                              onDragOver={(event) => event.preventDefault()}
                                              onDrop={(event) => {
                                                event.preventDefault();
                                                const file = event.dataTransfer.files[0];
                                                PartnerFormik.setFieldValue(`partners[${selectedPartnerIndex}].photo_file`, file);
                                              }}
                                              htmlFor={`partners.${selectedPartnerIndex}.photo_file`}
                                              className="h-[70%] py-8 border-dashed cursor-pointer justify-center border-2 flex gap-1 bg-[#e6fce2] border-[#81EF6F] px-3 rounded-lg "
                                            >
                                              <VisuallyHiddenInput
                                                type="file"
                                                accept=".png, .jpg, .jpeg, .pdf"
                                                id={`partners.${selectedPartnerIndex}.photo_file`}
                                                name={`partners.${selectedPartnerIndex}.photo_file`}
                                                onChange={(event) => {
                                                  const file = event.currentTarget.files[0];

                                                  if (!file) {
                                                    // User canceled file selection; retain previously selected files
                                                    return;
                                                  }

                                                  PartnerFormik.setFieldValue(`partners[${selectedPartnerIndex}].photo_file`, file);
                                                }}
                                              />
                                              <div className="cursor-pointer flex justify-center flex-col items-center gap-3">
                                                <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M20 25H2C1.73478 25 1.48043 24.8946 1.29289 24.7071C1.10536 24.5196 1 24.2652 1 24V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H14L21 8V24C21 24.2652 20.8946 24.5196 20.7071 24.7071C20.5196 24.8946 20.2652 25 20 25Z" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M14 1V8H21" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M8 16H14" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M11 13V19" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <Tooltip
                                                  title={
                                                    PartnerFormik.values.partners?.[selectedPartnerIndex]?.photo_file
                                                      ? PartnerFormik.values.partners[selectedPartnerIndex].photo_file?.name
                                                      : partners[selectedPartnerIndex]?.documents[2]?.file_name
                                                        ? partners[selectedPartnerIndex].documents[2]?.file_name
                                                        : "Click to Upload Photo"
                                                  }
                                                  placement="top"
                                                >
                                                  <p className="font-normal text-center text-xs text-[#1E1D1B]">
                                                    Drag and drop files here
                                                    <br /> or
                                                    Click to browse documents
                                                  </p>
                                                </Tooltip>
                                              </div>
                                            </label>
                                            {PartnerFormik.values.partners?.[selectedPartnerIndex]?.photo_file ? (
                                              <div className="flex flex-col justify-center items-center gap-4 mt-2">
                                                <div className="bg-[#EBEBE6] px-2 py-2 rounded-md font-xs w-full flex justify-between items-center">
                                                  <p className="text-sm font-thin line-clamp-1">
                                                    {PartnerFormik.values.partners[selectedPartnerIndex]?.photo_file?.name}
                                                  </p>
                                                  <ClearIcon
                                                    className="cursor-pointer text-[#1E1D1B]"
                                                    sx={{ fontSize: "20px" }}
                                                    onClick={() => {
                                                      PartnerFormik.setFieldValue(`partners[${selectedPartnerIndex}].photo_file`, null);
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            ) : (
                                              partners[selectedPartnerIndex]?.documents
                                              ?.filter(category => category.category_id === 3)
                                              ?.map((category) => (
                                                category?.documents?.length > 0 && (
                                                  category.documents.map((doc, docIndex) => (
                                                    <div className="bg-[#EBEBE6] mt-2 px-2 py-2 rounded-md font-xs w-full flex justify-between items-center" key={`${category.category_id}-${docIndex}`}>
                                                      <a href={doc.url} target="_blank" rel="noopener noreferrer" className="text-sm font-thin line-clamp-1">
                                                        previous image: {doc.file_name}
                                                      </a>
                                                    </div>
                                                  ))
                                                )
                                              ))
                                            )}
                                          </div>

                                          <div className="w-full lg:w-1/4" hidden={partners[selectedPartnerIndex]?.hasBeenToUAE === true || partners[selectedPartnerIndex]?.hasBeenToUAE === "true" ? false : true}>
                                            <p className="font-semibold"> 
                                              {/* Last Visa Page*/}
                                              {partners[selectedPartnerIndex]?.hasBeenToUAE === "true" || partners[selectedPartnerIndex]?.hasBeenToUAE === true ? <span>Last Visa Page <span className="text-red-500">*</span> <p style={{fontSize:"12px", fontWeight:"400"}}>(or passport UAE entry stamp)</p> </span> : ""}
                                            </p>
                                            <label
                                              onDragOver={(event) => event.preventDefault()}
                                              onDrop={(event) => {
                                                event.preventDefault();
                                                const file = event.dataTransfer.files[0];
                                                PartnerFormik.setFieldValue(`partners[${selectedPartnerIndex}].last_visa_page`, file);
                                              }}
                                              htmlFor={`partners.${selectedPartnerIndex}.last_visa_page`}
                                              className="h-[70%] py-8 border-dashed cursor-pointer justify-center border-2 flex gap-1 bg-[#e6fce2] border-[#81EF6F] px-3 rounded-lg "
                                            >
                                              <VisuallyHiddenInput
                                                type="file"
                                                accept=".png, .jpg, .jpeg, .pdf"
                                                id={`partners.${selectedPartnerIndex}.last_visa_page`}
                                                name={`partners.${selectedPartnerIndex}.last_visa_page`}
                                                onChange={(event) => {
                                                  const file = event.currentTarget.files[0];

                                                  if (!file) {
                                                    // User canceled file selection; retain previously selected files
                                                    return;
                                                  }

                                                  PartnerFormik.setFieldValue(`partners[${selectedPartnerIndex}].last_visa_page`, file);
                                                }}
                                              />
                                              <div className="cursor-pointer flex justify-center flex-col items-center gap-3">
                                                <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M20 25H2C1.73478 25 1.48043 24.8946 1.29289 24.7071C1.10536 24.5196 1 24.2652 1 24V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H14L21 8V24C21 24.2652 20.8946 24.5196 20.7071 24.7071C20.5196 24.8946 20.2652 25 20 25Z" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M14 1V8H21" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M8 16H14" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M11 13V19" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <Tooltip
                                                  title={
                                                    PartnerFormik.values.partners?.[selectedPartnerIndex]?.last_visa_page
                                                      ? PartnerFormik.values.partners[selectedPartnerIndex].last_visa_page?.name
                                                      : partners[selectedPartnerIndex]?.documents[2]?.file_name
                                                        ? partners[selectedPartnerIndex].documents[2]?.file_name
                                                        : "Click to Upload Photo"
                                                  }
                                                  placement="top"
                                                >
                                                  <p className="font-normal text-center text-xs text-[#1E1D1B]">
                                                    Drag and drop files here
                                                    <br /> or
                                                    Click to browse documents
                                                  </p>
                                                </Tooltip>
                                              </div>
                                            </label>
                                            {PartnerFormik.values.partners?.[selectedPartnerIndex]?.last_visa_page ? (
                                              <div className="flex flex-col justify-center items-center gap-4 mt-2">
                                                <div className="bg-[#EBEBE6] px-2 py-2 rounded-md font-xs w-full flex justify-between items-center">
                                                  <p className="text-sm font-thin line-clamp-1">
                                                    {PartnerFormik.values.partners[selectedPartnerIndex]?.last_visa_page?.name}
                                                  </p>
                                                  <ClearIcon
                                                    className="cursor-pointer text-[#1E1D1B]"
                                                    sx={{ fontSize: "20px" }}
                                                    onClick={() => {
                                                      PartnerFormik.setFieldValue(`partners[${selectedPartnerIndex}].last_visa_page`, null);
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            ) : (
                                              partners[selectedPartnerIndex]?.documents
                                              ?.filter(category => category.category_id === 4)
                                              ?.map((category) => (
                                                category?.documents?.length > 0 && (
                                                  category.documents.map((doc, docIndex) => (
                                                    <div className="bg-[#EBEBE6] mt-2 px-2 py-2 rounded-md font-xs w-full flex justify-between items-center" key={`${category.category_id}-${docIndex}`}>
                                                      <a href={doc.url} target="_blank" rel="noopener noreferrer" className="text-sm font-thin line-clamp-1">
                                                        previous image: {doc.file_name}
                                                      </a>
                                                    </div>
                                                  ))
                                                )
                                              ))
                                            )}
                                          </div>


                                        

                                        </div>
                                        <Divider className="w-full pt-16
                                         text-[#8C8C8C] text-xs">

                                        </Divider>
                                        <div className="flex flex-col sm:flex-row mt-3 gap-6 items-start w-full">

                                          <div className="w-full sm:w-1/4 text-xs font-normal text-[#8C8C8C]">
                                            <p className="font-bold text-xs">All Documents Must Be:</p>
                                            <ul className="ml-3">
                                              <li className="list-outside list-disc">Clear full colour scans <br/> (no document photographs)</li>
                                              {/* <li className="list-outside list-disc">No document photographs</li> */}
                                              <li className="list-outside list-disc">Max file size 5mb</li>
                                            </ul>
                                          </div>
                                          <div className="w-full sm:w-1/4 text-xs font-normal text-[#8C8C8C]">

                                            <p className="font-bold text-xs">Passport Requirements:</p>
                                            <ul className="ml-3">
                                              <li className="list-outside list-disc">Photo and details pages</li>
                                              <li className="list-outside list-disc"> Please also upload the address page <br/>if you have one</li>
                                            </ul>
                                          </div>

                                        {
                                           (partners[selectedPartnerIndex]?.nationality_name === "United Arab Emirates" || partners[selectedPartnerIndex]?.is_gcc === true) ||
                                           (PartnerFormik.values.partners[selectedPartnerIndex]?.nationality_name === "United Arab Emirates" || PartnerFormik.values.partners?.is_gcc === true)
                                           ? "" : <>
                                            <div className="w-full sm:w-1/4 text-xs font-normal text-[#8C8C8C]">

                                              <p className="font-bold text-xs">Passport Photo Requirements:</p>
                                              <ul className="ml-3">
                                                <li className="list-outside list-disc">White photo background</li>
                                                <li className="list-outside list-disc">Same requirements as passport photo</li>
                                              </ul>
                                            </div>                                           
                                           </>
                                        }

                                          
                                        {
                                          partners[selectedPartnerIndex]?.hasBeenToUAE === true || partners[selectedPartnerIndex]?.hasBeenToUAE === "true" && <>
                                            <div className="w-full sm:w-1/4 text-xs font-normal text-[#8C8C8C]"
                                                hidden={partners[selectedPartnerIndex]?.hasBeenToUAE === true || partners[selectedPartnerIndex]?.hasBeenToUAE === "true" ? false : true}
                                            >

                                              <p className="font-bold text-xs">Last Visa Page Requirements:</p>
                                              <ul className="ml-3">
                                                <li className="list-outside list-disc">Please upload the last page of your passport</li>
                                              </ul>
                                            </div>                                          
                                          </>
                                        }


                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>

                              </DialogContent>
                            </Box>

                            <div>

                              <div className="flex justify-center">
                                {PartnerFormik.values.partners.length > 0 &&
                                  PartnerFormik.values.partners.map((partner, index) => (
                                    <>
                                      {/* <p 
                                        className={`px-3 cursor-pointer mt-2 mr-1 text-center py-1 text-sm font-medium ${index === selectedPartnerIndex ? 'bg-[#81EF6F]' : 'bg-[#EBEBE6]'
                                          } rounded-lg`}>{index + 1}</p> */}
                                      <div key={index}>
                                        <Brightness1Icon className="cursor-pointer" onClick={() => handlePartnerChange(index)} sx={{ fontSize: "13px", color: index === selectedPartnerIndex ? '#81EF6F' : '#EBEBE6', mr: 1 }} />
                                      </div>
                                    </>
                                  ))}
                              </div>
                              <Box
                                sx={{
                                  alignItems: "flex-end",
                                  display: "flex",
                                  gap: "0.1rem",
                                  mt: 1,
                                  width: "100%"
                                }}
                              >

                                <Button
                                  variant="contained"
                                  fullWidth
                                  className="three-btn-row"
                                  sx={{
                                    backgroundColor: "rgb(212 212 216)",
                                    fontWeight: 600,
                                    
                                    "&:hover": {
                                      backgroundColor: "#81EF6F",
                                    },
                                    color: "#1E1D1B",
                                    borderRadius: "0px",
                                    textTransform: "none",
                                    minWidth: "30%",
                                  }}
                                  onClick={() => { setOpen(false) }}
                                >
                                  Save & Exit
                                </Button>
                                <Button
                                  variant="contained"
                                  fullWidth
                                  className="three-btn-row"
                                  sx={{
                                    backgroundColor: "#81ef6f",
                                    fontWeight: 600,
                                    color: "#1E1D1B",
                                    borderRadius: "0px",
                                    textTransform: "none",

                                    "&:hover": {
                                      backgroundColor: "#81EF6F",
                                    },
                                    display: "flex",

                                    gap: "10px",

                                  }}
                                  onClick={() => {
                                    if (selectedPartnerIndex < partners.length - 1) {
                                      // Increment the partner index if it's not the last partner
                                      setSelectedPartnerIndex(selectedPartnerIndex + 1);
                                    } else {
                                      // Close the modal if it's the last partner
                                      setOpen(false);
                                    }
                                  }}
                                // onClick={() => { setSelectedPartnerIndex(partners.length - 1 > selectedPartnerIndex ? selectedPartnerIndex + 1 : selectedPartnerIndex) }}
                                >
                                  Continue
                                </Button>
                              </Box>
                            </div >
                          </Box>

                        </Modal>
                      </div>

                    </Box>
                  </>
                ))}

            </div>
            <div>
              <div className="pl-7 sm:pl-10 mt-2 mb-3 w-full">
                <hr className="text-[#EBEBE6] border w-[90%] mb-3" />
                <div onClick={() => setOpen(true)} className=" flex cursor-pointer justify-center gap-3 w-[90%] border-[#81EF6F] bg-[#E6FCE2] rounded-md items-center px-5 border-2 py-2">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9978 5.87895 15.1542 3.84542 13.6544 2.34562C12.1546 0.845814 10.121 0.00223986 8 0ZM8 14.7692C6.66118 14.7692 5.35241 14.3722 4.23922 13.6284C3.12603 12.8846 2.2584 11.8274 1.74605 10.5905C1.2337 9.35356 1.09965 7.99249 1.36084 6.67939C1.62203 5.36629 2.26674 4.16012 3.21343 3.21343C4.16013 2.26674 5.36629 1.62203 6.67939 1.36084C7.99249 1.09965 9.35356 1.2337 10.5905 1.74605C11.8274 2.25839 12.8846 3.12602 13.6284 4.23922C14.3722 5.35241 14.7692 6.66117 14.7692 8C14.7672 9.79469 14.0534 11.5153 12.7843 12.7843C11.5153 14.0534 9.79469 14.7672 8 14.7692ZM11.6923 8C11.6923 8.16321 11.6275 8.31973 11.5121 8.43514C11.3967 8.55055 11.2401 8.61538 11.0769 8.61538H8.61539V11.0769C8.61539 11.2401 8.55055 11.3967 8.43515 11.5121C8.31974 11.6275 8.16321 11.6923 8 11.6923C7.83679 11.6923 7.68027 11.6275 7.56486 11.5121C7.44945 11.3967 7.38462 11.2401 7.38462 11.0769V8.61538H4.92308C4.75987 8.61538 4.60334 8.55055 4.48794 8.43514C4.37253 8.31973 4.3077 8.16321 4.3077 8C4.3077 7.83679 4.37253 7.68026 4.48794 7.56486C4.60334 7.44945 4.75987 7.38461 4.92308 7.38461H7.38462V4.92308C7.38462 4.75987 7.44945 4.60334 7.56486 4.48793C7.68027 4.37253 7.83679 4.30769 8 4.30769C8.16321 4.30769 8.31974 4.37253 8.43515 4.48793C8.55055 4.60334 8.61539 4.75987 8.61539 4.92308V7.38461H11.0769C11.2401 7.38461 11.3967 7.44945 11.5121 7.56486C11.6275 7.68026 11.6923 7.83679 11.6923 8Z" fill="black" />
                  </svg>

                  <p className="text-[14px] sm:text-[18px]" >Click to Upload Documents</p>
                </div>
                {idCardError && (
                  <div className="text-red-500 mt-2 text-xs">
                    {idCardError}
                  </div>
                )}
              </div>


              <Box
                sx={{
                  alignItems: "flex-end",
                  display: "flex",
                  gap: "0.1rem",
                  borderTop: "2px solid #EBEBE6"
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  className="three-btn-row"
                  sx={{
                    backgroundColor: "rgb(212 212 216)",
                    fontWeight: 600,
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#81EF6F",
                      boxShadow: "none",
                    },
                    color: "#1E1D1B",
                    borderRadius: "0px",
                    textTransform: "none",
                    maxWidth: windowWidth < 640 ? "48%" : "28%",
                  }}
                  onClick={onBack}
                  disabled={PartnerFormik.isSubmitting}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "rgb(212 212 216)",
                    fontWeight: 600,
                    display: windowWidth < 640 ? "none" : "flex",
                    "&:hover": {
                      backgroundColor: "#81EF6F",
                      boxShadow: "none",
                    },
                    color: "#1E1D1B",
                    borderRadius: "0px",
                    boxShadow: "none",
                    textTransform: "none",
                    maxWidth: "28%",
                  }}
                  onClick={async () => {
                    const errors = await PartnerFormik.validateForm();
                    if (Object.keys(errors).length === 0) {
                      handleSubmit(PartnerFormik.values, true);
                    } else {
                      PartnerFormik.submitForm();
                    }
                  }}
                >
                  Save & Exit
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={()=> {
                    scrollToFirstError()
                  }}
                  fullWidth
                  className="three-btn-row"
                  sx={{
                    backgroundColor: "#81ef6f",
                    fontWeight: 600,
                    color: "#1E1D1B",
                    borderRadius: "0px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#81EF6F",
                      boxShadow: "none",
                    },
                    display: "flex",
                    gap: "10px",
                    boxShadow: "none",
                  }}
                  disabled={PartnerFormik.isSubmitting}
                >
                  Next Step
                  {nextLoading && (
                    <svg
                      className="animate-spin w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                    </svg>
                  )}
                </Button>
              </Box>
            </div>
          </Form >

      </FormikProvider>


        
      {/* </Formik> */}
    </>
  );
};

export default RequiredDocument;
