// ForgetPassword.js
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, Container, Grid, CircularProgress } from "@mui/material";
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import NavbarNew from "./NavbarNew";
import { Link } from "react-router-dom";
import axios from 'axios';
import { API_URL } from "../Utilities/ConstantSettings";
// import { ForgetPasswordActivity } from "../helpers/api"; // Adjust the import path as needed

const ForgetPassword = () => {

  const [apiloading, setApiloading] = useState(false);

  const initialValues = {
    username: "",
  };

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Email is required";
    }
    if ( values.username?.length && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)) {
      errors.username = "Invalid email address";
    }
    return errors;
  };

  const handleSubmit = async (values, {setErrors}) => {
    setApiloading(true);
    try {
      const response = await axios.post(`${API_URL}/api/auth/reset-password/`, {
        email: values.username,
      });
      // Assuming "Password recovery email sent" is the only success message
      toast.success(`Email sent to ${values.username}`);
    } catch (error) {
        //toast.error('Email does not exist');
        setErrors({username:"Email does not exist"});
    } finally {
      setApiloading(false);
    }
  };
  
  
  return (
    <div className="bg-[#EBEBE6] sm:min-h-[calc(100dvh - 25px)]">
      <NavbarNew />
      <div className="flex items-center  min-h-[calc(100dvh-60px)] sm:min-h-[80vh] mt-[-12px] sm:mt-0">
        <Container sx={{ padding:{xs:"0px 10px", smc: "0px 16px"} }}>
          <Grid container sx={{ justifyContent: "center", alignItems: "center", height:{ xs:"calc(100dvh - 85px)", smc:"100%"} }}>
            <Grid item xs={12} sm={11} md={9} lg={6} xl={6} sx={{ height:"100%",}}>
              <div className="bg-[#fbfafb] py-12 rounded-[20px] flex flex-col gap-8 items-center h-full">
                <p className="text-[26px] sm:text-[28px] text-[#1E1D1B] font-bold pt-6">Forget Your Password?</p>
                <Formik
                  initialValues={initialValues}
                  validate={validate}
                  onSubmit={(values, {setErrors}) => handleSubmit(values, {setErrors})}
                >
                  {({ errors, touched }) => (
                    <Form className="space-y-4 flex flex-col items-center w-[275px] sm:w-[inherit]">
                      <div className="flex w-full flex-col items-start mb-10">
                        <div className="text-[#323338] text-[16px] font-semibold mb-1 hidden sm:block">Email</div>
                        <Field
                          type="text"
                          name="username"
                          className={`p-2 w-[100%] sm:w-80 bg-transparent rounded-md border-2 border-[#EBEBE6]  ${
                            errors.username && touched.username ? "border-red-600" : "border-[#EBEBE6]"
                          }`}
                          placeholder="Enter Email"
                          aria-label="Email"
                        />
                        <ErrorMessage name="username">
                          {msg => <div className="text-red-500 text-sm">{msg}</div>}
                        </ErrorMessage>
                        {!errors.username && (
                          <div className="text-[#8C8C8C] text-[14px] pl-2">
                            We will send a password reset link to your email
                          </div>
                        )}
                      </div>
                      <Button
                        type="submit"
                        variant="contained"
                        disableElevation
                        fullWidth
                        disabled={apiloading}
                        sx={{
                          textTransform: "none",
                          backgroundColor: "#81EF6F",
                          borderRadius: "30px",
                          fontSize: "18px",
                          color: "#000000",
                          "&:hover": { backgroundColor: "#81EF6F" },
                        }}
                        endIcon={
                          apiloading && (
                            <CircularProgress size={24} color="inherit" />
                          )
                        }
                      >
                        Send
                      </Button>
                      <div className="font-[#1E1D1B] text-[14px]">
                        Or go back to <Link className="underline" to="/signin">Sign In</Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default ForgetPassword;
