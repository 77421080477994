import React, { useState, useEffect } from 'react';
import { Button, Box, Dialog, DialogActions, CircularProgress, DialogContent, Divider, Checkbox, useMediaQuery, Popper, Tooltip, IconButton, DialogTitle, } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import success from "../../assets/success.jpg";
import { apiCallWithAxiosCancel, AppStepLock, SaveFinalPayment, ShareWithSheraaCheckbox } from "../../helpers/api";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetApplicationData, UpdateStripePaymentStatus, CreateStripePayment } from '../../helpers/api';
import LoadingModal from '../../helpers/LoadingModal';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { set } from 'date-fns';
import { formatNumberWithCommas, valueTester } from '../../Utilities/UtilityFunctions';
import { ExclaimationIcon } from '../../assets/svgs/svgs';
import { BootstrapDialog } from './SelectJurisdiction';
import CloseIcon from '@mui/icons-material/Close';
import { blockStep } from '../../features/stepsBlocker';



function FinalPayment({ onNext, onBack, onSaveAndExit, setMaxStepReached }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [apiload, setApiload] = useState(false);
    const [open, setOpen] = useState(false); // State to manage modal open state
    const applicationID = useSelector((state) => state.app.applicationID);
    const [loading, setLoading] = useState(false);
    const [selectedJurisdiction, setSelectedJurisdiction] = useState(null);
    const [costingBreakDown, setCostingBreakDown] = useState();
    const [totalPrice, setTotalPrice] = useState(0);
    const [lockStripe, setLockStripe] = useState(false);
    const [licenseCostBreakdown, setLicenseCostBreakdown] = useState();
    const [showingPaymentProcess, setShowingPaymentProcess] = useState(false);
    const [sheraaCheckbox, setSheraCheckbox] = useState(false)
    const jurisdictionWhitelist = ["SPC", "SHAMS"]
    const [jurisdictionShortName, setJurisdictionShortName] = useState(null)
    const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
    const [anchorEl , setAnchorEl] = useState(null);

    const [loadingCheckboxRes, setLoadingCheckboxRes] = useState(false)

    const appLock = useSelector((state) => state.stepBlocker)
    
    const checkSheraaCheckbox = async (value) => {
        setLoadingCheckboxRes(true)
        try{
            const response = await ShareWithSheraaCheckbox(
                {
                    "app_id": applicationID,
                    "share_with_sheraa": value
                }
            )  
            setSheraCheckbox(value)
            setLoadingCheckboxRes(false)
        }
        catch(error){
            setLoadingCheckboxRes(false)
        }

    }

    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleMouseLeave = () => {
    setAnchorEl(null);
    };

    const [openPriceBreakdownModal ,setOpenPriceBreakdownModal] = useState({
        open: false,
        type: "" // license OR visa-cost
    })

    function renderModalTitle(type) {

        if (type === "license"){

            if (licenseCostBreakdown && licenseCostBreakdown.filter(item => item.price !== 0 && item.name !== "Transaction_Fee_Stripe" && item.name !== "transaction_fee" ).some(
                (cost) => cost.department !== null && cost.department?.length > 0) === true){
                return "Cost Breakdown"
            }
            else return "License cost breakdown:"

        }
        
        else if (type === "visa-cost"){

            if(costingBreakDown && costingBreakDown.filter(item => item.price !== 0 && item.name !== "Service_Charges_Firm_Fox" && item.name !== "Service_Charges_Firmfox" && item.name !== "firmfox_service_fee" && item.name !== "Service_Charges" && item.name !== "Office_cost"  ).some(
                (cost) => cost.department !== null && cost.department?.length > 0) === true){
                    return "Cost Breakdown"
                }
            else return "Visa cost breakdown:"
        }

    }

    const infoOpen = Boolean(anchorEl);
    const id = infoOpen ? 'simple-popper' : undefined;

    const handleCompleteClick = () => {
        setOpen(true); // Open the modal when the "Complete" button is clicked
    };

    const [screenHeight, setScreenHeight] = useState(window.innerHeight); // Initial height

    useEffect(() => {
      const updateHeight = () => {
        setScreenHeight(window.innerHeight); // Update state when window is resized
      };
  
      // Add event listener for window resize
      window.addEventListener('resize', updateHeight);
  
      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener('resize', updateHeight);
      };
    }, []);

    const [isEverythingDisabled, setIsEverythingDisabled] = useState(false);

    useEffect(() => {
        async function fetchApplicationData() {
            try {
                setLoading(true);
                console.log("Fetching application data for ID:", applicationID);
                const ApplicationData = await GetApplicationData(applicationID, dispatch);
                setIsEverythingDisabled(ApplicationData?.data[0]?.is_completed);

                setSelectedJurisdiction(ApplicationData?.data?.[0]?.jurisdiction?.[0]?.nameEn);
                setCostingBreakDown(ApplicationData?.data?.[0]?.cost_breakdown);
                setLicenseCostBreakdown(ApplicationData?.data?.[0]?.license_cost_breakdown);
                // setTotalPrice(ApplicationData?.data?.[0]?.costing?.[4]?.up_front_cost);
                setTotalPrice(ApplicationData?.data?.[0]?.costing?.find((item) => item["up_front_cost"] || item["renewal_cost"])?.["up_front_cost"]);

                setMaxStepReached(ApplicationData?.data?.[0]?.step_no + 1);
                setLockStripe(ApplicationData?.data?.[0]?.step_no);
                setSheraCheckbox(ApplicationData?.data?.[0]?.share_with_sheraa)
                setJurisdictionShortName(ApplicationData?.data?.[0]?.jurisdiction?.[0]?.short_name)
                console.log("jurisdiction short name = ", ApplicationData?.data?.[0]?.jurisdiction?.[0]?.short_name)
            } catch (error) {
                toast.error("Error occurred while fetching Application Data please refresh the page");
                console.error("Error occurred while fetching Application Data:", error);
            } finally {
                setLoading(false);
            }
        }

        if (applicationID) {
            fetchApplicationData();
        } else {
            console.error("No application ID found.");
        }
        return () => {
            setLoading(false); // Reset to ensure it doesn't persist when navigating back
        };
    }, [applicationID, dispatch]);



    function formatKey(key) {
        return key
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const [loadingStripeApi, setLoadingStripeApi] = useState(false)

    const handlePayment = async () => {
        setLoadingStripeApi(true)
        try {
            const payload = {
                amount: parseInt(totalPrice) * 100,
                currency: "aed",
                app_id: applicationID
            }
            setShowingPaymentProcess(true);
            console.log("Creating Stripe payment with payload:", payload);
            const response = await apiCallWithAxiosCancel((cancelToken) =>
                CreateStripePayment(payload, dispatch, cancelToken)
              );
            // const stripeUrl = `https://checkout.stripe.com/c/pay/${response.data.session_id}`;

            if (response.status === 200) {

                const stripe = await loadStripe(STRIPE_PUBLIC_KEY);
                if (stripe) {
                    console.log("Redirecting to Stripe checkout");
                    const { error } = await stripe.redirectToCheckout({
                        sessionId: response.data.session_id,
                    });
                    if (error) {
                        toast.error("Error redirecting to Stripe checkout");
                        setLoading(false);
                        // Handle error, e.g., show a message to the user
                    }
                    setLoading(false)
                }
                setLoading(false)
            }
            else {

                //if(error?.code !== "ERR_CANCELED" || error?.name !== "CanceledError"){
                toast.error("Error creating Stripe payment! try again later", {
                    toatId: "stripe_error1"
                });
                setLoading(false);
                setShowingPaymentProcess(false);
                setLoadingStripeApi(false)
                //}

            }
            setLoading(false);
            setShowingPaymentProcess(false);
            // // Use window.location.href to directly navigate to the Stripe checkout page
            // window.location.href = stripeUrl;
            setLoadingStripeApi(false)
            console.log("Stripe payment created:", response);

        } catch (error) {
            if(error?.code !== "ERR_CANCELED" || error?.name !== "CanceledError"){
                console.error("Error creating Stripe payment:", error);
                setLoading(false);
                toast.error("Error creating Stripe payment");
                setLoading(false);
                setShowingPaymentProcess(false);
                setLoadingStripeApi(false)
            }
        }
        finally {
            setLoading(false);
            setShowingPaymentProcess(false);
            setLoadingStripeApi(false)
        }
    };

    console.log(selectedJurisdiction);
    console.log(costingBreakDown);
    const getTotalCostingBreakdown = () => {
        return costingBreakDown?.reduce((sum, item) => sum + (parseFloat(item?.price) || 0), 0);
    };
    const remainingAmount = totalPrice - getTotalCostingBreakdown();

    
    function ShareWithSheraa({ id, infoOpen, anchorEl, handleMouseLeave }) {
        const isSmallScreen = useMediaQuery('(max-width:900px)');
        const placement = isSmallScreen ? 'bottom-start' : 'right-start';

        return (
          <Popper placement={placement} id={id} open={infoOpen} anchorEl={anchorEl} onMouseLeave={handleMouseLeave} 
          sx={{
            width:"100%",
          }}>
            <Box sx={{ fontSize:14, border: 1, p: 1, bgcolor: 'background.paper', borderRadius: 3, maxWidth: '500px', color: '#1E1D1B' }}>
            By checking this box, you consent to share your business information with the Sharjah Entrepreneurship Center (Sheraa) to be automatically enrolled in their ecosystem. This comes at no cost to you and grants access to exclusive perks and support from Sheraa.            </Box>
          </Popper>
        );
      }

      const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    // Add event listener on mount
    window.addEventListener("resize", handleResize);
  
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


    return (
        <> <LoadingModal open={loading} />
            
            <BootstrapDialog
                onClose={()=>setOpenPriceBreakdownModal({open:false, type: ""})}
                open={openPriceBreakdownModal.open}
                PaperProps={{ style: { minWidth: windowWidth > 640 ? "500px" : "95%" , maxWidth: '500px', minHeight: "40%" } }}
                >
                {/* Dialog content for Other Available Jurisdiction */}
                <DialogTitle
                    sx={{ m: 0, p: {xs:"20px 10px 10px 0px", smc:"20px 10px 10px 20px"}, fontSize: '18px', fontWeight: 'bold', marginLeft: '20px', marginTop: '15px' }}
                    id="customized-dialog-title"
                >
                    
                    {/* { openPriceBreakdownModal.type === "license" ? <> License cost breakdown: </> : openPriceBreakdownModal.type === "visa-cost" ? <> Visa cost breakdown: </> : ""} */}
                    
                    {renderModalTitle(openPriceBreakdownModal?.type)}

                    <br />

                    <span style={{ fontSize: '16px', fontWeight: 'normal', color: '#555', display: 'block', marginTop: '2px' }}>
                    { 

                    licenseCostBreakdown && licenseCostBreakdown.filter(item => item.price !== 0 && item.name !== "Transaction_Fee_Stripe" && item.name !== "transaction_fee" ).some(
                                (cost) => cost.department !== null && cost.department?.length > 0) ? selectedJurisdiction : "The price we have quoted includes the following charges:" }
                    </span>


                </DialogTitle> 

                <DialogContent sx={{paddingLeft: {xs:"20px", smc: "40px"}}}>
                    {openPriceBreakdownModal.type === "license" ? <>
                        {
                            licenseCostBreakdown && licenseCostBreakdown.filter(item => item.price !== 0 && item.name !== "Transaction_Fee_Stripe" && item.name !== "transaction_fee" ).some(
                                (cost) => cost.department !== null && cost.department?.length > 0
                              ) ? (<div className="justify-start flex flex-col gap-[20px] sm:gap-[60px]" style={{ fontSize: "14px" }}>

                                {/* Render the custom table layout */}
                                <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '14px' }}>
                                  <thead>
                                    <tr style={{ borderBottom: '0px solid #000' }}>
                                      <th style={{ textAlign: 'left', padding: '5px' }}>Item</th>
                                      <th style={{ textAlign: 'left', padding: '5px' }}>Department</th>
                                      <th style={{ textAlign: 'right', padding: '5px', minWidth: "83px" }}>Cost (AED)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {[...licenseCostBreakdown.filter(item => item.price !== 0 && item.name !== "Transaction_Fee_Stripe" && item.name !== "transaction_fee" )]
                                      ?.filter((cost) => cost.price > 0)
                                      ?.map((cost, index) => (
                                        <tr
                                          key={index}
                                          style={{
                                            backgroundColor: index % 2 === 0 ? '#E9E9E4' : '#FFF',
                                            height: '18px',
                                          }}
                                        >
                                          <td style={{ padding: '5px', fontSize:"14px"}}>{formatKey(cost?.name)}</td>
                                          <td style={{ padding: '5px', fontSize:"14px" }}>{cost?.department?.includes("_") ? cost?.department?.split("_").map((item) => (item + " ")) : cost?.department}</td>
                                          <td style={{ padding: '5px', fontSize:"14px", textAlign: 'right' }}>{cost?.price}</td>
                                        </tr>
                                      ))}

                                  </tbody>


                                </table>

                              </div>) :
                                licenseCostBreakdown && licenseCostBreakdown.filter(item => item.price !== 0 && item.name !== "Transaction_Fee_Stripe" && item.name !== "transaction_fee" ).map((item, index) => {
                                    return (<>
                                        <div key={index} className={`flex justify-start`}>
                                            <p className='w-1/2 text-[#1E1D1B] text-xs sm:text-sm '>{formatKey(item.name)}</p>
                                            <p className='w-1/2 text-[#1E1D1B] text-xs sm:text-sm font-bold'> { item.price > 0 ? `AED ${formatNumberWithCommas(item.price)}`: item.price}</p>
                                            <br/>
                                        </div>
                                    </>);
                                })
                        }
                    </> : openPriceBreakdownModal.type === "visa-cost" ? 

                    <>
                        {
                            costingBreakDown && costingBreakDown.filter(item => item.price !== 0 && item.name !== "Service_Charges_Firm_Fox" && item.name !== "Service_Charges_Firmfox" && item.name !== "firmfox_service_fee" && item.name !== "Service_Charges" && item.name !== "Office_cost" ).some(
                                (cost) => cost.department !== null && cost.department?.length > 0
                              ) ? (<div className="justify-start flex flex-col gap-[20px] sm:gap-[60px]" style={{ fontSize: "14px" }}>

                                {/* Render the custom table layout */}
                                <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '14px' }}>
                                  <thead>
                                    <tr style={{ borderBottom: '0px solid #000' }}>
                                      <th style={{ textAlign: 'left', padding: '5px' }}>Item</th>
                                      <th style={{ textAlign: 'left', padding: '5px' }}>Department</th>
                                      <th style={{ textAlign: 'right', padding: '5px', minWidth: "83px" }}>Cost (AED)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {[...costingBreakDown.filter(item => item.price !== 0 && item.name !== "Service_Charges_Firm_Fox" && item.name !== "Service_Charges_Firmfox" && item.name !== "firmfox_service_fee" && item.name !== "Service_Charges" && item.name !== "Office_cost" )]
                                      ?.filter((cost) => cost.price > 0)
                                      ?.map((cost, index) => (
                                        <tr
                                          key={index}
                                          style={{
                                            backgroundColor: index % 2 === 0 ? '#E9E9E4' : '#FFF',
                                            height: '18px',
                                          }}
                                        >
                                          <td style={{ padding: '5px', fontSize:"14px"}}>{formatKey(cost?.name)}</td>
                                          <td style={{ padding: '5px', fontSize:"14px" }}>{cost?.department?.includes("_") ? cost?.department?.split("_").map((item) => (item + " ")) : cost?.department}</td>
                                          <td style={{ padding: '5px', fontSize:"14px", textAlign: 'right' }}>{cost?.price}</td>
                                        </tr>
                                      ))}

                                  </tbody>


                                </table>

                              </div>) :
                                costingBreakDown && costingBreakDown.filter(item => item.price !== 0 && item.name !== "Service_Charges_Firm_Fox" && item.name !== "Service_Charges_Firmfox" && item.name !== "firmfox_service_fee" && item.name !== "Service_Charges" && item.name !== "Office_cost").map((item, index) => {
                                    return (<>
                                        <div key={index} className={`flex justify-start`}>
                                            <p className='w-1/2 text-[#1E1D1B] text-xs sm:text-sm '>{formatKey(item.name)}</p>
                                            <p className='w-1/2 text-[#1E1D1B] text-xs sm:text-sm font-bold'> { item.price > 0 ? `AED ${formatNumberWithCommas(item.price)}`: item.price}</p>
                                            <br/>
                                        </div>
                                    </>);
                                })
                        }
                    </> 
                    : ""
                    }
                </DialogContent>
                <IconButton
                    aria-label="close"
                    onClick={()=>setOpenPriceBreakdownModal({open:false, type:""})}
                    sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon sx={{ color: '#1E1D1B' }} />
                </IconButton>                                     
                
            </BootstrapDialog>

            <Box
                className="rounded-t-[20px]"
                display={"flex"}
                justifyContent={"space-between"}
                sx={{ minHeight: "100%", background:"white" }}
                flexDirection={"column"}
            >
                <Box sx={{px: { xs:3, smc:5} }} marginTop={2}>
                    <p className="font-bold text-xl sm:text-2xl  text-[#1E1D1B]">
                        Final Payment:
                    </p>

                    {
                      windowWidth < 640 ? <hr style={{borderWidth:"1px", margin:"10px 0px 10px 0px"}}/> : ""
                    }

                    <p className="font-bold text-md sm:text-lg pt-[20px]  text-[#1E1D1B]">
                        Payment breakdown:
                    </p>


                    <Box sx={{
                        maxHeight:{
                            xs: `calc(100dvh - 350px - 50px)`, 
                            smc: `calc(100dvh - 350px - 5px)`,
                            lgc: `calc(100dvh - 300px)`,
                            overflowY:"auto"
                            }
                        }}>
                        {/* <p className="font-bold text-md sm:text-xl sm:block hidden mt-4 xl:mt-16 text-[#1E1D1B]">
                            Selected Jurisdiction
                        </p> */}
                        {/* <p className='mt-4 text-[#1E1D1B] text-sm sm:text-md'>{selectedJurisdiction}</p> */}
                        <div style={{
                            // maxHeight: `calc(100dvh - 90px - 20px - 32px - 32px - 32px - 32px - 21px - 52px - 40px - 20px - 60px)`, overflowY:"auto"
                            }} 
                            className='sm:px-4 flex flex-col scrollbar overflow-y-auto pr-3 gap-3 mt-4'>
                                
                                <div className='flex gap-[20px] justify-between sm:justify-start'>
                                    <p className='w-1/2 text-[#1E1D1B] text-xs sm:text-sm '>{selectedJurisdiction} License Cost</p>
                                    <p className='w-1/2 text-[#1E1D1B] flex gap-[5px] sm:gap-[20px] min-w-[max-content] text-xs sm:text-sm sm:font-bold min-w-[100px] max-w-[100px]'>

                                    <span className='min-w-[78px] sm:min-w-[93px]'>
    
                                    AED {formatNumberWithCommas(
                                        licenseCostBreakdown && licenseCostBreakdown?.reduce((sum, item) => sum + (item?.price > 0 && item.name !== "Transaction_Fee_Stripe" && item.name !== "transaction_fee" ? (parseFloat(item?.price)) : 0), 0)                                        
                                    )}
                                    </span>

                                    <span
                                    style={{ margin: "0px 0px 0px 0px" }}
                                    className="block"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setOpenPriceBreakdownModal({open: true, type: "license"})
                                    }}
                                    >
                                    <ExclaimationIcon/>
                                    </span>   
                                    
                                    </p>
                                    
 

                                </div>

                                <div className='flex gap-[20px] justify-between sm:justify-start'>
                                    <p className='w-1/2 text-[#1E1D1B] text-xs sm:text-sm '> Visa Cost</p>
                                    <p className='w-1/2 text-[#1E1D1B] flex justify-between  sm:gap-[20px] min-w-[102px] text-xs sm:text-sm sm:font-bold min-w-[100px] max-w-[100px]'>
                                    
                                    <span className='min-w-[78px] sm:min-w-[93px]'>
                                        AED {formatNumberWithCommas(
                                            costingBreakDown && costingBreakDown?.reduce((sum, item) => sum + (item?.price > 0 && item.name !== "Service_Charges_Firm_Fox" && item.name !== "Service_Charges_Firmfox" && item.name !== "firmfox_service_fee" && item.name !== "Service_Charges" && item.name !== "Office_cost" ? (parseFloat(item?.price)) : 0), 0)                                        
                                        )}
                                    </span>
                                        <span
                                        style={{ margin: "0px 0px 0px 0px" }}
                                        className="block"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setOpenPriceBreakdownModal({open: true, type: "visa-cost"})
                                        }}
                                        >
                                        <ExclaimationIcon/>
                                        </span>                                     
                                    </p>
                                    
   

                                </div>

                                {
                                    costingBreakDown && costingBreakDown.find((item) => item.name === "Office_cost"  )?.price  && 

                                    <div className='flex gap-[20px] justify-between sm:justify-start' 
                                        hidden={
                                                formatNumberWithCommas(
                                                    costingBreakDown && costingBreakDown.find((item) => item.name === "Office_cost"  )?.price                                        
                                                ) === "N/A" ? true : false
                                            }
                                        >
                                        <p className='w-1/2 text-[#1E1D1B] text-xs sm:text-sm '> Office Cost </p>
                                        <p className='w-1/2 text-[#1E1D1B] flex justify-between gap-[5px] min-w-[max-content] text-xs sm:text-sm sm:font-bold min-w-[100px] max-w-[100px]'>
                                        
                                            AED {formatNumberWithCommas(
                                                costingBreakDown && costingBreakDown.find((item) => item.name === "Office_cost"  )?.price                                        
                                            )}
                                

                                        </p>
                                    
                                    </div>

                                }



                                <div className='flex gap-[20px] justify-between sm:justify-start'>
                                    <p className='w-1/2 text-[#1E1D1B] text-xs sm:text-sm '> FirmFox Service Charge</p>
                                    <p className='w-1/2 text-[#1E1D1B] flex justify-between gap-[5px] min-w-[max-content] text-xs sm:text-sm sm:font-bold min-w-[100px] max-w-[100px]'>
                                    
                                        AED {formatNumberWithCommas(
                                            costingBreakDown && costingBreakDown.find((item) => item.name === "Service_Charges_Firm_Fox" || item.name === "Service_Charges_Firmfox" || item.name === "firmfox_service_fee" || item.name === "Service_Charges" )?.price                                        
                                        )}
                              

                                    </p>
                                
                                </div>

                                <div className='flex gap-[20px] justify-between sm:justify-start'>
                                    <p className='w-1/2 text-[#1E1D1B] text-xs sm:text-sm '> Transaction Fee</p>
                                    <p className='w-1/2 text-[#1E1D1B] flex justify-between gap-[5px] min-w-[max-content] text-xs sm:text-sm sm:font-bold min-w-[100px] max-w-[100px]'>
                                    
                                        AED {formatNumberWithCommas(
                                            licenseCostBreakdown && licenseCostBreakdown.find((item) => item.name === "Transaction_Fee_Stripe" || item.name === "transaction_fee")?.price                                        
                                        )}
                              

                                    </p>
                                    
   

                                </div>
                            {/* {
                                costingBreakDown && costingBreakDown.filter(item => item.price !== 0).map((item, index) => {
                                    return (
                                        <div key={index} className='flex gap-[20px] justify-between sm:justify-start'>
                                            <p className='w-1/2 text-[#1E1D1B] text-xs sm:text-sm '>{formatKey(item.name)}</p>
                                            <p className='text-[#1E1D1B] text-xs sm:text-sm sm:font-bold'>AED {formatNumberWithCommas(item.price)}</p>
                                        </div>
                                    );
                                })

                            } */}
                            {/* {
                                licenseCostBreakdown && licenseCostBreakdown.filter(item => item.price !== 0).map((item, index) => {
                                    return (
                                        <div style={{border:"2px solid red"}} key={index} className='flex gap-[20px]justify-between sm:justify-start'>
                                            <p className='w-1/2 text-[#1E1D1B] text-xs sm:text-sm '>{formatKey(item.name)}</p>
                                            <p className='text-[#1E1D1B] text-xs sm:text-sm sm:font-bold'>AED {formatNumberWithCommas(item.price)}</p>
                                        </div>
                                    );
                                })
                            } */}

                            {/* <div className='flex gap-2'>
                                <p className='w-1/2 text-[#1E1D1B] text-sm '>Activity Cost</p>
                                <p className='text-[#1E1D1B] text-sm font-bold'>{remainingAmount}</p>
                            </div> */}
                            <p className="text-[14px] text-[#1E1D1B] font-normal leading-5">
                                This is an upfront payment, to be made in full. { windowWidth > 640 && <br /> }  This cost covers your trade license for 1 year, and covers your visas for 2 years.

                            </p>
                            {
                            jurisdictionWhitelist.includes(jurisdictionShortName) && <>
                            
                            
                            <div className="flex items-center gap-2 mt-4">
                                <input disabled={isEverythingDisabled} checked={sheraaCheckbox} type="checkbox" id="sharewithSheraa" onChange={(event)=>{
                                    checkSheraaCheckbox(event.target.checked)
                                }}/>
                                <label htmlFor="sharewithSheraa" className="text-[#1E1D1B] text-[14px] sm:text-sm">Join Sheraa’s Entrepreneurial Ecosystem at No Extra Cost!</label>
                                <IconButton
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    aria-describedby={id}
                                    type="button"
                                >
                                    <InfoOutlinedIcon sx={{ color: "#1E1D1B" }} />
                                </IconButton>

                                {/* Popover component positioned relative to IconButton */}
                                <ShareWithSheraa
                                    id={id}
                                    infoOpen={infoOpen}
                                    anchorEl={anchorEl}
                                    onMouseLeave={handleMouseLeave}
                                />
                            </div>

                            <a className='text-[12px] underline text-blue-500 mt-[-10px]' href='https://sheraa.ae'>Learn More about Sheraa</a>

                            </>}


                        </div>                        
                    </Box>



                </Box>
                <Box sx={{px: { xs:3, smc:5} }}>
                    <Divider className="w-full text-[#EBEBE6] border-4 text-lg pt-5" />
                    <div className='flex justify-center'>
                        {
                            lockStripe > 6 ? <button type='button' onClick={() => navigate("/")} className='flex font-semibold gap-2 mt-4 border-2 items-center py-3 px-5 rounded-md text-[14px] sm:text-[16px]' style={{ backgroundColor: '#81EF6F' }}>
                                Payment is already made. Click to return to the Dashboard.
                            </button>
                                :
                                <button disabled={loadingStripeApi} type='button' onClick={handlePayment} className='flex justify-center font-semibold gap-2 mt-4 border-2 items-center py-3  px-5 rounded-lg w-[100%] sm:w-max min-w-[271.5px]' style={{ backgroundColor: '#81EF6F', flexDirection:"column-reverse" }}>
                                    {showingPaymentProcess && (<div style={{width:"100%"}} className="w-full flex justify-center">
                                        <svg
                                            className="animate-spin w-6 h-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                                        </svg>

                                    </div>

                                    )} 
                                    {!showingPaymentProcess && <span hidden={showingPaymentProcess} className='text-[14px] flex justify-between'>Click to Pay Total <div className='font-semibold text-[14px] pl-4 sm:pt-0'>AED {formatNumberWithCommas(totalPrice)}</div></span>  } 
                                </button>
                        }

                    </div>
                </Box>
                <Box
                    sx={{
                        alignItems: "flex-end",
                        display: "flex",
                        gap: "0.1rem",
                        mt: 3,
                        border: "2px solid #EBEBE6"
                    }}
                >
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: "rgb(212 212 216)",
                            fontWeight: 600,
                            boxShadow: "none",
                            "&:hover": {
                                backgroundColor: "#81EF6F",
                                boxShadow: "none"
                            },
                            color: "#1E1D1B",
                            borderRadius: "0px",
                            textTransform: "none",
                            maxWidth: "50%"
                        }}
                        onClick={onBack}

                    >
                        Previous
                    </Button>

                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: "#81ef6f",
                            fontWeight: 600,
                            color: "#1E1D1B",
                            borderRadius: "0px",
                            textTransform: "none",
                            boxShadow: "none",
                            "&:hover": {
                                backgroundColor: "#81EF6F",
                                boxShadow: "none",
                            },
                        }}
                        onClick={async () => {
                                  if(appLock.is_blocked === true){
                                    await AppStepLock({ 
                                        app_id: valueTester(appLock.app_id) ? appLock.app_id : applicationID,
                                        is_blocked: false, 
                                        current_step: appLock.currentStep >= 7 ? appLock.currentStep : 7, 
                                    }, dispatch)
                                        .then(()=>{
                                        dispatch(blockStep({ is_blocked: false, currentStep: appLock.currentStep >= 7 ? appLock.currentStep : 7, app_id: valueTester(appLock.app_id) ? appLock.app_id : applicationID}));
                                        })    
                                        .catch((error)=>{

                                        })            
                              }
                            navigate("/");

                        }} // Open modal on button click

                    >
                        Save & Exit
                    </Button>
                </Box>
            </Box>

        </>
    );
}

export default FinalPayment;
