


import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  Button,
  Typography,
  Container,
  Divider,
  Grid,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { LoginvalidationSchema } from "./ValidationSchema";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login, setError } from "../features/appSlice";
import NavbarNew from "./NavbarNew";
import axios from "axios";
import ForgetPassword from "./ForgetPassword";
import { API_URL } from "../Utilities/ConstantSettings";

const LogInPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiloading, setApiloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = LoginvalidationSchema;

  const [loadingSignIn, setLoadingSignIn] = useState(false)
  const handleSubmit = async (values, {setErrors}) => {
    setApiloading(true);
    try {
      const response = await axios.post(
        `${API_URL}/api/auth/login/`,
        values
      );
      console.log("user login info", response.data.user);
      if (response?.data?.access) {
        dispatch(
          login({
            user: response?.data?.user,
            token: response?.data?.access,
            refresh: response?.data?.refresh,
            role: response?.data?.is_agent,
          })
        );
        localStorage.setItem("access_token", response?.data?.access);
        localStorage.setItem("refresh_token", response?.data?.refresh);
      }
      setApiloading(false);
      navigate("/");
    } catch (error) {
      console.error("Error logging in:", error);
      setApiloading(false);
      if (error?.response && error?.response?.data && error?.response?.data?.error) {
        const errorMessage = error.response.data.error;
        console.log("error ==> ", errorMessage)

        if (`${errorMessage}`?.toLowerCase()?.includes("not verified")) {

            setErrors({username:"Your email is not verified."});
        }
        else if (`${errorMessage}`?.toLowerCase()?.includes("not active")) {
          setErrors({username: errorMessage || "This account is not active anymore."})
        }
        
        else {
            setErrors({password:"Oops! The email or password is incorrect."});
        }
    } else {
        setErrors({password:"An unexpected error occurred. Please try again later."})
    }

    }
  };

  return (
    <div className="sm:min-h-[calc(100dvh - 25px)] bg-[#EBEBE6]">
      {/* <ToastContainer/> */}
      <NavbarNew />
      <div className="flex w-full items-center min-h-[calc(100dvh-60px)] sm:min-h-[80vh] mt-[-12px] sm:mt-0">
        <Container sx={{padding:{xs:"0px 10px", smc: "0px 16px"}}}>
          <Grid
            container
            sx={{ justifyContent: "center", alignItems: "center", height:"100%", minHeight:"100%", }}
          >
            <Grid item xs={12} sm={11} md={9} lg={6} xl={6} sx={{height:{ xs:"calc(100dvh - 85px)", smc:"100%"},  }}>
              <div className="bg-[#fbfafb] pt-9 sm:pb-9 rounded-[20px] flex flex-col gap-8 items-center h-full" >
                <p className="text-3xl text-[#1E1D1B] font-bold">Sign In</p>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={ (values, {setErrors}) => handleSubmit(values, {setErrors})}
                >
                  {({ errors, touched }) => (
                    <Form
                      className="space-y-4 flex flex-col items-center ml-[-10px] sign-in-form"
                      fullWidth
                    >
                      <div style={{width: "calc(100%)"}} className="flex w-full flex-col items-start">
                        <div className="text-[#323338] font-semibold mb-1">
                          Email
                        </div>
                        <Field
                          type="username"
                          fullWidth
                          name="username"
                          className={`py-2 pl-2 w-[100%] sm:w-80 bg-transparent rounded-md border border-black border-opacity-25 ${
                            errors.username && touched.username
                              ? "border-red-600"
                              : "border-[#EBEBE6]"
                          }`}
                        />
                        {errors.username && touched.username ? (
                          <div className="text-red-500 text-sm">
                            {errors.username}
                          </div>
                        ) : null}
                      </div>
                      <div style={{width: "calc(100%)"}} className="flex w-full flex-col items-start">
                        <div className="text-[#323338] font-semibold mb-1">
                          Password
                        </div>
                        <Field
                          name="password"
                          as={TextField}
                          type={showPassword ? "text" : "password"}
                          fullWidth
                          sx={{
                            '& .MuiInputBase-root': {
                              maxHeight: '43px', // Setting max height for the input base
                            },
                            '& .MuiOutlinedInput-input': {
                              padding: '10px', // Adjust padding as needed
                            },
                          }}
                          variant="outlined"
                          className={`p-2 w-full bg-transparent rounded-md border-2 border-[#EBEBE6] ${
                            errors.password && touched.password
                              ? "border-red-600"
                              : "border-[#EBEBE6]"
                          }`}
                          // Adjust the height value as needed
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errors.password && touched.password ? (
                          <div className="text-red-500 text-sm">
                            {errors.password}
                          </div>
                        ) : null}
                      </div>
                      <div className="flex justify-start my-1 underline w-full">
                        <p className="text-start">
                          <Link
                            color="textPrimary"
                            sx={{ color: "#1E1D1B", fontSize: "14px" }}
                            to="/ForgetPassword"
                            variant="body2"
                          >
                            Forgot your password?
                          </Link>
                        </p>
                      </div>
                      <Button
                        type="submit"
                        variant="contained"
                        disableElevation
                        fullWidth
                        disabled={apiloading}
                        sx={{
                          textTransform: "none",
                          backgroundColor: "#81EF6F",
                          borderRadius: "30px",
                          fontSize: "18px",
                          color: "#000000",
                          "&:hover": { backgroundColor: "#81EF6F" },
                        }}
                        endIcon={
                          apiloading && (
                            <svg
                              className="animate-spin w-6 h-6"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                            </svg>
                          )
                        }
                      >
                        Sign In
                      </Button>
                      {/* <Divider className="w-full text-[#8C8C8C] text-xs">
                        OR
                      </Divider>
                      <Button
                        disableElevation
                        sx={{
                          textTransform: "none",
                          backgroundColor: "#fbfafb",
                          borderRadius: "30px",
                          marginBottom: "1rem",
                          border: "2px solid #EBEBE6",
                          "&:hover": { backgroundColor: "#fbfafb" },
                          fontSize: "18px",
                          color: "#1E1D1B",
                        }}
                        startIcon={
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_321_150)">
                              <path
                                d="M19.7616 10.1873C19.7616 9.36792 19.6951 8.76998 19.5512 8.1499H10.1946V11.8482H15.6867C15.576 12.7673 14.9781 14.1514 13.6493 15.0815L13.6307 15.2053L16.5891 17.4972L16.794 17.5176C18.6764 15.7791 19.7616 13.2213 19.7616 10.1873Z"
                                fill="#4285F4"
                              />
                              <path
                                d="M10.1947 19.9312C12.8854 19.9312 15.1442 19.0453 16.7941 17.5173L13.6494 15.0812C12.8079 15.6681 11.6784 16.0777 10.1947 16.0777C7.55932 16.0777 5.3226 14.3393 4.52527 11.9365L4.4084 11.9464L1.33222 14.3271L1.29199 14.439C2.93077 17.6944 6.29695 19.9312 10.1947 19.9312Z"
                                fill="#34A853"
                              />
                              <path
                                d="M4.52526 11.9368C4.31488 11.3167 4.19313 10.6523 4.19313 9.96583C4.19313 9.27927 4.31488 8.61492 4.5142 7.99484L4.50862 7.86278L1.39389 5.44385L1.29198 5.49232C0.616561 6.84324 0.229004 8.36026 0.229004 9.96583C0.229004 11.5714 0.616561 13.0884 1.29198 14.4393L4.52526 11.9368Z"
                                fill="#FBBC05"
                              />
                              <path
                                d="M10.1947 3.85336C12.066 3.85336 13.3283 4.66168 14.048 5.33718L16.8605 2.59107C15.1332 0.985496 12.8854 0 10.1947 0C6.29695 0 2.93077 2.23672 1.29199 5.49214L4.51421 7.99466C5.3226 5.59183 7.55932 3.85336 10.1947 3.85336Z"
                                fill="#EB4335"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_321_150">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        }
                        variant="contained"
                        fullWidth
                        color="primary"
                      >
                        Continue with Google
                      </Button> */}
                      <Typography
                        variant="body2"
                        align="center"
                        style={{ marginTop: "1rem", color: "#1E1D1B" }}
                      >
                        Don’t have an account?
                        <Link
                          className="ml-1 underline text-sm"
                          to="/signup"
                          variant="body2"
                        >
                          Sign up
                        </Link>
                      </Typography>
                    </Form>
                  )}
                </Formik>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default LogInPage;


