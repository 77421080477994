import React, { useEffect, useState } from 'react';
import { Box, Button, Drawer, SvgIcon } from '@mui/material';
import logo from "../assets/svgs/firmfox-logo.svg"
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

// unauth navbar
function NavbarNew({ children }) {

    const navigate = useNavigate();
    const isSignUpPage = window.location.href.includes("/signup");
    const isLoginPage = window.location.href.includes("/signin");
    const isRefferalPage = window.location.href.includes("/referral")

    const handleRedirect = () => {
        window.location.href = 'https://firmfox.com/our-services';
      };

    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDrawerCustom, setOpenDrawerCustom] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          // Check if the drawer is open and screen size is greater than a certain breakpoint
          if (openDrawer && window.innerWidth > 640) {
            setOpenDrawer(false); // Close the drawer
            setOpenDrawerCustom(false)
          }
        };
    
        // Add event listener for window resize
        window.addEventListener("resize", handleResize);
    
        // Remove event listener on component unmount
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, [openDrawer, openDrawerCustom]);

    return (
        <div className='mb-0 mx-6 sm:px-16'>
            <div className='flex pt-1 sm:pt-6 sm:mt-6 md:pt-0 md:flex-row sm:flex-col sm:mx-6 justify-between items-center gap-0 min-h-[65px]'
            >
                <div>
                    <img src={logo} className='firmfox-logo-mobile' alt="logo of the firmfox" />
                </div>

                

                <div className='sm:flex pt-6 md:pt-0 justify-end items-center gap-0 hidden'>

                    <div className='w-28 sm:w-32 flex justify-center'> 
                    
                    <Button onClick={() => navigate("/signin")} variant="text" 
                    sx={{ color: "#1E1D1B", textTransform: "capitalize", 
                        fontSize: {
                            xs: "13px",
                            smc: "17px",
                        },                        
                        textDecoration: isLoginPage ? "underline" : "none" }} 
                        >Sign In
                    </Button>
                    </div>

                    <div className='w-24 sm:w-28 '><Button onClick={() => navigate("/signup")} variant="text" 
                         sx={{textDecoration: isSignUpPage ? "underline" : "none", color: "#1E1D1B", textTransform: "capitalize",
                            fontSize: {
                                xs: "13px",
                                smc: "17px",
                            },  
                         }} >Sign Up</Button> 
                    </div>

                    <div className='w-24 sm:w-28 '><Button onClick={() => navigate("/referral")} variant="text" 
                         sx={{textDecoration: isRefferalPage ? "underline" : "none", color: "#1E1D1B", textTransform: "capitalize",
                            fontSize: {
                                xs: "13px",
                                smc: "17px",
                            },  
                         }} >Referral</Button> 
                    </div>

                    <div className='w-[120px] sm:w-[150px] '>
                        <a href="https://firmfox.com/our-services" rel="no" target="_blank">
                         <Button  variant="outlined"                          
                         sx={{
                            fontSize: {
                                xs: "13px",
                                smc: "17px",
                            },  
                            color: "#1E1D1B", border: "2px solid #1E1D1B", textTransform: "capitalize", borderRadius: "30px", '&:hover': {
                                backgroundColor: '#1E1D1B',
                                color: "white",
                                border: "2px solid #1E1D1B",

                                }
                        }}>Find Out More</Button>
                        </a></div>
                </div>

                {
            openDrawerCustom ? <span onClick={()=>setOpenDrawerCustom(false)}><CloseIcon/></span> : 

            <SvgIcon
              onClick={() => {
                setOpenDrawer(true);
                setOpenDrawerCustom(true);
              }}
              sx={{
                cursor: "pointer",
                display: { xs: "block", smc: "none" },
              }}
            >
              <svg
                fill="grey"
                style={{ cursor: "pointer" }}
                className="w-44"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>{" "}
            </SvgIcon>            
          }

{
            openDrawerCustom && 
            <div hidden={openDrawerCustom === true ? false : true} 
                style={{
                    background:"#ebebe6", 
                    width:"100%", 
                    minHeight:"calc(100dvh - 65px)", 
                    position:"absolute",
                    top:"65px", left:"0px"
            }}> 
                <div hidden={openDrawerCustom === true ? false : true} style={{background:"white",}} className='rounded-[20px] w-full flex flex-col justify-between drawer-custom'>

                

                <Box sx={{ width: "100%", padding: "3.5%", background: "white", borderRadius:"20px" }}>
                    <Box
                        sx={{
                        display: "flex",
                        justifyContent: "start",
                        pt: 1,
                        marginLeft: "4%",
                        }}
                    >

                    </Box>
                    <div className='flex flex-col justify-center pt-10 '>
                        

                    <div className='flex flex-col pl-[7%]'>

                    <Button 
                            onClick={()=>{window.open("https://firmfox.com/our-services")}}
                            variant="contained" 
                            sx={{
                            fontSize: {
                            xs: "15.5px",
                            sm: "23.5px"
                            },
                            marginLeft:"-7px",
                            marginBottom:"-45px",
                            textAlign:"left",
                            display:"flex",
                            justifyContent:"flex-start",
                            textTransform: "none", color: "white", backgroundColor: "#1E1D1B", '&:hover': {
                            backgroundColor: '#1E1D1B'
                        }
                        }} >Find Out More</Button>

                    <Button
                        onClick={() => {navigate("/signin"); setOpenDrawerCustom(false)}}
                        variant="text"
                        sx={{
                            mt:6,
                            fontSize: {
                            xs: "16px",
                            sm: "24px"
                            }, // Set font size
                            fontWeight: window.location.href.includes("signin") ? 'bolder' : "400",
                            textAlign: 'left', // Align text to the start (left)
                            justifyContent: 'flex-start', // Ensures text aligns left within button
                            color: 'black',
                            textTransform: 'none', // Prevent capitalization
                            textDecoration: 'none', // Ensure no underline
                            width: '100%', // Optional: Ensures full width for alignment
                        }}
                        >
                        Sign in
                        </Button>

                        {/* <Button
                        onClick={() => {navigate("/foxchat"); setOpenDrawerCustom(false)}}
                        variant="text"
                        sx={{
                            fontSize: {
                            xs: "16px",
                            sm: "24px"
                            }, // Set font size
                            fontWeight: window.location.href.includes("foxchat") ? 'bolder' : "400",
                            textAlign: 'left',
                            justifyContent: 'flex-start',
                            color: 'black',
                            textTransform: 'none',
                            textDecoration: 'none',
                            width: '100%',
                        }}
                        >
                        Fox Chat
                        </Button> */}

                        <Button
                        onClick={() => {navigate("/signup"); setOpenDrawerCustom(false)}}
                        variant="text"
                        sx={{
                            fontSize: {
                            xs: "16px",
                            sm: "24px"
                            }, // Set font size
                            fontWeight: window.location.href.includes("signup") ? 'bolder' : "400",
                            textAlign: 'left',
                            justifyContent: 'flex-start',
                            color: 'black',
                            textTransform: 'none',
                            textDecoration: 'none',
                            width: '100%',
                        }}
                        >
                        Sign up
                        </Button>

                        <Button
                        onClick={() => {navigate("/referral"); setOpenDrawerCustom(false)}}
                        variant="text"
                        sx={{
                            fontSize: {
                            xs: "16px",
                            sm: "24px"
                            }, // Set font size
                            fontWeight: window.location.href.includes("referral") ? 'bolder' : "400",
                            textAlign: 'left',
                            justifyContent: 'flex-start',
                            color: 'black',
                            textTransform: 'none',
                            textDecoration: 'none',
                            width: '100%',
                        }}
                        >
                        Refer a friend
                        </Button>





                    </div>
                        
                    

                    </div>
                    </Box>

                

                </div>      
            </div>
            }



                
                {/* Mobile Drawer */}
                <Drawer
                    // sx={{ xs: "block", lgc: "none" }}
                    anchor="left"
                    // open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    onOpen={() => setOpenDrawer(true)}
                    role="presentation">

                    <Box sx={{ width: "50vw", padding: "3.5%", background: "white" }}>
                    <Box
                        sx={{
                        display: "flex",
                        justifyContent: "start",
                        pt: 1,
                        marginLeft: "4%",
                        }}
                    >

                    </Box>
                    <div className='flex flex-col justify-center pt-10 '>
                        <img src={logo} width="57%" alt="logo of firmfox" onClick={() => setOpenDrawer(false)} className='mb-20 w-8/12' />
                        <Button
                        onClick={() => {navigate("/signin"); setOpenDrawer(false)}}
                        variant="text"
                        sx={{
                            fontSize: {
                            xs: "18px",
                            sm: "24px"
                            }, // Set font size
                            fontWeight: 'bold',
                            textAlign: 'left', // Align text to the start (left)
                            justifyContent: 'flex-start', // Ensures text aligns left within button
                            color: 'black',
                            textTransform: 'none', // Prevent capitalization
                            textDecoration: 'none', // Ensure no underline
                            width: '100%', // Optional: Ensures full width for alignment
                        }}
                        >
                        Sign in
                        </Button>

                        <Button
                        onClick={() => {navigate("/signup"); setOpenDrawer(false)}}
                        variant="text"
                        sx={{
                            fontSize: {
                            xs: "18px",
                            sm: "24px"
                            }, // Set font size
                            fontWeight: 'bold',
                            textAlign: 'left', // Align text to the start (left)
                            justifyContent: 'flex-start', // Ensures text aligns left within button
                            color: 'black',
                            textTransform: 'none', // Prevent capitalization
                            textDecoration: 'none', // Ensure no underline
                            width: '100%', // Optional: Ensures full width for alignment
                        }}
                        >
                        Sign up
                        </Button>

                        <Button
                        onClick={() => {navigate("/referral"); setOpenDrawer(false)}}
                        variant="text"
                        sx={{
                            fontSize: {
                            xs: "18px",
                            sm: "24px"
                            }, // Set font size
                            fontWeight: 'bold',
                            textAlign: 'left', // Align text to the start (left)
                            justifyContent: 'flex-start', // Ensures text aligns left within button
                            color: 'black',
                            textTransform: 'none', // Prevent capitalization
                            textDecoration: 'none', // Ensure no underline
                            width: '100%', // Optional: Ensures full width for alignment
                        }}
                        >
                        Referral
                        </Button>

                        <Button variant="contained" sx={{
                            fontSize: {
                            xs: "16px",
                            sm: "18px"
                            },
                            textTransform: "none", color: "white", backgroundColor: "#1E1D1B", '&:hover': {
                            backgroundColor: '#1E1D1B'
                        }
                        }} >Find Out More</Button>

                    </div>
                    </Box>
                </Drawer>

                </div>
        </div>
    );
}

export default NavbarNew;