import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"; // Default localStorage
import sessionStorage from "redux-persist/lib/storage/session"; // Session storage
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import appReducer from "../features/appSlice";
import stepBlockerSlice from "../features/stepsBlocker";

// Persist configuration for stepBlocker using sessionStorage
const stepBlockerPersistConfig = {
  key: "stepBlocker",
  storage: sessionStorage,
};

// Persist configuration for the app reducer using localStorage
const appPersistConfig = {
  key: "app",
  storage,
};

// Persisted reducers
const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer), // app reducer with localStorage
  stepBlocker: persistReducer(stepBlockerPersistConfig, stepBlockerSlice), // stepBlocker reducer with sessionStorage
});

// Root persist configuration: Exclude stepBlocker from being persisted in localStorage
const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: ["app"], // Only persist the app reducer
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export default store;
