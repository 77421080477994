import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Box,
  Divider,
  TextField,
  MenuItem,
  IconButton,
  Grid,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { CiCirclePlus } from "react-icons/ci";
import { MdClose } from "react-icons/md";
import {
  apiCallWithAxiosCancel,
  AppStepLock,
  GetApplicationData,
  GetNationalities,
  SaveIntialPartnerDetails,
} from "../../helpers/api";
import { toast } from "react-toastify";
import { Formik, FieldArray, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import LoadingModal from "../../helpers/LoadingModal";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { scrollToFirstError, valueTester } from "../../Utilities/UtilityFunctions";
import { blockStep, unblockStep } from "../../features/stepsBlocker";

Yup.addMethod(Yup.number, "withinRange", function (min, max, message) {
  return this.test(
    "within-range",
    message,
    (value) => value >= min && value <= max
  );
});

const validationSchema = Yup.object().shape({
  partners: Yup.array()
    .of(
      Yup.object().shape({
        nameEN: Yup.string().trim().required("Partner name is required"),
        nationality_id: Yup.string().trim().required("Nationality is required"),
        currently_in_uae: Yup.string().trim().required("Required"),
        dob: Yup.string().trim().required("Date of birth is required"),
        equity: Yup.number()
          .required("Equity is required")
          .withinRange(0.1, 100, "Equity must be between 1% and 100%"),
      })
    )
    .test(
      "total-equity",
      function (partners) {
        // Check if all required fields have values
        const allFieldsFilled = partners.every(
          (partner) => partner.nameEN && partner.nationality_id && partner.dob && partner.equity
        );

        if (!allFieldsFilled) {
          return true; // Let individual field errors handle missing fields
        }

        // Now perform the equity sum check
        const totalEquity = partners.reduce(
          (sum, partner) => sum + (partner.equity || 0),
          0
        );
        return totalEquity === 100 || this.createError({ message: "Sum of all partners' equity must be 100%" });
      }
    ),
});

function formatDOB(dob) {
  // Create a new Date object from the input string
  const date = new Date(dob);

  // Extract the day, month, and year
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

  // Return the formatted date
  return `${day}-${month}-${year}`;
}



// Example usage
// const dob = "2024-09-04";
// console.log(formatDOB(dob)); // Output: "04-09-24"



function InitialPartnerDetails({ onNext, onBack, setMaxStepReached, onSaveAndExit }) {
  dayjs.extend(customParseFormat);
  const applicationID = useSelector((state) => state.app.applicationID);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.app);
  const inputRef = useRef(null);
  const [isEverythingDisabled, setIsEverythingDisabled] = useState(false);
  const [country, setCountryNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [initialPartners, setInitialPartners] = useState([]);
  const [open, setOpen] = useState(false);
  const today = dayjs(); // Current date
  const twentyYearsAgo = today.subtract(20, 'year').toDate(); // Date for 20 years ago


  const appLock = useSelector((state) => state.stepBlocker)

  const [errorCustom, setErrorCustom] = useState({error: "", show: false, id: ""})

  const [screenHeight, setScreenHeight] = useState(window.innerHeight); // Initial height

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight); // Update state when window is resized
    };

    // Add event listener for window resize
    window.addEventListener('resize', updateHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    async function fetchNationalities() {
      try {
        setLoading(true);
        const Nationalities = await GetNationalities(dispatch);
        setCountryNames(Nationalities.data);
        setErrorCustom({error: "Error occurred while fetching Countries Data", show: false, id: "nat-id"})
      } catch (error) {
        toast.error("Error occurred while fetching Countries Data");
        setErrorCustom({error: "Error occurred while fetching Countries Data", show: true, id: "nat-id"})
      } finally {
        setLoading(false);
        setErrorCustom({error: "Error occurred while fetching Countries Data", show: false, id: "nat-id"})
      }
    }
    fetchNationalities();
  }, [dispatch]);

  useEffect(() => {
    async function fetchApplicationData() {
      try {
        setLoading(true);
        const ApplicationData = await GetApplicationData(
          applicationID,
          dispatch
        );
        setMaxStepReached(ApplicationData?.data[0]?.step_no + 1)
        setIsEverythingDisabled(ApplicationData?.data[0]?.is_completed);
        console.log("application data of partner details", ApplicationData);
        if (
          ApplicationData.data.length > 0 &&
          ApplicationData.data[0].partner_details.length > 0
        ) {
          setInitialPartners(ApplicationData.data[0].partner_details);
        }
      } catch (error) {
        toast.error("Error occurred while fetching Application Data");
      }
      finally {
        setLoading(false);
      }
    }
    fetchApplicationData();
  }, [applicationID, dispatch]);

  const handleIsEverythingDisabled = (strategy) => {
    if (strategy === 'next') {
      console.log("next clicked")
      onNext();
    } else {
      navigate("/");
    }
  };

  const handleSubmit = async (values, exit) => {
    try {
      setNextLoading(true);
  
      // Payload for SaveInitialPartnerDetails
      const payload = {
        partners: values.partners,
        id: applicationID,
        no_of_shareholders: values.partners?.length,
      };
  
      // Call SaveInitialPartnerDetails API
      await apiCallWithAxiosCancel((cancelToken) =>
        SaveIntialPartnerDetails(payload, dispatch, cancelToken)
      );
  
      // Check for changes in partners
      if (initialPartners.length !== values.partners.length) {
        await AppStepLock(
          {
            app_id: valueTester(appLock.app_id) ? appLock.app_id : applicationID,
            is_blocked: true,
            current_step: 1,
          },
          dispatch
        );
        dispatch(
          blockStep({
            is_blocked: true,
            currentStep: 1,
            app_id: valueTester(appLock.app_id) ? appLock.app_id : applicationID,
          })
        );
      } else {
        const change_in_partner = initialPartners.map((partner, index) => {
          if (partner?.currently_in_uae !== values.partners[index]?.currently_in_uae) {
            return true;
          } else if (partner?.nationality_id !== values.partners[index]?.nationality_id) {
            return true;
          } else {
            return false; // No change
          }
        });
  
        console.log("change_in_partner = ", change_in_partner);
  
        if (change_in_partner.includes(true)) {
          await AppStepLock(
            {
              app_id: appLock.app_id,
              is_blocked: true,
              current_step: 1,
            },
            dispatch
          );
          dispatch(blockStep({ is_blocked: true, currentStep: 1 }));
        } else if (appLock.is_blocked === true) {
          await AppStepLock(
            {
              app_id: appLock.app_id,
              is_blocked: true,
              current_step: appLock.currentStep >= 1 ? appLock.currentStep : 1,
            },
            dispatch
          );
          dispatch(
            blockStep({
              is_blocked: true,
              currentStep: appLock.currentStep >= 1 ? appLock.currentStep : 1,
            })
          );
        }
      }
  
      // Navigation or next step
      if (exit === true) {
        navigate("/");
      } else {
        onNext();
      }
    } catch (error) {
      if (error?.code !== "ERR_CANCELED" || error?.name !== "CanceledError") {
        toast.error("Error saving Initial Partners Details.");
      }
    } finally {
      setNextLoading(false);
    }
  };
  


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Add event listener on mount
  window.addEventListener("resize", handleResize);

  // Cleanup event listener on unmount
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);


  return (
    <>
      <LoadingModal open={loading} />
      <Formik
        initialValues={{ partners: initialPartners }}
        validationSchema={validationSchema}
        onSubmit={(values, errors) => {
          console.log("s2 formik errors = ",errors)
          handleSubmit(values, false);
        }}
        enableReinitialize={true}
        // validateOnChange={false} // Disable validation on change
        // validateOnBlur={false}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          validateForm,
          submitForm,
          setErrors
        }) => (
          <Form 
            className="h-full"
            >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"column"}
              sx={{ minHeight: "100%", background:"white", borderRadius:"20px" }}
            >
              <Box sx={{ px: { xs:3, smc:5} , width:"100%" }} marginTop={2}>
                <p className={`font-bold text-lg sm:text-2xl   text-[#1E1D1B]`}>
                  Who all are the shareholders of the business?
                </p>

                {
                    windowWidth < 640 ? <hr style={{borderWidth:"1px", marginTop:"25px"}}/> : ""
                 }

                <br />

                <FieldArray name="partners">
                  {({ insert, remove, push }) => (
                    <div>
                      <Box sx={{ 
                        maxHeight: {xs: `calc(100dvh - 90px - 32px - 64px - 56px - 32px - 40px - 40px - 20px - 35px)`, lgc: `calc(100dvh - 90px - 32px - 64px - 56px - 32px - 30px)`}, 
                        minHeight: {xs: `calc(100dvh - 90px - 32px - 64px - 56px - 32px - 40px - 40px - 20px - 35px)`, lgc: `calc(100dvh - 90px - 32px - 64px - 56px - 32px - 30px)`}
                      }} 
                        className="mb-3 overflow-auto scrollbar">
                       
                        {values.partners.length > 0 &&
                          values.partners.map((partner, index) => (
                            <div className="mt-1 w-full" key={index}>
                              <span className="font-bold">
                                Partner {index + 1}
                              </span>
                              <div className="flex flex-col  max-w-[891px] lg:flex-row w-full pb-2 mt-1 gap-4 z-0 items-center">
                                <div className="w-full lg:w-[40%] min-h-[55px]">
                                  <Field
                                    required
                                    as={TextField}
                                    name={`partners.${index}.nameEN`}
                                    placeholder="Partner Name (as per passport)"
                                    variant="outlined"
                                    size="small"
                                    error={touched.partners?.[index]?.nameEN && !!errors.partners?.[index]?.nameEN}
                                    disabled={isEverythingDisabled}
                                    className="w-full lg:w-[270px]"
                                  />
                                  {/* Error message for name */}
                                  <ErrorMessage
                                    name={`partners.${index}.nameEN`}
                                    component="div"
                                    className="text-red-500 text-xs"
                                  />
                                  
                                </div>
                                <div className=" w-full  lg:w-[40%] min-h-[55px]">
                                  <Field
                                    required
                                    as={TextField}
                                    size="small"
                                    name={`partners.${index}.nationality_id`}
                                    select
                                    placeholder="Select nationality"
                                    variant="outlined"
                                    className="w-full lg:w-[220px]"
                                    label="Nationality"
                                    disabled={isEverythingDisabled}
                                    error={touched.partners?.[index]?.nationality_id && !!errors.partners?.[index]?.nationality_id}

                                  >
                                    {country.map((country) => (
                                      <MenuItem key={country.id} value={country.id}>
                                        {country.nameEn}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                  {/* Error message for nationality */}
                                  <ErrorMessage
                                    name={`partners.${index}.nationality_id`}
                                    component="div"
                                    className="text-red-500 text-xs"
                                  />
                                </div>

                                <div className="flex w-full justify-between sm:justify-start gap-2 sm:gap-4">

                                  <div style={{}} className="w-full lg:w-[50%] min-h-[55px] ">
                                    <div className="lg:w-36 w-full date-container-custom">

                                    <DatePicker
                                      required
                                      style={{width:"100%"}}
                                      selected={partner.dob ? dayjs(partner.dob, 'DD-MM-YYYY').toDate() : null}
                                      onChange={(date) => {
                                        setFieldValue(
                                          `partners.${index}.dob`,
                                          date ? dayjs(date).format('DD-MM-YYYY') : ''
                                        );
                                      }}
                                      dateFormat="dd-MM-yyyy" 
                                      maxDate={twentyYearsAgo} 
                                      minDate={new Date(1900, 0, 1)}
                                      showYearDropdown
                                      disabled={isEverythingDisabled}
                                      scrollableYearDropdown
                                      yearDropdownItemNumber={110}
                                      className="w-[100%] border p-3 bg-transparent border-[#c1c0c1] h-[40px] rounded"
                                      placeholderText="Date of Birth"
                                    />

                                    </div>
                                    <ErrorMessage
                                      name={`partners.${index}.dob`}
                                      component="div"
                                      className="text-red-500 text-xs"
                                    />
                                  </div>

                                  <div className="w-full min-w-[100px] min-h-[55px]">
                                    <Field
                                      required
                                      as={TextField}
                                      name={`partners.${index}.equity`}
                                      placeholder="Equity%"
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      InputLabelProps={{ shrink: true }}
                                      className="w-full lg:w-[130px]"
                                      disabled={isEverythingDisabled}
                                      readOnly={isEverythingDisabled}
                                      error={
                                        touched.partners?.[index]?.equity &&
                                        !!errors.partners?.[index]?.equity
                                      }
                                      inputProps={{ min: "0", max: "100" }}
                                      onInput={(e) => {
                                        if (e.target.value < 0) e.target.value = 0;
                                      }}

                                    />
                                    <ErrorMessage
                                      name={`partners.${index}.equity`}
                                      component="div"
                                      className="text-red-500 text-xs"
                                    />
                                  </div>                                  
                                </div>


                              </div>

                        {/* <Grid item xs={12} mt={2} mb={2}> */}
                        <div 
                          style={{paddingRight: isEverythingDisabled ? "38px" : "", maxWidth: index === 0 ? "812px" : "852px"}}
                          className="flex min-h-[55px] lg:justify-end">

                          
                        <div className="flex gap-5 items-center">
                            <p className="text-sm sm:text-base sm:font-normal pr-1">
                              Is {" "}
                              <span className="font-semibold">
                                Partner {index+1}
                              </span>{" "} currently in the UAE?
                            </p>

                            <span>

                            <Field
                              required
                              name={`partners[${index}].currently_in_uae`}
                              as={TextField}
                              disabled={isEverythingDisabled}
                              select
                              margin="normal"
                              placeholder="Yes/No"
                              sx={{
                                minWidth: "100px"
                              }}
                              value={values?.partners[index]?.currently_in_uae || null} 
                              onChange={(e) => {
                                if (!isEverythingDisabled){
                                  setFieldValue(`partners[${index}].currently_in_uae`, e.target.value); 
                                }
                              }}
                              error={
                                touched.partners?.[index]?.currently_in_uae &&
                                !!errors.partners?.[index]?.currently_in_uae
                              }
                            >
                              <MenuItem value="yes">Yes</MenuItem>
                              <MenuItem value="no">No</MenuItem>
                            </Field>
                            <ErrorMessage
                              className="text-red-500 mt-2 text-xs"
                              name={`partners[${index}].currently_in_uae`}
                              component="div"
                            />
                            </span>
                          </div>
                        <div hidden={index === 0 ? true : false} className="flex items-center justify-center">
                          {
                            isEverythingDisabled || index === 0 ? "" : (
                              <>
                                {index !== 0 &&
                                  <IconButton
                                    onClick={() => index !== 0 && remove(index)}
                                  >
                                    {index !== 0 && <MdClose />}
                                  </IconButton>
                                }
                                {index === 0 &&
                                  <IconButton sx={{ color: "#fbfafb", pointerEvents: "none" }}
                                  >
                                    <MdClose />
                                  </IconButton>
                                }
                              </>
                            )
                          }
                        </div>
                        </div>

                      {/* </Grid> */}
                        {errors.partners?.[index] && touched.partners?.[index] && (
                              <div className="flex gap-4 text-xs justify-center items-center" style={{ color: "red" }}>
                                {/* Total equity error */}
                                {errors.partners && typeof errors.partners === "string" && (
                                  <div className="text-red-500 text-xs">{errors.partners}</div>
                                )}
                              </div>
                            )}
                        </div>
                        ))}
                      </Box>

                      {
                        errorCustom?.show === true ? <div className="error-msg-div"><p className="error-msg-text">{errorCustom?.error}</p></div> : ""
                      }

                      <Divider sx={{ borderBottomWidth: 2 }}></Divider>
                      <div className={`border-2 mt-3 mb-2 max-w-[530px] w-[100%] rounded-lg overflow-hidden ${isEverythingDisabled ? 'bg-[#e6e6e6] ' : 'bg-[#E6FCE2] border-[#81EF6F]'}  `}>
                        <button
                          type="button"
                          disabled={isEverythingDisabled}
                          className="flex items-center text-center w-[100%] justify-center py-3 px-4 cursor-pointer"
                          onClick={() => {
                            if (values.partners.length < 5) {
                              push({
                                nameEN: "",
                                nationality_id: "",
                                dob: "",
                                equity: "",
                              });
                            } else {
                              toast.warn("You can add up to 5 partners only.", {
                                toastId: "customId"
                              });
                            }
                          }}
                        >
                          <div className="flex items-center">
                            <CiCirclePlus
                              className={`mr-2 text-[#1E1D1B]`}
                              size={28}
                            />
                            <span className={` text-[12px] sm:text-xl text-[#1E1D1B]`}>
                              Click to Add Another Partner
                            </span>
                          </div>

                        </button>
                      </div>
                    </div>

                  )}
                </FieldArray>

              </Box>

              <Box
                sx={{
                  alignItems: "flex-end",
                  display: "flex",
                  gap: "0.1rem",
                  mt: 0.5,
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  className="three-btn-row"
                  sx={{
                    backgroundColor: "rgb(212 212 216)",
                    fontWeight: 600,
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#81EF6F",
                      boxShadow: "none",
                    },
                    color: "#1E1D1B",
                    borderRadius: "0px",
                    textTransform: "none",
                    maxWidth: windowWidth < 640 ? "48%" : "28%",
                  }}
                  onClick={onBack}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  className="three-btn-row"
                  fullWidth
                  sx={{
                    backgroundColor: "rgb(212 212 216)",
                    fontWeight: 600,
                    boxShadow: "none",
                    display: windowWidth < 640 ? "none" : "flex",
                    "&:hover": {
                      backgroundColor: "#81EF6F",
                      boxShadow: "none",
                    },
                    color: "#1E1D1B",
                    borderRadius: "0px",
                    textTransform: "none",
                    maxWidth: "28%",
                  }}
                  onClick={async () => {
                    if (isEverythingDisabled) {
                      console.log("i am here")
                      handleIsEverythingDisabled('exit');
                    } else {
                      console.log("i am hereeedsdsd")
                      const errors = await validateForm();
                      if (Object.keys(errors).length === 0) {
                        handleSubmit(values, true);
                      } else {
                        submitForm();
                      }
                    }
                  }}
                >
                  Save & Exit
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  className="three-btn-row"
                  sx={{
                    backgroundColor: "#81ef6f",
                    fontWeight: 600,
                    color: "#1E1D1B",
                    borderRadius: "0px",
                    textTransform: "none",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#81EF6F",
                      boxShadow: "none",
                    },
                    display: "flex",
                    gap: "10px",
                  }}
                  type={isEverythingDisabled ? "button" : "submit"} // Correctly apply the conditional type here
                  onClick={() => {

                    if (isEverythingDisabled) {
                      handleIsEverythingDisabled('next');
                    }
                  }}
                >
                  Next Step
                  {nextLoading && (
                    <svg
                      className="animate-spin w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                    </svg>
                  )}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default InitialPartnerDetails;
