import { Button, Grid, Container, IconButton, Tooltip, InputAdornment, TextField} from "@mui/material"
import NavbarNew from "./NavbarNew"
import { useState, useEffect } from "react"
import * as Yup from "yup"
import { Field, Form, Formik } from "formik";
import { ClipboardIconCustom } from "../assets/svgs/svgs";
import { API_URL } from "../Utilities/ConstantSettings";
import axios from "axios";
import { useSelector } from "react-redux";
import Navbar from "../dashboard/Navbar";


const Referral = () => {

    const initialValues = {
        "company_name": "",
        "company_email": "",
        "refferal_link": "",
    }

    const validationSchema = Yup.object().shape({
        company_name: Yup.string().trim().required("Company name is required"),

        company_email: Yup.string().trim()
        .email("Invalid email address")  // Yup's built-in email validation
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,  // Additional regex for more strict email validation
          "Invalid email address"
        )
        .required("Company email is required"),
    })

    const [apiloading, setApiLoading] = useState(false)
    const [refferalLink, setrefferalLink] = useState("")


    const handleSubmit = async (values, {setErrors}) => {
        setApiLoading(true)

        try{

            const response = await axios.post(
                `${API_URL}/api/business/referral-link/`,
                {
                    company_name: values.company_name,
                    company_email: values.company_email
                }
              );
            
            setrefferalLink(response.data?.referral_link)
            setApiLoading(false)
        }
        catch(error){
            setApiLoading(false)
        }
    }

    const [tooltipText, setTooltipText] = useState("Click to copy text");

    const handleCopyText = (text) => {
      navigator.clipboard.writeText(text);
      setTooltipText("Copied");
      setTimeout(() => setTooltipText("Click to copy text"), 2000); // Reset tooltip after 2 seconds
    };

    const user = useSelector((state)=>state?.app?.user)
    console.log(user)

    return(<>
        
        <div className="bg-[#EBEBE6] sm:min-h-[calc(100dvh - 25px)]">
      {/* <ToastContainer/> */}
      {user?.email ? <Navbar/> : <NavbarNew />}
      
      <div className="flex w-full sm:items-center  min-h-[calc(100dvh-85px)] sm:min-h-[80vh] sm:mt-0">
        <Container sx={{ padding:{xs:"0px 10px", smc: "0px 16px"} }}>
          <Grid
            container
            sx={{ justifyContent: "center", alignItems: {xs: "flex-start" , smc: "center"}, minHeight:{ xs:"calc(100dvh - 85px)", smc:"100%"}, 
            height:{xs: "max-content", smc: "100%"}, background:{xs:"#fbfafb", smc: "none"}, borderRadius:"20px", pb: {xs:"5px", smc: "0px"}
          }}
          >
            <Grid item xs={12} sm={11} md={9} lg={6} xl={6} sx={{height:{xs: "max-content", smc: "100%"}, background:{xs:"#fbfafb", smc: "none"}, borderRadius:"20px",}} >
              <div className="bg-[#fbfafb] pt-9 sm:mt-9 sm:pb-9 rounded-[20px] flex flex-col gap-4 items-center h-full">
                <p className="text-2xl sm:text-3xl text-[#1E1D1B] font-bold pt-6 sm:pt-0">Earn by Sharing FirmFox</p>

                <p className="text-sm text-[#8C8C8C] w-[80%] sm:w-[60%] text-center mb-4 max-w-[260px] sm:max-w-[320px]">
                Get 10% of the net fees we earn from the first year of service for every successful referral!
                </p>

                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={ (values, {setErrors}) => handleSubmit(values, {setErrors})}
                >
                  {({ errors, touched }) => (
                    <Form
                      className="space-y-4 flex flex-col items-center referral-form-width"
                      fullWidth
                      >
                    <div className="flex w-full flex-col items-start">
                    <div className="text-[#323338] font-semibold mb-1">Company Name</div>

                    <Field name="company_name">
                        {({ field, meta }) => (
                        <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            type="text"
                            error={meta.touched && Boolean(meta.error)}
                            helperText={meta.touched && meta.error ? meta.error : null}
                            // InputProps={{
                            // endAdornment: (
                            //     <InputAdornment position="end">
                            //     <Tooltip title={tooltipText} placement="top">
                            //         <IconButton
                            //         onClick={() => handleCopyText(field.value)}
                            //         onMouseLeave={() => setTooltipText("Click to copy text")} // Reset tooltip on mouse leave
                            //         >
                            //         <ClipboardIconCustom />
                            //         </IconButton>
                            //     </Tooltip>
                            //     </InputAdornment>
                            // ),
                            // }}
                            sx={{
                            "& .MuiOutlinedInput-input":{
                              padding: "12px 14px",
                            },
                            "& .MuiInputBase-root": {
                                height:"44px"
                            },
                            width: { xs:"100%", smc: '334px'},
                            }}
                        />
                        )}
                    </Field>
                    </div>
                    
                    <div className="flex w-full flex-col items-start">
                    <div className="text-[#323338] font-semibold mb-1">Company Email</div>

                    <Field name="company_email" >
                        {({ field, meta }) => (
                        <TextField
                            {...field}
                            fullWidth
                            className="bg-transparent rounded-md border-2 border-[#EBEBE6]"
                            variant="outlined"
                            type="text"
                            error={meta.touched && Boolean(meta.error)}
                            helperText={meta.touched && meta.error ? meta.error : null}
                            // InputProps={{
                            // endAdornment: (
                            //     <InputAdornment position="end">
                            //     <Tooltip title={tooltipText} placement="top">
                            //         <IconButton
                            //         onClick={() => handleCopyText(field.value)}
                            //         onMouseLeave={() => setTooltipText("Click to copy text")} // Reset tooltip on mouse leave
                            //         >
                            //         <ClipboardIconCustom />
                            //         </IconButton>
                            //     </Tooltip>
                            //     </InputAdornment>
                            // ),
                            // }}
                            sx={{
                              "& .MuiOutlinedInput-input":{
                                padding: "12px 14px",
                              },
                              "& .MuiInputBase-root": {
                                  height:"44px"
                              },
                              width: { xs:"100%", smc: '334px'},
                              }}
                        />
                        )}
                    </Field>

                    </div>
                      <Button
                        type="submit"
                        variant="contained"
                        disableElevation
                        fullWidth
                        disabled={apiloading}
                        sx={{
                          textTransform: "none",
                          backgroundColor: "#81EF6F",
                          borderRadius: "5px",
                          fontSize: { xs:"16px", smc: "18px"},
                          color: "#000000",
                          "&:hover": { backgroundColor: "#81EF6F" },
                        }}
                        endIcon={
                          apiloading && (
                            <svg
                              className="animate-spin w-6 h-6"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                            </svg>
                          )
                        }
                      >
                        Generate Link
                      </Button>

                    <div className="flex w-full flex-col items-start">

                      <hr style={{borderWidth:"1.5px", borderColor:"#EBEBE6", width:"100%", margin:"10px 0px"}}/>
                    
                    <div className="text-[#323338] font-semibold mb-1">Referral Link</div>
                    <Field name="refferal_link" >
                        {({ field, meta }) => (
                        <TextField
                            {...field}
                            fullWidth
                            readOnly
                            className="bg-transparent rounded-md border-2 border-[#EBEBE6]"
                            variant="outlined"
                            type="text"
                            value={refferalLink}
                            error={meta.touched && Boolean(meta.error)}
                            helperText={meta.touched && meta.error ? meta.error : null}
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <Tooltip title={tooltipText} placement="top">
                                    <IconButton
                                    onClick={() => handleCopyText(refferalLink)}
                                    onMouseLeave={() => setTooltipText("Click to copy text")} // Reset tooltip on mouse leave
                                    >
                                    <ClipboardIconCustom />
                                    </IconButton>
                                </Tooltip>
                                </InputAdornment>
                            ),
                            }}
                            sx={{
                            "& .MuiInputBase-root": {
                                paddingRight: "10px", // Adjust padding to fit icon well
                                height:"44px",
                            },
                            }}
                        />
                        )}
                    </Field>

                    </div>
                    
                    </Form>
                  )}
                </Formik>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
    
    </>)

}

export default Referral