import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
  user: null,
  error: null,
  refresh: localStorage.getItem("refresh_token") ?? null,
  token: localStorage.getItem("access_token") ?? null,
  isLoading: false,
  isSidebar: false,
  currentCategory: null,
  favouriteCategories: [],
  bookmarks: [],
  currentAsset: {},
  applicationID: null,
  role: null,
  subActivitiesData: null,
  is_blocked:false,
  currentStep: 0,
};

export const appSlice = createSlice({
  name: "firmfox",
  initialState,
  reducers: {
    start: (state) => {
      state.isLoading = true;
    },
    stop: (state) => {
      state.isLoading = false;
    },
    login: (state, action) => {
      state.user = action.payload.user;
      state.token = action?.payload?.token;
      state.refresh = action?.payload?.refresh;
      state.role = action?.payload?.role;
    },
    setUser: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    logout: () => {
      localStorage.clear();
      return {
        ...initialState,
        token: null,
        refresh: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    sidebar: (state) => {
      state.isSidebar = !state.isSidebar;
    },
    // setRole: (state,action) => {
    //   state.role = action?.payload?.is_agent;
    // },
    setCurrentCategory: (state, action) => {
      return {
        ...state,
        currentCategory: action.payload,
      };
    },
    setFavouriteCategories: (state, action) => {
      state.favouriteCategories = action.payload;
    },
    setBookmarks: (state, action) => {
      state.bookmarks = action.payload;
    },
    setApplicationID: (state, action) => {
      state.applicationID = action.payload.toString();
    },
    setAsset: (state, action) => {
      return {
        ...state,
        currentAsset: action.payload,
      };
    },
    resetApplicationID: (state) => {
      state.applicationID = null;
    },
    setSubActivitiesData: (state, action) => {
      state.subActivitiesData = action.payload;
    },
    // blockStep:(state,action)=>{
    //   state.is_blocked=true;
    //   state.currentStep=action.payload.currentStep;
      
    // },
    // unblockStep:(state,action)=>{
    //   state.is_blocked=false;
    // }
  },
});

export const {
  login,
  logout,
  setError,
  start,
  stop,
  setUser,
  sidebar,
  setCurrentCategory,
  setFavouriteCategories,
  setBookmarks,
  setAsset,
  setApplicationID,
  resetApplicationID,
  // blockStep,
  // unblockStep,
  //setRole,
  setSubActivitiesData,
} = appSlice.actions;

export default appSlice.reducer;
